<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="master.level.type.list.name">Level Type List</h5>
          <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
        </ng-template>
        <ng-template pTemplate="right">
          <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button"
            (click)="openNew()" styleClass="button"><span class="button-label" [ngClass]="fontService.buttonClass"
              jhiTranslate="common.newbutton"></span></button>
          <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->


        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-12 px-4">
      <custom-filter-table [columnsValues]="cols" (emitRow)="onSelectRow($event)" [filterSearchResult]="searchResult"
        [disableDeleteIcon]="true" [disableEditIcon]="true" [enableViewIcon]="true"></custom-filter-table>
    </div>
  </div>
  <p-dialog #dialog [(visible)]="levelTypeDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
    [style]="{ width: '850px' }">
    <p-header [ngClass]="fontService.headingClass">
      {{"master.add.level.type.name"|translate}}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row gy-2">
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass"
                jhiTranslate="master.department.name">Department</label>
              <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="departmentList"
                [(ngModel)]="levelType.departmentDto"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelType.departmentDto }" [disabled]="viewOnly">
              </ng-select>
            </div>

            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" for="levelType" [ngClass]="fontService.labelClass"
                jhiTranslate="master.level.name.name">Level Name</label>
              <input type="text" class="form-control" [(ngModel)]="levelType.name" maxlength="50"
                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="name" autocomplete="off"
                id="levelType" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelType.name }"
                (ngModelChange)="checkName()" [disabled]="viewOnly" />
              <div *ngIf="showNameError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{showNameError}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span></button>
      <button *ngIf="!viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
        (click)="saveData()"><span class="button-label" [ngClass]="fontService.buttonClass"
          jhiTranslate="common.savebutton"></span></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>