import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DepartmentListService } from './department-list.service';
import { DepartmentList } from './department-list';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';


@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.scss']
})
export class DepartmentListComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  departmentListDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<DepartmentList> = new SearchResult<DepartmentList>();


  departmentList?: DepartmentList;

  selectedDepartmentList: DepartmentList[] = [];

  submitted?: boolean;

  selectedMapping: string;
  itemsPerPage: number = 10;

  //required fields property
  ngSelectInvalid = false;

  constructor(
    private departmentListService: DepartmentListService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {

    this.loadPage()

    this.cols = [
      { field: 'name', header: 'Name', jhiTranslate: 'tableHead.name', width: 25, isSelectcolumn: true },
      { field: 'shortName', header: 'Short Name', jhiTranslate: 'tableHead.short.name', width: 25, isSelectcolumn: true },
      { field: 'code', header: 'Code', jhiTranslate: 'tableHead.code.name', isSortable: true, isSelectcolumn: true },
      { field: 'branch', header: 'Branch', jhiTranslate: 'tableHead.branch.name', width: 13, isSelectcolumn: true },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.departmentList = new DepartmentList();
    this.submitted = false;
    this.departmentListDialog = true;

  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()

    this.departmentListService
      .filter()
      .subscribe(
        (res: HttpResponse<DepartmentList[]>) => {
          this.searchResult.total = res.body.length
          this.spinner.hide()

          this.searchResult.items = res.body;
          this.searchResult = { ...this.searchResult };

        },
        () => {
          this.onError();
        }
      );
  }

  protected onError(): void {
  }

  hideDialog() {
    this.ngSelectInvalid = false;
    this.departmentList = new DepartmentList();
    this.departmentListDialog = false;
    this.submitted = false;
  }

  saveData() {
    const isFormValid =
      this.departmentList.name &&
      this.departmentList.shortName &&
      this.departmentList.code &&
      this.departmentList.branch

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.departmentListService
      .save(this.departmentList)
      .subscribe(
        (res: HttpResponse<DepartmentList>) => {
          this.departmentListDialog = false;
          this.loadPage();
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onSelectRow($event) {
    if ($event.type === "select") {
      this.departmentListDialog = true;
      this.departmentList = $event.value
    }

  }
}