import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { LevelMasterService } from './level-master.service';
import { LevelMaster, LevelMasterDto } from './level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LevelTypeService } from '../level-type/level-type.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelType } from '../level-type/level-type';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { filterQuery } from 'src/app/shared/common.model';
import { Subscription } from 'rxjs';
import { EncryptService } from 'src/app/shared/encrypt.service';
@Component({
  selector: 'app-level-master',
  templateUrl: './level-master.component.html',
  styleUrls: ['./level-master.component.scss'],
})
export class LevelMasterComponent implements OnInit, OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription;
  schemeDto:any;
  @ViewChild('dialog') dialog: Dialog;
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<LevelMaster> = new SearchResult<LevelMaster>();
  levelMaster?: LevelMasterDto;
  selectedLimitSet: LevelMaster[] = [];
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  departmentDto: any;
  stateList: TreeNode[] = [];
  stateListDto: any;
  districtLevelList: any;
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: LevelMasterDto;
  tamilText: any;
  VillageDto:any;
  treeLevelData :TreeNode[] = [];
  SublevelTypeData :TreeNode[] = [];
  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  villageLevelList: LevelMasterDto[];
  blockVillageDto: any;
  filter: any;
  /* Parameters for Role based CRUD operations */  
  pageCRUDdata = null;
  //treenode ng-model
  levelTypeData :any;
  sublevelTypeData:any;

  constructor(
    private levelMasterService: LevelMasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private departmentListService: DepartmentListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private encryptService: EncryptService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
      this.changeLangLoadHeader()
    });
    this.subscription = this.schemeListService.myData$.subscribe((data) => {
      console.log(data)
      this.schemeDto = data ? data : JSON.parse(localStorage.getItem('scheme'));
      this.loadPage()
    });

    let temp =  JSON.parse(this.encryptService.decryptData(localStorage.getItem('menuDetails')))
    temp.forEach(menuData=>{
      if(menuData.menuTab == 'LEVELMASTER'){
        this.pageCRUDdata=menuData
      }
      if(menuData.subMenus){
        menuData.subMenus.forEach(subMenuData=>{
          if(subMenuData.subMenuTab == 'LEVELMASTER'){
            this.pageCRUDdata=subMenuData
          }
        })        
      }      
    })    
    console.log(this.pageCRUDdata);
  }

  ngOnInit() {
    //this.loadPage(1);
    this.changeLangLoadHeader();
    this.onStateLevelfilterChange()
  }

  changeLangLoadHeader(){
    const lang = this.translateService.currentLang;
    if(lang === 'en') {
      this.cols = [
        {
          field: 'departmentName',
          header: 'Deaprtment Name',
          // jhiTranslate: 'tableHead.state.code.name',
          isFilterable: true,
          width: 5,
          isSelectcolumn: true,
        },
        {
          field: 'schemeName',
          header: 'Scheme Name',
          // jhiTranslate: 'tableHead.district.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelName',
          header: 'Level Type',
          // jhiTranslate: 'tableHead.district.name',
          isFilterable: true,
          width: 15,
          isSelectcolumn: true,
        },
        // {
        //   field: 'levelTwoCode',
        //   header: 'Block Code',
        //   jhiTranslate: 'tableHead.block.code.name',
        //   isFilterable: true,
        //   width: 25,
        //   isSelectcolumn: true,
        // },
        // {
        //   field: 'levelTwoName',
        //   header: 'Block',
        //   jhiTranslate: 'tableHead.block.name',
        //   isFilterable: true,
        //   width: 25,
        //   isSelectcolumn: true,
        // },
        
        {
          field: 'levelZeroCode',
          header: 'LGD Code',
          // jhiTranslate: 'tableHead.village.code.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'levelZeroName',
          header: 'Level Master',
          // jhiTranslate: 'tableHead.village.name',
          isFilterable: true,
          width: 25,
          isSelectcolumn: true,
        },
        
      ];
    }
    // else{
    //   this.cols = [
    //     {
    //       field: 'levelZeroCode',
    //       header: 'State Code',          
    //       jhiTranslate: 'tableHead.state.code.name',
    //       isFilterable: true,
    //       width: 5,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelOneCode',
    //       header: 'District Code',
    //       jhiTranslate: 'tableHead.district.code.name',
    //       isFilterable: true,
    //       width: 25,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelOneNameTn',
    //       header: 'District TN',
    //       jhiTranslate: 'tableHead.district.name',
    //       isFilterable: true,
    //       width: 15,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelTwoCode',
    //       header: 'Block Code',
    //       jhiTranslate: 'tableHead.block.code.name',
    //       isFilterable: true,
    //       width: 25,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelTwoNameTn',
    //       header: 'Block TN',
    //       jhiTranslate: 'tableHead.block.name',
    //       isFilterable: true,
    //       width: 15,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelThreeCode',
    //       header: 'Village Code',
    //       jhiTranslate: 'tableHead.village.code.name',
    //       isFilterable: true,
    //       width: 25,
    //       isSelectcolumn: true,
    //     },
    //     {
    //       field: 'levelThreeNameTn',
    //       header: 'Village TN',
    //       jhiTranslate: 'tableHead.village.name',
    //       isSortable: false,
    //       isFilterable: true,
    //       width: 15,
    //       isSelectcolumn: true,
    //     },
    //   ];
    // }

  }

  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.loadValue();
    this.onchangeSchema()
    this.submitted = false;
    this.levelMasterDialog = true;
    this.levelMaster = new LevelMasterDto();
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if(this.filterProps){
      let check=0
      this.filterProps.forEach(eachFilter => {
        if(eachFilter.key == 'schemeId'){
          check=1
          
          eachFilter.value = this.schemeDto.id
        }
      });
      if(check == 0){
        if(this.schemeDto)
        this.filterProps.push({
          key: 'schemeId',
          operation: 'equals',
          value: this.schemeDto.id
      })
      }
    }
    this.levelMasterService.filter({ ...filterQuery }, this.filterProps).subscribe(
        (res: HttpResponse<LevelMaster[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: LevelMaster[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page > 1 ? page: 1;
    if (navigate) {
      this.router.navigate(['/level-master'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  hideDialog() {

    this.levelMaster = new LevelMasterDto();
    this.departmentDto = null;
    //this.schemeDto = null;
    this.levelType=null;
    this.stateList=null;
    this.BlockLevelDto = new LevelMasterDto()
    this.districtListDto = new LevelMasterDto()
    this.stateListDto = new LevelMasterDto()
    this.stateListDto=null;
    this.districtListDto=null;
    this.districtLevelList=null;
    this.BlockLevelDto=null;
    this.ngSelectInvalid = false;
    this.levelMasterDialog = false;
    this.submitted = false;
  }

  saveData() {
    // console.log(this.levelMaster,this.departmentDto ,
    //   this.schemeDto ,
    //   this.levelMaster.levelTypeDto ,
    //   this.stateListDto ,
    //   this.levelMaster.name ,
    //   this.levelMaster.code);
    console.log('levelMaster',this.levelMaster.levelTypeDto)
    const isFormValid =
      this.departmentDto &&
      this.schemeDto &&
      this.levelMaster.levelTypeDto &&
      //this.stateListDto &&
      this.levelMaster.name &&
      this.levelMaster.code

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    
    if (this.levelMaster.levelTypeDto.name === "State") {
      this.levelMaster.parentId = 0
    }
    else if (this.levelMaster.levelTypeDto.name === "District") {
      if(!this.stateListDto){
        return
      }
      this.levelMaster.parentId = this.stateListDto?.data?.id
    }
    else if (this.levelMaster.levelTypeDto.name === "Block") {
      if(!this.stateListDto || !this.districtListDto){
        return
      }
      this.levelMaster.parentId = this.districtListDto?.data?.id
    }
    else if (this.levelMaster.levelTypeDto.name === "Village") {
      if(!this.stateListDto || !this.districtListDto || !this.BlockLevelDto){
        return
      }
      this.levelMaster.parentId = this.BlockLevelDto.id
    }else{
      this.levelMaster.parentId = 0;
    }
    console.log('this.levelMaster',this.levelMaster)
    // return
    this.levelMasterService.save(this.levelMaster)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.levelMasterDialog = false;
          this.loadPage(this.page);
          this.onchangeSchema();
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    if(this.filter){
      this.filterTable(this.filter);
    }
    this.loadPage(event.page);
    
  }

  async filterData(page?) {
    console.log("djdjdjhdhhdhdhdhdhdhhdhdhdhdhdhhdhdhdhdhdhdhhdbbhd");
    this.filterProps = [];
   
   
   
   
    this.filterProps = this.filterProps ? this.filterProps : [];
    
    console.log("kjhgfghjhgfghuuuuuuuuuuu", this.filterProps);
    this.loadPage(page ? page : 1);
  }

  getlevelMasterbyId(id): Promise<any> {
    return new Promise<any>((resolve) => {
      this.levelMasterService.getLevelMaster(id).subscribe(
        (res) => {
          resolve(res.body);
        },
        () => {
          resolve(null);
          // this.onError();
        }
      );
    });
  }

  checkCodeNo() {
    let filterQuery = {
      page: 0,
      size: 1000,
    };
    const filter = [
      {
        key: 'code',
        operation: 'equals',
        value: this.levelMaster.code,
      },
    ];
    if (this.levelMaster.id) {
      filter.push({
        key: 'id',
        operation: 'notEquals',
        value: String(this.levelMaster.id),
      });
    }

    this.levelMasterService.LevelMasterDtoFilter(
      { ...filterQuery },
      filter
    ).subscribe(
      (res: HttpResponse<LevelMaster[]>) => {
        if (res.body.length != 0) {
          this.codeError = 'LGD Code Already Exists';
        } else {
          this.codeError = null;
        }
      },
      () => {
        this.onError();
      }
    );
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
        this.departmentDto = res.body[0];
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
        this.onchangeSchema()
      },
      () => { }
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
        this.treeLevelData = this.transformDataIntoTreeNodeFormat1(res.body);
      },
      () => { }
    );
  }

  onLevelType(event) {
    this.levelMaster.levelTypeDto = event?.node?.data;
    if (this.levelMaster.levelTypeDto.name !== 'State') {
      let filterQuery = {
        page: 0,
        size: 1000,
      };
      this.levelMasterService.LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'id',
            operation: 'equals',
            value: event?.node?.data?.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.stateList = this.transformDataIntoTreeNodeFormat1(res.body);
          },
          () => { }
        );
    }
  }
  onStateLevelChange($event?) {
    let parentId = $event?.node?.data?.id;
    this.levelMasterService.levelMasterByFilter(parentId).subscribe((res:any) => {
      if(res.body){
        this.districtLevelList = this.transformDataIntoTreeNodeFormat1(res.body?.data);
        console.log('this.stateList',this.stateList)
      }
      // this.stateListDto = res.body[0];
    },() => { }
  );
    // if ($event) {
    //   let filterQuery = {
    //     page: 0,
    //     size: 1000,
    //   };
    //   this.levelMasterService
    //     .LevelMasterDtoFilter({ ...filterQuery }, [
    //       {
    //         key: 'parentId',
    //         operation: 'equals',
    //         value: this.stateListDto?.data?.parentId,
    //       },
    //       // {
    //       //   key: 'name',
    //       //   operation: 'contains',
    //       //   value: $event.term.toUpperCase(),
    //       // },
    //     ]).subscribe(
    //       (res: HttpResponse<LevelMasterDto[]>) => {
    //         this.districtLevelList = res.body;
    //       },
    //       () => { }
    //     );
    // } else {
    //   let filterQuery = {
    //     page: 0,
    //     size: 1000,
    //   };
    //   this.levelMasterService
    //     .LevelMasterDtoFilter({ ...filterQuery }, [
    //       {
    //         key: 'parentId',
    //         operation: 'equals',
    //         value: this.stateListDto?.data?.id,
    //       },
    //     ])
    //     .subscribe(
    //       (res: HttpResponse<LevelMasterDto[]>) => {
    //         this.districtLevelList = res.body;
    //       },
    //       () => { }
    //     );
    // }
  }

  onDistrictLevelChange($event?) {    
    this.BlockLevelDto=null; 
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 1000,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto?.data?.id,
          },
          // {
          //   key: 'name',
          //   operation: 'contains',
          //   value: $event.term.toUpperCase(),
          // },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 1000,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto?.data?.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onStateLevelfilterChange($event?, id?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: 1,
          },
          {
            key: "name",
            operation: "contains",
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            console.log(res.body);
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            console.log(res.body);
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelFilterChange($event?, id?) {
    console.log("logs", id);
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService.LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: id ? id : this.districtListDto?.data?.id,
          },
          {
            key: "name",
            operation: "contains",
            value: $event.term.toUpperCase(),
          },
        ]).subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            console.log(res.body);
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: id ? id : this.districtListDto?.data?.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            console.log(res.body);
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: this.blockVillageDto.id,
          },
          {
            key: "name",
            operation: "contains",
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: "parentId",
            operation: "equals",
            value: this.blockVillageDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  filterTable(event:any){
    this.filter = event;
    this.filterProps = [];
    console.log('dddd',event)

    if (
      event.district
    
    ) {
      this.filterProps.push({
        key: "levelOneName",
        operation: "equals",
        value: event.district.toUpperCase(),
      });
    }
    if (
      event.block
     
    ) {
      this.filterProps.push({
        key: "levelTwoName",
        operation: "equals",
        value: event.block.toUpperCase(),
      });
    }
    if (event.village) {
      this.filterProps.push({
        key: "levelThreeName",
        operation: "equals",
        value: event.village.toUpperCase(),
      });
    }
    this.loadPage(1)

  }

  onLevelParentType(event){
    this.SublevelTypeData = [];
    let parentId = event?.node?.data?.parentId;
    this.levelMaster.levelTypeDto = event?.node?.data;
    this.levelMasterService.levelMasterByFilterId(parentId).subscribe((res:any) => {
        if(res.body){
          this.SublevelTypeData = this.transformDataIntoTreeNodeFormat1(res.body?.data);
          console.log('this.SublevelTypeData',this.SublevelTypeData)
        }
        // this.stateListDto = res.body[0];
      },() => { }
    );
  }

  transformDataIntoTreeNodeFormat1(data): TreeNode[] {
    const result: TreeNode[] = [];
    const map = new Map();
    data.forEach((item: any) => {
        map.set(item.id, { label: item.name, data: item, children: [] });
    });

    data.forEach((item: any) => {
        const parent = map.get(item.parentId);
        if (parent) {
            parent.children.push(map.get(item.id));
        } else {
            result.push(map.get(item.id));
        }
    });

    // this.filterNodes(result);
    const uniqueSet = new Set<string>();
    this.filterAndRemoveDuplicates(result, uniqueSet);
    return result;
}
filterAndRemoveDuplicates(data: any[], uniqueSet: Set<string>): any[] {
  return data.filter(node => {
    const nodeKey = `${node.data.id}_${node.data.name}`; // Create a unique identifier for the node

  //   if (uniqueSet.has(nodeKey) || node.data.levelTypeDto.id === 4 || node.data.levelTypeDto.name === "Panchayat") {
  //     return false; // Exclude this node
  //   }

    uniqueSet.add(nodeKey); // Add the node to the set to track duplicates

    if (node.children && node.children.length > 0) {
      // Recursively filter children
      node.children = this.filterAndRemoveDuplicates(node.children, uniqueSet);
    }

    return true; // Include this node
  });
}

filterNodes(data: any[]): any[] {
  return data.filter(node => {
      if (node.data.levelTypeDto.id === 4 || node.data.levelTypeDto.name === "Panchayat") {
          return false; // Exclude this node
      }

      if (node.children && node.children.length > 0) {
          // Recursively filter children
          node.children = this.filterNodes(node.children);
      }

      return true; // Include this node
  });
}

clearTreeSelect() {
  // this.chosenFile = [];
}



nodeUnselect(event) {
  // this.updateSelectedLevelType(event);
}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
