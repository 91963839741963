<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Agency Report</h2>
</div>
<div class="container-fluid bg">
    <div class="container ">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">
                    From Date</label>
                <p-calendar dateFormat="dd/mm/yy" [readonlyInput]="true" [maxDate]="minDate" [(ngModel)]="fromDate"
                    appendTo="body"></p-calendar>&nbsp;
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">
                    To Date</label>
                <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="minDate" [readonlyInput]="true" [minDate]="fromDate" dateFormat="dd/mm/yy"
                    appendTo="body"></p-calendar>
                &nbsp;
                <p-button label="Submit" class="submit" [disabled]="fromDate == '' ||
                toDate == ''" (click)="getProjectsFilter(1,'action')"></p-button>
            </div>
        </div>
    </div>
    <div class="row tableDesign" *ngIf="isShowTable">
        <div class="row">
            <div class="col-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass" class="head">
                            Agency List
                        </h5>
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 px-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Agency Name</th>
                            <th scope="col">Authorized Mobile Number</th>
                            <th scope="col">District
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let data of projectList;let i =index'>
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.agencyName}}</td>
                            <td>{{data.authorizedPersonMobileNumber}}</td>
                            <td>{{data.levelMasterName}}</td>
                        </tr>
                    </tbody>
                </table>
                <p-paginator [rows]="itemsPerPage" [totalRecords]="totalCount" (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
            </div>
        </div>
    </div>
</div>