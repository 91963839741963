<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>{{hamHeader}}</h2>
</div>
<div class="container-fluid bg">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                </ng-template>
                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center mt-3 bgFilter">
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Agency Name</label>
                <input type="text" class="form-control" name="name" autocomplete="off" [(ngModel)]="filter.agencyName"
                    maxlength="60" id="name" appSerachInputRestriction/>
            </div>&nbsp;
            <div class="col-sm-2 col-md-1 mt-3">
                <p-button label="Search" class="submit" (click)="submitForFilter('','')"></p-button>
            </div>
            <!-- <div class="col-sm-2 col-md-2 mt-3" *ngIf="filterQuery.agencyName != '' ">
                <span>Agency Name <i class="pi pi-times iconClose" (click)="clear('agencyName')"></i>
                </span>
            </div> -->
        </div>
    </div>
    <div class="row tableDesign">
        <div class="reqButton">
            <h5 [ngClass]="fontService.headingClass" class="pt-2 head">
                Requisitioning Departments List
            </h5>
        </div>
        <div #customerTable id="entities" class="col-md-12 px-5 mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Name of the Requisitioning
                            Department <span (click)="submitForFilter('RequestingAgencyName' ,sortDirection)"><i
                                    class="fa fa-sort"></i> </span> </th>
                        <th scope="col">No. of
                            Projects
                            Involved <span (click)="submitForFilter('NoOfProjects',sortDirection)"><i
                                    class="fa fa-sort"></i> </span></th>
                        <th scope="col">Total funds
                            Received <span (click)="submitForFilter('TotalFundsReceived',sortDirection)"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Total funds
                            spent <span (click)="submitForFilter('TotalFundsSpent',sortDirection)"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Total Amount
                            Available <span (click)="submitForFilter('TotalAmountAvailable',sortDirection)"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let data of agencyList;let i = index'>
                        <td>{{generateIndex(i)}}</td>
                        <td>{{data.RequestingAgencyName}}</td>
                        <td>{{data.NoOfProjects}}</td>
                        <td>{{data.TotalFundsReceived | currency:'INR'}}</td>
                        <td>{{data.TotalFundsSpent | currency:'INR'}}</td>
                        <td>{{data.TotalAmountAvailable | currency:'INR'}}</td>
                        <td>
                            <span (click)="openNew('view',data)">
                                View
                            </span>
                            <span *ngIf="roleId" (click)="openNew('edit',data)">
                                Edit
                            </span>
                            <!-- <span *ngIf="roleId">
                                <i class="bi bi-trash3-fill" (click)="deleteAgency(data)"></i>
                            </span> -->
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" width="419px" alt="">
            </div>
            <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            Requesting Agency
        </p-header>
        <ng-template pTemplate="content">
            <form #agencyForm="ngForm">
                <div class="col-md-12">
                    <div class="form-inner-panel">
                        <div class="row gy-2">
                            <div class="col-md-12">
                                <h6 [ngClass]="fontService.headingClass" class="">
                                    Requesting Agency Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="agency.name" for="agencyName">Name</label>
                                <input type="text" id="agencyName" name="agencyName" (ngModelChange)="checkName()"
                                    maxlength="100" autocomplete="off" [disabled]="true"
                                    oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [(ngModel)]="agency.name" required
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.name }"
                                    class="form-control" />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{
                                        showNameError }}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass">Government
                                    Type</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" [items]="govermentType"
                                    name="govermentType" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                    [clearable]="true" class="form-control" required [(ngModel)]="agency.govermentType"
                                    [disabled]="viewOnly"
                                    [ngClass]="{'is-invalid': ngSelectInvalid && !agency.govermentType}" appNgSelectInputRestriction>
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    for="designaiton">Address 1</label>
                                <input type="text" id="designaiton" [(ngModel)]="agency.address1" name="address1"
                                    class="form-control" maxlength="50" [disabled]="viewOnly" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    for="designaiton">Address 2</label>
                                <input type="text" id="designaiton" name="address2" [disabled]="viewOnly"
                                    [(ngModel)]="agency.address2" class="form-control" maxlength="50" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    for="designaiton">Street</label>
                                <input type="text" id="designaiton" name="street" [disabled]="viewOnly"
                                    class="form-control" [(ngModel)]="agency.street" maxlength="50" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="designaiton">City</label>
                                <input type="text" id="designaiton" name="city" required [disabled]="viewOnly"
                                    class="form-control" [(ngModel)]="agency.city" maxlength="50"
                                    [ngClass]="{'is-invalid': ngSelectInvalid && !agency.designation}" />
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">District Name</label>

                                <input type="text" name="districtName" required [disabled]="viewOnly"
                                    class="form-control" [(ngModel)]="agency.district" maxlength="50"
                                    [ngClass]="{'is-invalid': ngSelectInvalid && !agency.designation}" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelName">State</label>
                                <ng-select [appendTo]="'.p-dialog'" id="scheme" required name="state"
                                    [(ngModel)]="agency.state" [items]="state" bindLabel="name" appearance="outline"
                                    [disabled]="viewOnly" (search)="onLevelTypeChange($event)" [closeOnSelect]="true"
                                    [clearable]="true" [ngClass]="{'is-invalid': !levelMasterDto && ngSelectInvalid}"
                                    class="form-control" appNgSelectInputRestriction>
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="designaiton">Pincode</label>
                                <input type="text" id="designaiton" name="pincode" required [disabled]="viewOnly"
                                    class="form-control" [(ngModel)]="agency.pincode" maxlength="6" minlength="6"
                                    #pinCode="ngModel" ngModel
                                    [ngClass]="{'is-invalid': ngSelectInvalid && !agency.pincode}" />
                                <div *ngIf="pinCode.dirty && pinCode.invalid">
                                    <small class="form-text text-danger" *ngIf="pinCode.control.errors.minlength">
                                        Pincode is required to be 6 number.
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="row gy-2">
                            <div class="col-md-12">
                                <h6 [ngClass]="fontService.headingClass" class="mt-3">
                                    Authorized Person Details
                                </h6>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="agency.name" for="agencyName">Agency Name</label>
                                <input type="text" id="agencyName" name="AuthName" (ngModelChange)="checkName()"
                                    maxlength="100" autocomplete="off" [disabled]="viewOnly" required
                                    oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [(ngModel)]="agency.agency"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.agency }"
                                    class="form-control" />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{
                                        showNameError }}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="user.mobile.nunmber" for="mobileNo">Mobile No</label>
                                <input type="number" id="mobileNo" class="form-control" name="mobileNo"
                                    autocomplete="off" required [(ngModel)]="agency.mobile" minlength="10"
                                    pattern="^[6-9]\d{9}$"
                                    [ngClass]="{ 'is-invalid': !agency.mobile && ngSelectInvalid }"
                                    [disabled]="viewOnly" #mobileNo="ngModel" ngModel maxlength="10" />
                                <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                                    <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                                        Please enter valid mobile number
                                    </small>
                                </div>
                                <!-- 
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile Number.</small>
                            </div> -->
                                <div *ngIf="showMobileNoError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass"
                                        jhiTranslate="error.showMobileUniError">{{showMobileNoError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="user.email" for="email">Email</label>
                                <input type="email" id="email" name="email" class="form-control" [disabled]="viewOnly"
                                    [(ngModel)]="agency.email" onblur="this.value =this.value.trim();"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.email }" required
                                    (input)="validateEmail()" maxlength="75" autocomplete="off"
                                    (ngModelChange)="checkemail()" />
                                <div *ngIf="emailError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please
                                        enter a
                                        valid Email Address.</small>
                                </div>
                                <div *ngIf="showEmailUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass"
                                        jhiTranslate="error.showEmailUniError">{{showEmailUniError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    jhiTranslate="user.designaiton" for="designaiton">Designaiton</label>
                                <input type="text" id="designaiton" name="designaiton" class="form-control"
                                    maxlength="50" [disabled]="viewOnly" required
                                    oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [(ngModel)]="agency.designation" autocomplete="off"
                                    [ngClass]="{'is-invalid': ngSelectInvalid && !agency.designation}" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label " [ngClass]="fontService.labelClass"
                                    for="mobileNo">Office
                                    Mobile No</label>
                                <input type="text" id="mobileNo" class="form-control" name="officemobileNo"
                                    autocomplete="off" [disabled]="viewOnly" [(ngModel)]="agency.offliceMobile"
                                    minlength="10" pattern="^[6-9]\d{9}$"  #OfficemobileNo="ngModel" ngModel
                                    [ngClass]="{ 'is-invalid': !agency.mobile && ngSelectInvalid }" maxlength="10" />
                                    <div *ngIf="OfficemobileNo.dirty && OfficemobileNo.invalid">
                                        <small class="form-text text-danger" *ngIf="OfficemobileNo.control.errors.pattern">
                                            Please enter valid mobile number
                                        </small>
                                    </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" for="email">Office
                                    Email</label>
                                <input type="text" id="email" [disabled]="viewOnly" name="Officeemail"
                                    class="form-control" [(ngModel)]="agency.ooficeemail"
                                    onblur="this.value =this.value.trim();"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !agency.email }"
                                    (input)="validateEmail()" maxlength="75" autocomplete="off"
                                    (ngModelChange)="checkemail()" />
                                <div *ngIf="emailError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please
                                        enter a
                                        valid Email Address.</small>
                                </div>
                                <div *ngIf="showEmailUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass"
                                        jhiTranslate="error.showEmailUniError">{{showEmailUniError}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!viewOnly" class="mt-3">
                    <button pButton pRipple icon="pi pi-times" iconPos="left"
                        class="p-button-text custom-button cancelButton" (click)="hideDialog()">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.cancelbutton"></span>
                    </button> &nbsp;
                    <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button saveButton"
                        [disabled]="agencyForm.invalid" (click)="saveAgency('edit')" type="submit">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.savebutton"></span>
                    </button>
                </div>
            </form>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>