import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { DecimalPipe } from '@angular/common';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { SigninService } from '../../auth/signin/signin.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { HttpResponse } from '@angular/common/http';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage1',
  templateUrl: './stage1.component.html',
  styleUrls: ['./stage1.component.scss']
})
export class Stage1Component implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  
  checked: boolean;
  project?: Project;
  totalExtent: any
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  projectDistrict
  requestingDepartment: any = ''
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;

  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];

  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;

  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  totalLandPrivate: any;
  throughAct: any;
  taluk: any[];
  villages: any[];
  sampleNumber: any[];
  projectName: boolean;
  districtsByProject: any;
  totalExtendsValue: any = {
    totalExtent: '',
    fundAllocated: '',
    fundReceived: ''
  };

  isErrorAct: boolean = false;
  currentIndex: any = 0;
  format = '.0-2';
  totals: any;
  publicationImage: any;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    private paymentService: PaymentService,
    protected notificationService: NotificationService,
    private _decimalPipe: DecimalPipe,
    private loginService: SigninService,
    private projectService: ProjectService
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }

  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];

  getProjects() {
    this.projectService.getProjects().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
      }
    )
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.spinner.show()
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.fileUpload = res.body.fileDownloadUri;
        this.spinner.hide()
        this.projectForm.get('publicationImage').setValue(res.body.fileName)

        this.publicationImage = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.spinner.hide()
      }
    );
  }
  removeDocument() {
    this.paymentService.deleteimage(this.publicationImage).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.projectForm.get('publicationImage').setValue('')
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }
  viewDocument() {
    this.spinner.show()
    this.paymentService.viewimage(this.publicationImage).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    },(err)=> {
      this.spinner.hide()

    });
  }


  totalExtend() {

  }

  generateIndex() {
    this.sampleNumber = [];
    let obj
    for (let index = 0; index <= 50; index++) {
      if (index == 0) {
        obj = {
          key: '-'
        }
      } else {
        obj = {
          key: index
        }
      }
      this.sampleNumber.push(obj)
    }
  }

  submit() {
    let beneficaryData = this.projectForm.value
    let landFrom = []
    beneficaryData.LandDetailsForm.forEach(element => {
      let data = {
        "projectId": this.projectId,
        "districtId": this.districtId,
        "talukId": element.talukName.id,
        "villageId": element.villageName.id,
        "totalExtentLand": element.total,
        "throughPrivateNegotiation": element.privateNegotitaion,
        "throughAct": element.throughAct,
        "nameOfBlock": element.nameOfBlock.key != '-' ? element.nameOfBlock.key : 0,
        "nameOfUnit": element.nameOfUnit.key != '-' ? element.nameOfUnit.key : 0
      }
      landFrom.push(data)
    });
    this.projectService.saveProjectDetails(landFrom, this.publicationImage).subscribe(
      (res) => {
        this.notificationService.alertSuccess('Project Details saved successfully!', '');
        this.router.navigateByUrl('/stage1-view')
        this.forms();
        this.addSkills();
        this.totalLandPrivate = '';
        this.throughAct = ''
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );
  }
  onSelectDistrict(e) {
    this.projectService.talukAndVillages(e, 4).subscribe(res => {
      this.taluk = res
    })
  }
  selectProject(e) {
    this.totalExtendsValue = {
      totalExtent: '',
      fundAllocated: '',
      fundReceived: ''
    }
    if (e.id) {
      this.projectDistrict = ''
      this.projectName = e.projectName
      this.projectId = e.id
      this.projectService.getDistrict(this.projectId).subscribe((res: any) => {
        this.districtsByProject = res.body.data
      })
    } else {
      this.isShowTable = false
    }

  }



  OnSelectTaluk(e, i) {
    this.spinner.show()
    this.projectService.talukAndVillages(e.id, 6).subscribe(res => {
      this.spinner.hide()
      this.villages = res
      this.detailsOfLand.controls[i].get('villageName').setValue('');
    },(err)=>{
      this.spinner.hide()
    })
  }
  ngOnInit() {
    this.forms();
    this.addSkills();
    this.generateIndex()
    this.getProjects();
    this.loginService.getuserDto().subscribe(
      (res) => {
        // this.districtId = res.body.levelMasterId

      })
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
    // this.loginService.getuserDto().subscribe(
    //   (res) => {
    //     this.user = res.body;
    //   },
    //   (onError) => {}
    // );
    this.cols = [
      {
        field: 'projectName',
        header: 'Name of the project',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'agencyId',
        header: 'Requesting Agency',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDowns',
      },
      {
        field: 'landUnitId',
        header: 'Lant Unit',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'type',
      },

      {
        field: 'tentativeLandCost',
        header: 'Project Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);

  }

  getUserrole() {
  }

  addSkill(i) {
    this.currentIndex = i
    let totalLand: number = 0;
    let form = this.projectForm.controls.LandDetailsForm.status
    if (form == 'VALID') {
      this.detailsOfLand.push(this.detailsform())
    }
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      let text = event.target.value
      if (text.includes('.')) {
        let decimals = text.split('.');

        if (decimals[1].length >= 4) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }

  selectDistrict(e) {
    this.totalExtendsValue = {
      totalExtent: '',
      fundAllocated: '',
      fundReceived: ''
    }

    this.districtId = e.id
    this.spinner.show()
    this.projectService.getExtend(this.districtId, this.projectId).subscribe((res: any) => {
      this.totalExtendsValue = res.body.data
      this.spinner.hide()
      this.isShowTable = true
    }, (err) => {
      this.spinner.hide()
    })

    this.projectService.getRemain(this.districtId, this.projectId).subscribe((res: any) => {
      this.remainValue = res.body.data.alreadyTotalExtent
      // this.isShowTable = true
    })

    this.onSelectDistrict(e.id)

  }
  forms() {
    this.projectForm = this.formBuilder.group({
      publicationImage: ['', Validators.required],
      LandDetailsForm: this.formBuilder.array([]),
    })
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      talukName: ['', Validators.required],
      villageName: ['', Validators.required],
      privateNegotitaion: ['', Validators.required],
      throughAct: ['', Validators.required],
      total: ['', Validators.required],
      totalInSqM: ['', Validators.required],
      nameOfBlock: ['', Validators.required],
      nameOfUnit: ['', Validators.required],
    });
  }


  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('privateNegotitaion').value);
    let throughAct: any = Number(this.detailsOfLand.controls[i].get('throughAct').value)
    let total: any = String(privateNegotitaion + throughAct)
    let heaToSqs = Number(total) * 10000


    if (total.includes('.')) {
      let split = total.split('.');
      let split1 = split[0];
      let split2 = split[1].slice(0, 4)
      let final = split1 + '.' + split2
      let finalValue = Number(final)
      this.detailsOfLand.controls[i].get('total').setValue(finalValue);
      let heaToSq = Number(finalValue) * 10000
      this.detailsOfLand.controls[i].get('totalInSqM').setValue(heaToSq);
    } else {
      this.detailsOfLand.controls[i].get('total').setValue(total);
      this.detailsOfLand.controls[i].get('totalInSqM').setValue(heaToSqs);
    }
    // }
    let totalLand: any = 0;
    let act: any = 0
    let totals: any = 0

    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.privateNegotitaion);
      act += Number(element.throughAct);
      totals += Number(element.total);

    });
    this.totalLandPrivate = totalLand
    this.throughAct = act
    this.totals = totals

  }
  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }



  ngOnDestroy(): void {
    this.notificationService.hideLoader()
  }
}