import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<Agency[]>;
import { Agency, AgencyDto } from './stage3';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Injectable({ providedIn: 'root' })
export class stage3Service {
  constructor(private http: HttpClient,private encryptService:EncryptService) { }
  protected resourceFliterUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail');
  protected getBaseURL = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getList');
  protected resourceVaildFliterUrl = environment.SERVER_API_URL + ('api/getIAgencyValidByFilter');
  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail')
  protected resourceDeleteUrl = environment.SERVER_API_URL + ('api/deleteImplementingAgency')
  protected getAgencyUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner')
  protected getIFSC = environment.SERVER_API_URL + ('api/fetchBankInfoByIfscCode')
  protected getAadhar= environment.SERVER_API_URL + ('api/benificiaryLandOwner/fetchByAadhaarNumber')
  protected getLevelMasters = environment.SERVER_API_URL + ('api/levelMasterList');
  protected filterUrl = environment.SERVER_API_URL + ('api/projectSurvey/getProjectTaluk')
  protected TalukVillageUrl = environment.SERVER_API_URL + ('api/getLevelMasterByParentId')
  protected getTalukAndVilliages = environment.SERVER_API_URL + ('api/projectSurvey/getProjectTaluk')
  protected stage3Remain = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getDetails')
  protected accountValidation = environment.SERVER_API_URL + ('api/pennyDropAccountValidation')

  protected getConfirmValues = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getConfirmed')
  protected razorIFSC = environment.RAZOR


  protected projectDetails = environment.SERVER_API_URL + ('api/projectDetailLocation')

  stage2Filter(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.projectDetails}`, {
      params: options,
      observe: 'response',
    });
  }

  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .get<Agency[]>(this.getBaseURL, { params: options, observe: 'response' })
  }
  talukAndVillage(parentId: any, district,taluk,village) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?projectId=${parentId}&district=${district}&taluk=${taluk}&village=${village}`, { observe: 'response' })
  }
  talukAndVillageSurvey(parentId: any,district, LevelType,village,survey) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?projectId=${parentId}&district=${district}&taluk=${LevelType}&village=${village}&surveyNumber=${survey}`, { observe: 'response' })
  }
  talukAndVillageSubDiv(parentId: any,district, LevelType,village,survey,subDiv) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?projectId=${parentId}&district=${district}&taluk=${LevelType}&village=${village}&surveyNumber=${survey}&subDivisionNumber=${subDiv}`, { observe: 'response' })
  }
  talukAndVillagPartSubDiv(parentId: any,district, LevelType,village,survey,subDiv,partDiv) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?projectId=${parentId}&district=${district}&taluk=${LevelType}&village=${village}&surveyNumber=${survey}&subDivisionNumber=${subDiv}&partSubDivisionNumber=${partDiv}`, { observe: 'response' })
  }
  stae3Remain(parentId: any,district, LevelType,village,survey,subDiv) {
    return this.http
      .get<any[]>(`${this.stage3Remain}?projectId=${parentId}&district=${district}&taluk=${LevelType}&village=${village}&surveyNumber=${survey}&subDivisionNumber=${subDiv}`, { observe: 'response' })
  }
  getConfirmData() {
    return this.http
      .get<any[]>(`${this.getConfirmValues}`, { observe: 'response' })
  }
  getConfirmProject(id) {
    return this.http
      .get<any[]>(`${this.getConfirmValues}?projectId=${id}`, { observe: 'response' })
  }

  validFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<Agency[]>(this.resourceVaildFliterUrl, filter, { params: options, observe: 'response' })
  }

  save(agency?: any): Observable<EntityArrayResponseType> {
    return this.http
      .post<AgencyDto[]>(this.resourceFliterUrl, agency, { observe: 'response' })
  }
  accountValidations(agency?: any): Observable<any> {
    return this.http
      .post(this.accountValidation, agency, { observe: 'response' , responseType: 'text'})
  }
  getLevelMaster(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getLevelMasters}`, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

  getOneAgency(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getAgencyUrl}?id=${id}`, { observe: 'response' });
  }

  getIFSCCode(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getIFSC}?ifscCode=${id}`, { observe: 'response' });
  }

  getAadhaar(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getAadhar}?aadharNumber=${id}`, { observe: 'response' });
  }
  saveBeneficiary(filter,id: number): Observable<HttpResponse<any>> {
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(filter))}
    return this.http.post<AgencyDto>(`${this.getAgencyUrl}`,val, { observe: 'response' });
  }


  getProjects(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      // params: options,
      observe: 'response',
    });
  }
  beneficiary(id): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
  ifsc(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.razorIFSC}/${id}`, { observe: 'response' });
  }
  talukAndVillages(parentId: any, LevelType) {
    return this.http
      .post<AgencyDto[]>(`${this.TalukVillageUrl}?parentId=${parentId}&levelTypeId=${LevelType}`, { observe: 'response' })
  }

  deleteAgency(id): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
}