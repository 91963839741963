

export class Project {
    id: number;
    projectName: string;
    workId: string;
    scheme: any;
    villageMaster: any;
    districtMaster: any;
    blockMaster: any;
    finYear: any;
    administrativeSanctionNo: string;
    administrativeSanctionDate: Date;
    technicalSanctionNo: string;
    technicalSanctionDate: Date;
    agreementDate: Date;
    fileUpload:string;
    fileUpload1:string;
    fileUpload2:string;


    publicContribution: string;
    sanctionImageCopy: string;
    totalCost: number;
    utilizedTotalCost:number;
    publicContributionAmount: number;
    administrativeSanctionAmount: number;
    technicalSanctionAmount:number;
    agreementNo:string;
    agreementAmount:number;
    contributionPercentage: number;
    activeFlag: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
  }
  