<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Perform eSign</h2>
</div>
<div class="container-fluid bg">
    <div class="container row mx-auto bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project Name</label>
            <ng-select id="department" bindValue="id" bindLabel="projectName" appearance="outline"
                [(ngModel)]="stage2SearchFilter.project" [items]="projectList" class="form-control"
                (change)="getExtent('projectName')" (change)="selectProject($event)">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Districts</label>
            <ng-select id="department" bindValue="id" bindLabel="name" appearance="outline"
                (change)="SelectDistrict($event)" (change)="getExtent('district')"
                [(ngModel)]="stage2SearchFilter.district" [items]="districkList" class="form-control">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk</label>
            <ng-select id="type" [items]="taluk" bindValue="id" bindLabel="name" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="OnSelectTaluk($event)"
                (change)="getExtent('taluk')" [(ngModel)]="stage2SearchFilter.taluk"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="type" [items]="villages" bindValue="id" bindLabel="name" (change)="OnSelectVillage($event)"
                (change)="getExtent('village')" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [(ngModel)]="stage2SearchFilter.village"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Total extent Acquired(In
                hectares)</label>
            <input type="text" class="form-control" name="name" readonly [(ngModel)]="stage2SearchFilter.totalExtent"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
        </div>
        <div class="col-sm-3 col-md-3 mt-3">
            <p-button label="Submit" class="submit" (click)="getSurvey()"></p-button>
        </div>
    </div>
    <div class="row" *ngIf="isShowTable">
        <div class="container-fluid bg">
            <div class="tableDesign">
                <div class="row">
                    <div class="col-md-2">
                        <p-toolbar styleClass="toolbar">
                            <ng-template pTemplate="left">
                                <h5 [ngClass]="fontService.headingClass">
                                    Payment Status
                                </h5>
                            </ng-template>
                        </p-toolbar>
                    </div>
                    <div class="col-md-3">
                        <label class="form-control-label" [ngClass]="fontService.labelClass">Survey No</label>
                        <input type="text" class="form-control" autocomplete="off"
                            [(ngModel)]="stage2SearchFilter.survey" maxlength="60"
                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                    </div>
                    <!-- <div class="col-md-3">
                        <label class="form-control-label" [ngClass]="fontService.labelClass">Mobile No</label>
                        <input type="number" class="form-control" autocomplete="off"
                            [(ngModel)]="stage2SearchFilter.mobile" maxlength="60"
                            oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                            onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                    </div> -->
                    <div class="col-md-3 mt-3">
                        <p-button label="Filter" class="submit" (click)="getSurvey()"></p-button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div #customerTable id="entities" class="col-md-12  px-5">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="background-color: #017DC3;" scope="col">S.No
                                    </th>
                                    <th style="background-color: #017DC3;" scope="col">Project Name <span
                                            (click)="getSurvey('projectName')"><i class="fa fa-sort"></i> </span>
                                    </th>
                                    <th style="background-color: #017DC3;" scope="col">Total Extent <span
                                            (click)="getSurvey('totalAmount')"><i class="fa fa-sort"></i> </span>
                                    </th>

                                    <th style="background-color: #017DC3;" scope="col">Total Count <span
                                            (click)="getSurvey('totalAmount')"><i class="fa fa-sort"></i> </span>
                                    </th>
                                    <th style="background-color: #017DC3;" scope="col">Total Amount <span
                                            (click)="getSurvey('totalAmount')"><i class="fa fa-sort"></i> </span>
                                    </th>
                                    <th style="background-color: #017DC3;" scope="col">Status <span
                                            (click)="getSurvey('status')"><i class="fa fa-sort"></i> </span>
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of beneficiaryData;let i =index;trackBy: trackByFn">
                                    <td>{{generateIndex(i)}}</td>
                                    <td>{{data.benificiaryLandDetail?.projectID?.projectName}}</td>
                                    <td>{{data?.totalExtentOwned}}</td>
                                    <td>{{data?.count}}</td>
                                    <td>{{data?.sumOfTotalAmount}}</td>
                                    <td>{{data?.status}}</td>
                                    <td *ngIf="!enableSignButton[i] && data.status ==='Maker Sign Pending' ">
                                        <button pButton pRipple (click)="enable(data,i)" type="submit"
                                            class="p-button-primary custom-button approve_btn">
                                            <span class="button-label" [ngClass]="fontService.buttonClass">Enable
                                                eSign</span>
                                        </button>
                                    </td>
                                    <td *ngIf="enableSignButton[i]  && data.status ==='Maker Sign Pending' ">
                                        <form ngNoForm action="https://emgateway.emsigner.com/eMsecure/V3_0/Index"
                                            method="POST">
                                            <input id="Parameter1" name="Parameter1" type="hidden" [value]='param1' />
                                            <input id="Parameter2" name="Parameter2" type="hidden" [value]='param2' />
                                            <input id="Parameter3" name="Parameter3" type="hidden" [value]='param3' />
                                            <button pButton pRipple onclick="submit()" type="submit"
                                                *ngIf="(selectIndex==i)"
                                                class="p-button-success custom-button approve_btn">
                                                <span class="button-label" [ngClass]="fontService.buttonClass">Perform
                                                    E-sign
                                                </span>
                                            </button>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center" *ngIf="totalCount == 0">
                            <img src="../../../assets/img/notFound.png" width="419px" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>