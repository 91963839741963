import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { Project } from './project.model';
import { EncryptService } from '../shared/encrypt.service';

@Injectable({ providedIn: 'root' })
export class ProjectService {

  protected calculatePercentageUrl = environment.SERVER_API_URL + ('api/calculatePercentage');
  protected saveProjectDtoUrl = environment.SERVER_API_URL + ('api/project');
  protected updateProjectDtoUrl = environment.SERVER_API_URL + ('api/project/update');

  
  protected saveProjectDtoUrlLand = environment.SERVER_API_URL + ('api/projectVirtualAccount')

  protected projectDetails = environment.SERVER_API_URL + ('api/projectDetailLocation')
  protected tamilNilam = environment.SERVER_API_URL + ('api/nicIntegration')

  protected projectSurvey = environment.SERVER_API_URL + ('api/projectSurvey')

  protected beneficiaryDetails = environment.SERVER_API_URL + ('api/benificiaryLandOwner/get')
  protected getProject = environment.SERVER_API_URL + ('api/project/get')
  protected projectSurveyRemind = environment.SERVER_API_URL + ('api/projectSurvey/getDetails')
  protected projectLocation = environment.SERVER_API_URL + ('api/projectLocation/getProjectDistricts')
  protected getBeneficiarys = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getListByProjectId')

  protected stage1Extend = environment.SERVER_API_URL + ('api/projectLocation/getTotalExtendByProjectDistricts')
  protected stage1Remind = environment.SERVER_API_URL + ('api/projectDetailLocation/getDetails')
  protected filterUrl = environment.SERVER_API_URL + ('api/project/getList')
  protected getProjectforProceeding = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getListByProjectId')

  protected getAgencys = environment.SERVER_API_URL + ('api/requestingAgency/getList')
  protected getYears = environment.SERVER_API_URL + ('api/finYearList')

  protected getTalukAndVilliages = environment.SERVER_API_URL + ('api/projectDetailLocation/getProjectTalukVillage')
  protected getBeneficiaryVillage = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getProjectDistrictTalukVillage')
  protected estimatioCharge = environment.SERVER_API_URL + ('api/establishmentCharge')
  protected getUnits = environment.SERVER_API_URL + ('api/landUnit') 
  protected laoCategory = environment.SERVER_API_URL + ('api/laoCategory');
  protected laoCategoryForUser = environment.SERVER_API_URL + ('api/laoCategory/getMakersList');
  protected getOneUnit = environment.SERVER_API_URL + ('api/project')
  protected getOneVirtuals = environment.SERVER_API_URL + ('api/projectVirtualAccount')

  protected adminLAOs =environment.SERVER_API_URL + ('api/getAdminListByLaoCategory')
  protected getLevelMasters = environment.SERVER_API_URL + ('api/allLevelMasterList');
  protected TalukVillageUrl = environment.SERVER_API_URL + ('api/getLevelMasterByParentId')
  // protected voucherGenerate = environment.SERVER_API_URL + ('api/benificiaryLandOwner/generateVoucher')
  protected voucherGenerate = environment.SERVER_API_URL + ('api/benificiaryLandOwner/generatePdfQ')
  protected emudraRequestUrl = environment.SERVER_API_URL+('api/benificiaryLandOwner/emuthraRequest'); 

  protected recommendVoucher = environment.SERVER_API_URL + ('api/benificiaryLandOwner/recommendForPayment')
  protected getProceedings = environment.SERVER_API_URL + ('api/benificiaryProceedings/getAll')
  protected addProceeding = environment.SERVER_API_URL + ('api/benificiaryProceedings')
  protected page1Submits = environment.SERVER_API_URL + ('api/establishmentCharge')
  protected getProfileactivateUrl = environment.SERVER_API_URL+('api/ApproveProfileStatus');
  protected paymentStatus = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getPaymentStatus')

  constructor(protected http: HttpClient,private encryptService:EncryptService) { }

  getAmount(percentage: any, amount: any) {
    return this.http.get(`${this.calculatePercentageUrl}?amount=${amount}&state=${percentage}`, { observe: 'response' })
  }

  profileactivate(id: number): Observable<HttpResponse<{}>> {
    return this.http.get(`${this.getProfileactivateUrl}?id=${id}`, { observe: 'response',responseType:'text' });
  }
  getExtend(district,projectId: any) {
    return this.http.get(`${this.stage1Extend}?district=${district}&project=${projectId}`, { observe: 'response' })
  }
  getRemain(district,projectId: any) {
    return this.http.get(`${this.stage1Remind}?district=${district}&project=${projectId}`, { observe: 'response' })
  }
  getEmudrarequest(fileName:string){
    
    return this.http.get(`${this.emudraRequestUrl}?fileName=${fileName}`,{observe: 'response'  })
  }
  getDistrict(projectId: any) {
    return this.http.get(`${this.projectLocation}?project=${projectId}`, { observe: 'response' })
  }
  getBeneficiary(projectId: any) {
    return this.http.get(`${this.getBeneficiarys}?projectId=${projectId}`, { observe: 'response' })
  }
  talukAndVillage(parentId: any, LevelType) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?project=${parentId}&taluk=${LevelType}`, { observe: 'response' })
  }


  Proceeding(projectDto:any){
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
    .post<Project>(`${this.addProceeding}`, val, { observe: 'response' })
  }
  getProceeding(req){
    const options = createRequestOption(req);

    return this.http
    .get<Project>(`${this.getProceedings}`, { observe: 'response',params:req })
  }
  talukAndVillageforStage2(parentId: any, district,taluk,village) {
    return this.http
      .get<any[]>(`${this.getTalukAndVilliages}?project=${parentId}&district=${district}&taluk=${taluk}&village=${village}`, { observe: 'response' })
  }
  talukAndVillageforStage4(parentId: any, district,taluk,village) {
    return this.http
      .get<any[]>(`${this.getBeneficiaryVillage}?projectId=${parentId}&district=${district}&taluk=${taluk}&village=${village}`, { observe: 'response' })
  }
  saveVoucherGenerate(projectDto:any): Observable<any>{
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
    .post(this.voucherGenerate, val, { observe: 'response' , responseType: 'text' })
  }

  page1Submit(projectDto:any,params?:any){
    const options = createRequestOption(params);

    return this.http
    .post<Project>(`${this.page1Submits}`, projectDto, { observe: 'response',params:options })
  }
  recommend(projectDto:any,pId){
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
    .post<Project>(`${this.recommendVoucher}?project=${pId}`, val, { observe: 'response' })
  }

  tamilNilams(data){
    return this.http
    .post(`${this.tamilNilam}`,data, { observe: 'response',responseType: 'text' })
  }
  talukAndVillages(parentId: any, LevelType) {
    return this.http
      .post<any[]>(`${this.TalukVillageUrl}?parentId=${parentId}&levelTypeId=${LevelType}`, { observe: 'response' })
  }
  getProjects(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      // params: options,
      observe: 'response',
    });
  }

  getProjectDetails(): Observable<any> {
    return this.http.get<any[]>(this.getProjectforProceeding, {
      observe: 'response',
    });
  }

  getProjectFilter(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.getProject}`, {
      params: options,
      observe: 'response',
    });
  }

  stage2Filter(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.projectDetails}`, {
      params: options,
      observe: 'response',
    });
  }
  stage2Remind(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.projectSurveyRemind}`, {
      params: options,
      observe: 'response',
    });
  }
  stage4Filter(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.beneficiaryDetails}`, {
      params: options,
      observe: 'response',
    });
  }

  stage5Filter(req: any) {
    //let val={requestPayload:this.encryptService.encryptData(JSON.stringify(req))}
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.paymentStatus}`, {
      params: options,
      observe: 'response',
    });
  }


  getAgencyReport(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.getProject}`, {
      params: options,
      observe: 'response',
    });
  }
  

  getLevelMaster(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getLevelMasters}`, { observe: 'response' });
  }

  getProjectList(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getTalukAndVilliages}`, { observe: 'response' });
  }
  getProjectListforStage4(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getBeneficiaryVillage}`, { observe: 'response' });
  }
  
  estimationCharge(req): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);

    return this.http.get<any>(`${this.estimatioCharge}`, { observe: 'response' ,params:options});
  }
  saveProjectDetails(projectDto: any,image) {
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
      .post<Project>(`${this.projectDetails}?publicationImage=${image}`, val, { observe: 'response' })
  }
  saveProjectSurvey(projectDto:any){
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
    .post<Project>(this.projectSurvey, val, { observe: 'response' })
  }

  saveProject(projectDto: any) {
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
      .post<Project>(this.saveProjectDtoUrl, val, { observe: 'response' })
  }

  saveProjectAccount(projectDto: any) {
    return this.http
      .post<Project>(this.saveProjectDtoUrlLand, projectDto, { observe: 'response' })
  }
  saveProjectLand(projectDto: any) {
    return this.http
      .post<Project>(this.saveProjectDtoUrl, projectDto, { observe: 'response' })
  }
  editProject(projectDto: any,params) {
    return this.http
      .post<Project>(`${this.updateProjectDtoUrl}/${params.id}`, projectDto, { observe: 'response' })
  }
  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      params: options,
      observe: 'response',
    });
  }

  getAgency() {
    return this.http.get(`${this.getAgencys}`, { observe: 'response' })
  }

  getYear() {
    return this.http.get(`${this.getYears}`, { observe: 'response' })
  }

  getTalukAndVillages() {
    return this.http.get(`${this.getTalukAndVilliages}`, { observe: 'response' })
  }


  getOnceProject(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getOneUnit}/${id}`, { observe: 'response' });
  }
  getOneVirtual(id): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getOneVirtuals}/${id}`, { observe: 'response' });
  }

  deleteAgency(id): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.saveProjectDtoUrl}/${id}`, { observe: 'response' });
  }

  getCategoryLao() {
    return this.http.get(`${this.laoCategoryForUser}`, { observe: 'response' })

  }

  getLandUnit() {
    return this.http.get(`${this.getUnits}`, { observe: 'response' })

  }
  
  LAOLists(LevelType) {
    return this.http
      .get(`${this.adminLAOs}/${LevelType}`, { observe: 'response' })
  }

}