import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidateService } from './validate.service';
import { SigninService } from '../signin/signin.service';
import * as forge from 'node-forge';
import { environment } from 'src/environments/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
 keyCloakUuid:string;
 public pwdMismatch:boolean=false;
 showOtp:boolean = false;
 inValidOtp:boolean = false;
 otpverified:boolean=false;
 hide:boolean=true;
 phide:boolean=true;
 inValidUser:boolean=false;
 passwordError:string;
otpTimer:any;
timer:any;
resendOtp:boolean=false;
otpButtonName:string = "Send OTP";
disableButton:boolean=false;

 otpForm= this.fb.group({
  username: [null, [Validators.required]],
  otp: [null, [Validators.required]]
});
 forgotPwdForm = this.fb.group({
    password: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]],
  });
  userDto: any;
  otpError: null;
  otpId: any;
  phoneNo: string;
  enableResendButton: boolean;
  disableOtp: boolean;
  constructor( private fb: FormBuilder,private route: ActivatedRoute,private router:Router,
    private validateService: ValidateService,private notificationService:NotificationService,
    private loginService: SigninService,private localStorageService:LocalStorageService,
    private sessionStorageService:SessionStorageService, private encryptService:EncryptService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.otpForm.controls['username'].setValue(atob(params['username']));
    });
  }
  get username(){
    return this.otpForm.get('username')!.value;
  }
  get otp(){
    return this.otpForm.get('otp')!.value;
  }
  get password(){
    return this.forgotPwdForm.get('password')!.value;
  }
  get confirmPassword(){
    return this.forgotPwdForm.get('confirmPassword')!.value;
  }
  navToLogin() {
    this.router.navigate(['/login']);
  }
  startTimer() {
    this.otpTimer = 30; 

    this.timer = setInterval(() => {
      this.otpTimer--; 

      if (this.otpTimer === 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if(this.resendOtp){
      this.otpButtonName="Resend OTP";
      this.disableButton=false;
    }
    else{
      this.localStorageService.clear();
      this.sessionStorageService.clear();
      this.showOtp = false;
      // this.loginForm.reset();
      // this.otpError=null;
      this.otpForm.controls['otp'].setValue('');
    }
    clearInterval(this.timer);
  }

 
  sendotp(resend?){
    this.otpError= null;
    this.validateService.getUserDetails(this.encryptService.encryptData(this.username)).subscribe(
     
      (res)=>{
        this.otpId = res.body.levelOne;
        if(res.body.activeFlag === "No"){
          this.notificationService.alertError('User is disabled by department','')
          return
        }
        this.phoneNo = '****'+res.body.mobile.substring(6, 10);
        if (resend) {
          this.enableResendButton = false;
          this.startTimer();
          this.notificationService.alertSuccess('Otp Resend Sucessfully','')
        } 
       this.showOtp = true;
       this.userDto = res.body;
       this.startTimer();
    },
    () => {
      this.notificationService.alertError('User Invalid','')
    }
    );
  }

  getUserOtp() {
    this.validateService.getUserOtp(this.username).subscribe(
      (res) => {       
          this.startTimer();
          this.resendOtp=true;
          this.disableButton=true;
      },
      (onError) => {}
    );
  }
  verifyotp(){
    if (!this.otpForm.get('otp')!.value) {
      this.notificationService.alertError('Invalid Otp', '');
      return;
    }
    this.validateService.validateOtp(this.userDto.id,this.encryptService.encryptData(this.otpForm.get('otp')!.value),this.otpId).subscribe(
      (res) => {
        
          if (res === "Correct") {
            this.otpverified=true;
            this.showOtp=false;
          } else {
            if(res === 'Maximum Attempt Reached'){
              this.disableOtp = true;
            }
            this.otpError =res;
          }
        
      },
      (onError) => {
        if (onError.status === 400) {
          
        } 
      }
    );
    
  }

  passwordPolicyValidator(password: string): boolean {
    const minLength = 8;
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    return password.length >= minLength && hasSpecialChar;
  }
 
 
  submit(){
    this.passwordError=null;
    this.pwdMismatch=null;
    if (this.forgotPwdForm.invalid) {
    return;
    }
    if (!this.passwordPolicyValidator(this.password)) {
      this.passwordError = "Password must be a minimum of 8 characters including Number, Upper, Lower and one special character";
      return;
    }
        if(this.password==this.confirmPassword){
          this.validateService.setPassword(this.userDto.id,this.encryptService.encryptData(this.password)).subscribe(
          ()=>{
            this.notificationService.alertSuccess('Password successfully changed', '');
            this.router.navigateByUrl('login');
          },
          (onError)=>{
            if (onError.status == 400)
            {
              this.passwordError = onError.error.errorKey;
            }
            else{
              this.passwordError='Password reset failed!';
            }
      }
        );
        }
        else{
          this.pwdMismatch=true;
        }       

  
    
  
  }
}
