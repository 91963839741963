import { HttpParams } from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort' && key !== 'custom') {
        if(req[key] !=''){
        options = options.set(key, req[key]);
        }
      }
    });
    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
    if (req.custom) {
      req.custom.forEach((val: any) => {
        options = options.append(val.label, val.field);
      });
    }
  }

  return options;
};
