<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>DD Details</h2>
</div>
<div class="container-fluid bg">
    <div class="container row mx-auto align-items-end bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department" bindLabel="projectName" appearance="outline"
                [(ngModel)]="stage2SearchFilter.project" [items]="projectList" class="form-control"
                style="background: lightgray;" (change)="getExtent('projectName')" (change)="selectProject($event)"
                appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">District</label>
            <ng-select id="type" [items]="districtList" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                style="background: lightgray;" [clearable]="true" class="form-control" (change)="SelectDistrict($event)"
                (change)="getExtent('district')" [(ngModel)]="stage2SearchFilter.district"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk</label>
            <ng-select id="type" [items]="taluk" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                style="background: lightgray;" [clearable]="true" class="form-control" (change)="OnSelectTaluk($event)"
                (change)="getExtent('taluk')" [(ngModel)]="stage2SearchFilter.taluk"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="type" [items]="villages" bindLabel="name" (change)="OnSelectVillage($event)"
                style="background: lightgray;" (change)="getExtent('village')" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" [(ngModel)]="stage2SearchFilter.village"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Survey Number</label>
            <ng-select id="type" [items]="surveyNo" bindLabel="name" (change)="OnSelectSurvey($event)"
                style="background: lightgray;" (change)="getExtent('survey')" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" [(ngModel)]="stage2SearchFilter.surveyNo"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>

        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Sub-division number</label>
            <ng-select id="type" [items]="subDiv" bindLabel="name" (change)="OnSelectSubdivision($event)"
                style="background: lightgray;" (change)="getExtent('subDiv')" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" [(ngModel)]="stage2SearchFilter.subDiv"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3" *ngIf="partSubDiv">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Part Sub-division number</label>
            <ng-select id="type" [items]="partSub" bindLabel="name" (change)="OnSelectPartSubdivision($event)"
                style="background: lightgray;" (change)="getExtent('partsubDiv')" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control"
                [(ngModel)]="stage2SearchFilter.partSubdiv"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Extent(In hectares)</label>
            <input type="number" class="form-control" name="name" autocomplete="off"
                [(ngModel)]="stage2SearchFilter.totalExtent" readonly style="background: lightgray;"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Total Compensation
                Amount as per
                award (In Rupees)</label>
            <input type="number" class="form-control" name="name" autocomplete="off"
                [(ngModel)]="stage2SearchFilter.compensationAmount" maxlength="60" readonly
                style="background: lightgray;" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}"
                id="name" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass"> Total number of Land
                Owners payment is
                proposed in this Survey
                Number</label>
            <input type="number" class="form-control" name="name" autocomplete="off"
                [(ngModel)]="stage2SearchFilter.noOfOwner" maxlength="60"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}"
                id="name" />
        </div>
        <div class="col-sm-3 col-md-3 mt-4">
            <p-button label="Submit" class="submit" (click)="getParentId()"></p-button>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="beneficiaryDialog" [style]="{ width: '2000px' }" header="Beneficiary Details"
        [modal]="true" styleClass="ib-dialog">
        <div class="row">
            <div class="col-md-12">
                <div class="mt-3">
                    <span>Land Acquired(In hectares): {{remainLand}}</span> &nbsp;
                    <span>Amount Spent for Acquisition(In Rupees): {{remainAmount}}</span> &nbsp;
                    <span>No of landowner: {{totalLandOwner}}</span>&nbsp;
                    <span>Total Extent: {{stage2SearchFilter.totalExtent}}</span>&nbsp;
                    <span>Total amount: {{ stage2SearchFilter.compensationAmount}}</span> &nbsp;
                    <span>Remain Extent: {{ reminExtend}}</span>
                </div>
                <form [formGroup]="projectForm" class="mt-3">
                    <div formArrayName="LandDetailsForm">
                        <table style="width:100%">
                            <tr>
                                <th style="background-color: #017DC3;">S.No</th>
                                <th style="background-color: #017DC3;">Award proceeding no
                                </th>
                                <th class="text-center" style="background-color: #017DC3;">Award proceeding date</th>
                                <th style="background-color: #017DC3;">Beneficiary details and remark for
                                    court deposit
                                </th>
                                <th style="background-color: #017DC3;">Entend of land
                                </th>
                                <th style="background-color: #017DC3;">DD in favour of
                                </th>
                                <th style="background-color: #017DC3; color: white;">
                                    IFSC Code</th>

                                <th style="background-color: #017DC3; color: white;" class="text-center">
                                    Bank Name
                                </th>
                                <th style="background-color: #017DC3; color: white;">
                                    Branch Name
                                </th>
                                <th style="background-color: #017DC3; color: white;">
                                    DD Remitter Name
                                </th>
                                <th style="background-color: #017DC3; color: white;">
                                    Payable at
                                </th>
                                <th style="width:100px;background-color: #017DC3; color: white;">
                                    Amount
                                </th>
                            </tr>
                            <tr *ngFor="let landDetails of detailsOfLand.controls; let i=index" [formGroupName]="i">
                                <td>
                                    <p>{{i+1}}</p>
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="proceedingNo"
                                        onblur="this.value =this.value.trim();" uppercase
                                        style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <p-calendar dateFormat="dd-mm-yy" formControlName="proceedingDate"
                                        [maxDate]="maxDate" [minDate]="minDate" styleClass="datepicker"></p-calendar>
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="remark" onblur="this.value =this.value.trim();"
                                        uppercase style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="ExtentOwned"
                                        (keypress)="keyPressNumberForMobile($event)"
                                        onblur="this.value =this.value.trim();" uppercase
                                        style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="favour" onblur="this.value =this.value.trim();"
                                        uppercase style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="ifscCode" (keyup)="ifscCode($event,i)"
                                        style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="bankName" readonly
                                        oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                        onblur="this.value =this.value.trim();" uppercase
                                        style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="branchName" readonly
                                        oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                        onblur="this.value =this.value.trim();" uppercase
                                        style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="remmiter" style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="payable" style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" autocomplete="off"
                                        maxlength="60" formControlName="amount"
                                        (keypress)="keyPressNumberForMobile($event)" style="text-transform:uppercase"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>
                <div class="flex align-items-center mt-2">
                    <p-button label="Submit" class="submit" [disabled]="projectForm.invalid"
                        (click)="submit('popup')"></p-button>
                </div>
            </div>
        </div>
    </p-dialog>
    <div class="row mt-3" *ngIf="isShowTable">
        <div class="col-md-12">
            <div>
                <table style="width:100%">
                    <tr>
                        <th style="background-color: #017DC3;">Land Owner name</th>
                        <th style="width:100px;background-color: #017DC3;">Survey Number</th>
                        <th style="width:100px;background-color: #017DC3;">Sub division Number</th>
                        <th style="width:100px;background-color: #017DC3;">Adhaar Number</th>
                        <th style="width:100px;background-color: #017DC3;">Mobile Number</th>
                        <th class="text-center" style="background-color: #017DC3;">Account Number</th>
                        <th style="background-color: #017DC3;">
                            IFSC Code</th>
                        <th style="background-color: #017DC3;" class="text-center">
                            Bank Name
                        </th>
                        <th style="background-color: #017DC3;">
                            Branch Name
                        </th>
                        <th style="background-color: #017DC3;">
                            Extent owned by the
                            Land Owner as per
                            Award(In hectares)
                        </th>
                        <th style="background-color: #017DC3;">
                            Compensation
                            Amount to be
                            disbursed (in Rupees)
                        </th>
                    </tr>
                    <tr *ngFor="let data of confirmValues">
                        <td>{{data.benificiaryLandOwnerName}}</td>
                        <td>{{data.surveyNumber}}</td>
                        <td>{{data.subDivisionNumber}}</td>
                        <td>{{data.aadharNumber}}</td>
                        <td>{{data.mobileNumber}}</td>
                        <td>{{data.accountNumber}}</td>
                        <td style="text-transform:uppercase">{{data.ifscCode}}</td>
                        <td>{{data.nameOfBank}}</td>
                        <td>{{data.branchName}}</td>
                        <td>{{data.extentOwned}}</td>
                        <td>{{data.compensationAmount| currency:'INR'}}</td>
                    </tr>
                </table>
                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>
            </div>
        </div>
        <div class="flex align-items-center">
            <p-button label="Save" class="submit" (click)="submit('confirm')"></p-button>
        </div>
    </div>
</div>
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>