import { gender } from "src/app/shared/common.model";
import { maritalStatus } from "src/app/shared/common.model";
import { educationalQualification } from "src/app/shared/common.model";
import { categoryReservation } from "src/app/shared/common.model";
import { community } from "src/app/shared/common.model";
import * as dayjs from 'dayjs';
export class Profile {
    id?:number;
    name?:string;
    otp?:number
    dateOfBirth?:Date;
    educationalQualification?:educationalQualification;
    maritalStatus?:maritalStatus;
    categoryReservation?:categoryReservation;
    community?:community;
    mobileNo?:Number;
    gender?:gender;
    emailId?:string;
    dateOfAssumingOffice?:Date;
    termValidUpTo?:Date;
    state?:string;
    district?:string;
    city?:string;
    doorNo?:string;
    street?:string;
    landmark?:string;
    area?:string;
    pinCode?:string;
    aadhaarNo?:number;
    panNo?:string;
    fileNameOne?:string;
    dateOfBirthUi?: Date;
    adminUserDtoCreatedby?:number
    profileStatus?:string; 
    incharge:string;

}

export class AddProfile {
    id?:number;
    name?:string;
    otp?:any
    dateOfBirth?:Date;
    educationalQualificationDto?:educationalQualification;
    maritalStatusDto?:maritalStatus;
    categoryReservationDto?:categoryReservation;
    communityDto?:community;
    mobileNo?:any;
    genderDto?:gender;
    emailId?:string;
    dateOfAssumingOffice?:Date;
    termValidUpTo?:Date;
    state?:string;
    district?:string;
    city?:string;
    doorNo?:string;
    street?:string;
    landmark?:string;
    area?:string;
    pinCode?:string;
    aadhaarNo?:any;
    panNo?:string;
    fileNameOne?:string;
    dateOfBirthUi?: Date;
    adminUserDtoCreatedby?:number;
    profileStatus?:string;  
    incharge:string;

}

