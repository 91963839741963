import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SigninService } from '../../auth/signin/signin.service';
import { PaymentService } from '../../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];

  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  isFile1: boolean;

  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;
  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[] = [];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowBankDetails: boolean = false;
  projectIds: any;
  categoryLAO: any;
  hideIndex: any;
  vFileExt1: any;
  vFileUrl1: string;
  viewDoc1: boolean;
  uploadedFiles: any = [];
  fileUpload2: any;
  isFile2: boolean;
  vFileExt2: any;
  vFileUrl2: string;
  viewDoc2: boolean;
  landImage: any = [];
  paperPublication: any = [];
  files: any = [];

  landImages: any = [];
  toDate: Date;
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private projectService: ProjectService
  ) {

    this.translateService.onLangChange.subscribe(() => {
    });

    this.getUserrole();
  }

  ngOnInit() {
    this.forms();
    this.label = 'Add';
    this.viewOnly = false;
    this.project = new Project();
    this.projectDialog = true;
    this.readonly = false;
    this.toDate = new Date()
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });

    this.projectService.getCategoryLao().subscribe((res: any) => {
      this.categoryLAO = res.body.data;
    });

    this.projectService.getLandUnit().subscribe((res: any) => {
      this.landUnits = res.body.data;
      console.log(this.landUnits, 'this.landUnits');
    });

    this.projectService.getLevelMaster().subscribe(
      (res: HttpResponse<any[]>) => {
        let stateList = res.body;
        this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
        this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
      },
      () => {
        this.onError();
      }
    );
    this.addSkills();
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (
          res.body.role.id != 1 &&
          res.body.role.id != 5 &&
          res.body.role.id != 4 &&
          res.body.role.id != 3
        ) {
          this.roleId = true;
        } else {
          this.roleId = false;
        }
      },
      (onError) => { }
    )
  }
  forms() {
    this.projectForm = this.formBuilder.group({
      requestingDep: ['', Validators.required],
      projectName: ['', Validators.required],
      sanctionDate: ['', Validators.required],
      landUnit: ['', Validators.required],
      landsAcquire: ['', Validators.required],
      fileGO: ['', Validators.required],
      VAcNo: [''],
      ifscCode: [''],
      bankName: [''],
      branch: [''],
      amount: [''],
      LandDetailsForm: this.formBuilder.array([]),
    });
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      districtName: ['', Validators.required],
      landAcquireInDistrict: ['', Validators.required],
      Categorylao: ['', Validators.required],
      lao: ['', Validators.required],
      isFlag: [],
    });
  }

  keyPressNumbersDecimal(event, lable) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      if (lable == 'land') {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 4) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      } else {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 2) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return true;
  }


  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  addSkill() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (totalLand != 0) {
      if (this.projectForm.get('landsAcquire').value >= totalLand) {
        this.remainValue =
          Number(this.projectForm.get('landsAcquire').value) -
          Number(totalLand);
        if (totalLand == this.projectForm.get('landsAcquire').value) {
          this.notificationService.alertInfo(
            'Equal',
            'Acquired land in the district is equal to total amount of land'
          );
        } else {
          this.detailsOfLand.push(this.detailsform());
        }
      } else {
        this.notificationService.alertError(
          'Alert',
          'Acquired land in the district should be less than total amount of land'
        );
      }
    }
  }

  removeSkill(i: number) {

    let deleteData = this.detailsOfLand.at(i).value;
    this.deletedProject.push(deleteData);
    this.deletedProject.forEach(element => {
      if (element.id != '' && element.id != undefined) {
        this.editContent.push(element)
        this.deletedProject = []
      }
    });

    this.detailsOfLand.removeAt(i);

    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.getValues('');
  }



  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  calcAmount() {
    if (!this.enablepublicContributionAmount) {
      this.project.administrativeSanctionAmount = this.project.totalCost;
      return;
    }
    this.projectService
      .getAmount(this.selectedpublicContribution.id, this.project.totalCost)
      .subscribe(
        (res: any) => {
          this.project.publicContributionAmount = res.body;
          this.project.administrativeSanctionAmount = Number(
            (
              this.project.totalCost - this.project.publicContributionAmount
            ).toFixed(2)
          );
        },
        () => { }
      );
  }

  openNew(val, content?: any) {
    this.deletedProject = []
    this.editContent = []
    this.deletePayload = []
    this.projectId = content.id;
    if (val == 'add') {
      this.projectService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
    }
    else if (val == 'edit') {
      this.viewOnly = false;
      this.label = 'Edit';
      this.forms();
      this.districtIds = [];
      this.project = new Project();
      this.projectDialog = true;
      this.readonly = false;
      this.projectService.getAgency().subscribe((res: any) => {
        this.AgencyLists = res.body.data;
      });

      this.spinner.show();
      this.projectService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.spinner.hide();

          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.projectService.getOnceProject(content.id).subscribe((res: any) => {
        this.singleProject = res.body.data;
        this.singleProject.projectLocation.forEach((element) => {
          this.districtIds.push(element.id);
        });
        this.projectForm.patchValue({
          requestingDep: this.singleProject.agenceyId.agencyName,
          projectName: this.singleProject.projectName,
          sanctionDate: this.datepipe.transform(
            this.singleProject.adminSanctionDate,
            'dd/MM/YYYY'
          ),
          // costOfLand: this.singleProject.tentativeLandCost,
          landsAcquire: this.singleProject.totalExtentOfLands,
        });
        this.displayItemsEdit();
      });
    } else {
      this.viewOnly = true;
      this.forms();
      this.project = new Project();
      this.projectDialog = true;
      this.readonly = false;
      this.projectService.getAgency().subscribe((res: any) => {
        this.AgencyLists = res.body.data;
      });

      this.spinner.show();

      this.projectService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.spinner.hide();

          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.projectService.getOnceProject(content.id).subscribe((res: any) => {
        this.singleProject = res.body.data;
        //console.log(this.singleProject);
        let date = this.datepipe.transform(
          this.singleProject.adminSanctionDate,
          'dd/MM/YYYY'
        );
        this.projectForm.patchValue({
          requestingDep: this.singleProject.agenceyId.agencyName,
          projectName: this.singleProject.projectName,
          sanctionDate: date,
          // costOfLand: this.singleProject.tentativeLandCost,
          landsAcquire: this.singleProject.totalExtentOfLands,
        });
        this.displayItems();
      });
    }
  }
  createItem(item: any) {
    return this.formBuilder.group({
      districtName: [item.districtLGDCode.id, [Validators.required]],
      landAcquireInDistrict: [item.extentLand, [Validators.required]],
      lao: [item.adminUser.id, [Validators.required]],
    });
  }

  displayItems() {
    let transformedItems = this.singleProject.projectLocation.map((item: any) =>
      this.createItem(item)
    );
    this.projectForm.setControl(
      'LandDetailsForm',
      this.formBuilder.array(transformedItems)
    );
    this.projectForm.disable();
  }

  displayItemsEdit() {
    let transformedItems = this.singleProject.projectLocation.map((item: any) =>
      this.createItemEdit(item)
    );
    this.projectForm.setControl(
      'LandDetailsForm',
      this.formBuilder.array(transformedItems)
    );
  }
  createItemEdit(item: any) {
    //console.log(item);
    this.districtId = [];
    this.districtId.push(item.districtLGDCode.id);
    return this.formBuilder.group({
      id: [item.id],
      districtName: [item.districtLGDCode.id, [Validators.required]],
      landAcquireInDistrict: [item.extentLand, [Validators.required]],
      lao: [item.adminUser.id, [Validators.required]],
      isFlag: [],
    });
  }

  getDistricts(e, i) {
    if (e.id == 3) {
      this.projectService.LAOLists(e.id).subscribe((res: any) => {
        this.LAOAdmin = res.body.data;
      });
      this.detailsOfLand.controls[i].get('lao').setValidators(Validators.required);
      this.detailsOfLand.controls[i].get('lao').enable();
    } else {
      this.detailsOfLand.controls[i].get('lao').clearValidators();
      this.detailsOfLand.controls[i].get('lao').disable();
      // this.hideValue(i)  
    }

    //console.log(this.detailsOfLand.controls[i].get('lao'));

    this.detailsOfLand.controls[i].get('lao').setValue('');
  }

  hideValue(i) {
  }

  removeDocument() {
    this.paymentService.deleteimage(this.project.fileUpload).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
        this.projectForm.get('fileGO').setValue('')
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.spinner.show()
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.fileUpload = res.body.fileDownloadUri;
        this.spinner.hide()
        this.projectForm.get('fileGO').setValue(res.body.fileName)
        this.project.fileUpload = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.spinner.hide()
      }
    );

  }
  removeEr(e) {
    console.log(e.file);
  }


  getValues(eve) {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
      this.showError = false;
    } else if (Number(this.projectForm.get('landsAcquire').value) < totalLand) {
      this.showError = true;
      this.disableButton = false;
    } else {
      this.disableButton = false;
      this.showError = false;
    }
  }


  viewDocument() {
    this.paymentService.viewimage(this.project.fileUpload).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }

  viewDocument2() {
    this.paymentService.viewimage(this.project.fileUpload2).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt2 = filename.split('.').pop();
      this.vFileUrl2 =
        'data:image/' + this.vFileExt2 + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc2 = true;
    });
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return this.index + 1 + this.itemsPerPage;
    }
  }

  onPageChange(event: PageEvent) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }

  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }
  hideDialog() {
    this.ngSelectInvalid = false;
    this.project = new Project();
    this.projectDialog = false;
    this.enablepublicContributionAmount = null;
    this.selectedpublicContribution = null;
    this.isFile = false;
    this.viewOnly = false;
    this.edit = false;
  }
  saveData(label) {
    let form = this.projectForm.value;
    let landFrom = [];
    let district = [];
    this.files = []
    let data
    let params = {
      id: this.projectId,
    };
    if (this.projectForm.valid) {
      form.LandDetailsForm.forEach((element) => {
        district.push(element.districtName);
        if (element.Categorylao == 3) {
          data = {
            districtLGDCode: element.districtName,
            extentLand: element.landAcquireInDistrict,
            laoCategoryId: 3,
            designation: element.lao
          };
        } else {
          data = {
            districtLGDCode: element.districtName,
            extentLand: element.landAcquireInDistrict,
            laoCategoryId: element.Categorylao,
          };
        }
        landFrom.push(data);
      });
      this.files.push(this.project.fileUpload)
      let payload = {
        agencyId: form.requestingDep.id,
        projectName: form.projectName,
        landUnitId: form.landUnit,
        adminSanctionDate: new Date(form.sanctionDate),
        // tentativeLandCost: form.costOfLand,
        totalExtentOfLands: form.landsAcquire,
        files: this.files,
        projectLocation: landFrom,
      };
      this.projectService.saveProject(payload).subscribe(
        (res: any) => {
          this.isShowBankDetails = true
          this.projectIds = res.body.data;
          this.projectForm.patchValue({
            VAcNo: this.projectIds.projectVirtualAccount.accountNumber,
            ifscCode: this.projectIds.projectVirtualAccount.ifscCode,
            bankName: this.projectIds.projectVirtualAccount.bankName,
            branch: this.projectIds.projectVirtualAccount.branchName,
            amount: this.projectIds.projectVirtualAccount.amount,
          })
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.router.navigateByUrl('/project')
          this.files = []
        },
        (err) => {
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    } else {
    }
  }

  saveLand(label) {
    let form = this.projectForm.value;

    let params = {
      id: this.projectId,
    };
    if (this.projectForm.valid) {
      if (label == 'Add') {
        let payload = {
          projectId: this.projectIds,
          accountNumber: form.VAcNo,
          ifscCode: form.ifscCode,
          bankName: form.bankName,
          branchName: form.branch,
          amount: form.amount,
        }
        this.spinner.show()
        this.projectService.saveProjectAccount(payload).subscribe(
          (res) => {
            this.isShowBankDetails = true
            this.spinner.hide()
            this.loadPage(this.page);
            this.router.navigateByUrl('/project')
            this.notificationService.alertSuccess('Saved Successfully', '');
          },
          (err) => {
            this.notificationService.alertError('Error!', err.error.error);
            this.spinner.hide();
          }
        )
      }
    } else {
    }
  }

  deleteAgency(data) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the project?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinner.show();
        this.projectService.deleteAgency(data.id).subscribe(
          (res: HttpResponse<any[]>) => {
            this.spinner.hide();
            this.loadPage();
          },
          () => {
            this.onError();
          }
        );
      },
    });
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.projectService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Project[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    // this.searchResult.items = data ?? [];
    this.projectList = data.data;
    this.totalCount = data.totalCount;
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onError(): void { }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      } else if ($event.type === 'select') {
        this.viewOnly = true;
        this.edit = true;
      }
      this.paymentService.getProjectByWorkid($event.value.workId).subscribe(
        (res: Project) => {
          this.project = res;
          if (this.project.technicalSanctionDate) {
            this.project.technicalSanctionDate = new Date(
              this.project.technicalSanctionDate
            );
          }
          if (this.project.agreementDate) {
            this.project.agreementDate = new Date(this.project.agreementDate);
          }
          this.projectDialog = true;
          if (this.project.administrativeSanctionDate) {
            this.project.administrativeSanctionDate = new Date(
              this.project.administrativeSanctionDate
            );
          }
          if (this.project.fileUpload) {
            this.isFile = true;
          }
          this.publicContributionAmount.forEach((element) => {
            if (element.value === this.project.contributionPercentage) {
              this.selectedpublicContribution = element;
            }
          });

          if (this.project.publicContribution == 'Yes') {
            this.enablepublicContributionAmount = true;
          }
        },
        () => {
          this.onError();
        }
      );
    }
    // else {
    //   this.paymentService.delete($event.value.workId).subscribe(
    //     (res: Project) => {
    //       this.loadPage(this.page);
    //     },
    //     () => {
    //       this.onError();
    //     }
    //   );
    // }
  }
}