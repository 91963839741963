import { Component, OnInit, ViewChild } from '@angular/core';
import { AgencyService } from './agency.service';
import { Agency, AgencyDto } from './agency';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-add-agency',
  templateUrl: './add-agency.component.html',
  styleUrls: ['./add-agency.component.scss']
})

export class AddAgencyComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;
  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean = false;
  hamHeader: string;
  subHeader: string;
  agencyList: any;
  stateList: any;
  state: any[];
  district: any;
  totalCount: any;
  pageCount: number = 0;
  index: number;
  pageLength: number;
  agencyForm: FormGroup;
  constructor(
    private agencyService: AgencyService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private levelTypeService: LevelTypeService,
    public fontService: FontService,
    public validationService: ValidationService,
    public fb: FormBuilder,

    protected notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.agency = new AgencyDto();
    this.submitted = false;
    this.forms()
    this.agencyService.getLevelMaster().subscribe(
      (res: HttpResponse<any[]>) => {
        let stateList = res.body;
        this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
        this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
      },
      () => {
        this.onError();
      }
    );
    this.agencyDialog = true;
    this.viewOnly = false;

    if (this.router.url == '/agency') {
      this.hamHeader = 'Requisitioning Departments';
      this.subHeader = 'Home  /   Requisitioning Departments';
    }
  }
  ngAfterViewInit() {
  }

  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },
  ];


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.spinner.hide();
        this.notificationService.alertError('Error!', err.error.error);
      }
    );
  }

  getDate(d){
    console.log(d,'e');
    
  } 
  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data.data ?? [];
    this.agencyList = data.data;
    this.totalCount = data.totalCount;
  }

  protected onError(): void { }



  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(
          (item) => item.name === 'State' || item.name === 'District'
        );
      },
      () => { }
    );
  }


  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    // this.viewOnly = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.agency.mobile
    );
    !this.agency.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.agency.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.agency.email);
    !this.agency.email ? (this.emailError = false) : true;
    return !this.emailError && !!this.agency.email;
  }

  validateGst() {
    this.showGstError = !this.validationService.isGstValid(this.agency.gstNo);
    !this.agency.gstNo ? (this.showGstError = false) : true;
    return !this.showGstError && !!this.agency.gstNo;
  }
  validateIfsc() {
    if (this.agency.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.agency.ifscCode
      );
      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.agency.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.agency.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  validateBemail() {
    if (this.agency.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.agency.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }
  forms() {
    this.agencyForm = this.fb.group({
      name: ['', Validators.required],
      govermentType: ['', Validators.required],
      address1: [''],
      address2: [''],
      street: [''],
      city: ['', Validators.required],
      districtName: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
      nameAuth: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', Validators.required],
      designation: ['',Validators.required],
      OfficeEmail: [''],
      OfficeMobile: [''],
    });
  }

  saveAgency() {
     if (this.agencyForm.valid) {
      let form = this.agencyForm.value
      let payload = {
        agencyName: form.name,
        governmentType: form.govermentType.name,
        address1: form.address1,
        address2: form.address2,
        street: form.street,
        city: form.city,
        districtLgdCode: form.districtName,
        state: form.state.id,
        pinCode: form.pincode,
        authorizedPersonName: form.nameAuth,
        authorizedPersonDesignation: form.designation,
        authorizedPersonMobileNumber: form.mobile,
        authorizedPersonEmailId: form.email,
        officePhoneNumber: form.OfficeMobile,
        officeEmailId: form.OfficeEmail,
      };
      this.agencyService.save(payload).subscribe(
        (res: HttpResponse<Agency[]>) => {
          this.agencyDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.router.navigateByUrl('/agency')
          this.hideDialog();
        },
        (err) => {
          this.onError();
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
     } else {
     }

     
   
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }
  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  getC(e){
    console.log(e,'e');
  }
  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  keyPressNumberForPincode(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 6) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }



}
