import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SigninService } from '../signin/signin.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private loginService: SigninService,

  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url || (request.url.startsWith('http') && !(environment.SERVER_API_URL && request.url.startsWith(environment.SERVER_API_URL)))) {
      return next.handle(request);
    }
    const token: string | null =
      this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // if (request.url && request.url.includes('/api')) {
    //   return from(this.loginService.getCsrfToken()).pipe(
    //     switchMap(csrfToken => {
    //       if (csrfToken) {
    //         request = request.clone({
    //           setHeaders: {
    //             'X-XSRF-TOKEN': csrfToken,
    //           },
    //         });
    //       }
    //       return next.handle(request);
    //     }),
    //     catchError((error: HttpErrorResponse) => {
    //       if (error.status === 401) {
    //         this.localStorageService.clear();
    //         this.sessionStorageService.clear();
    //         this.router.navigate(['/login']);
    //       } else if (error.status === 405) {
    //         this.router.navigate(['/error']);
    //       }
    //       // You can handle other error statuses as needed
    //       // Pass the error to the caller of the HTTP request
    //       return throwError(error);
    //     })
    //   );

  //   if (request.url && (request.url.includes('/api'))) {
  //        this.loginService.getCsrfToken().then(response => {
  //         let csrfToken = response;
  
  //         if (csrfToken) {
  //           request = request.clone({
  //             setHeaders: {
  //               'X-XSRF-TOKEN': csrfToken
  //             }
  //           });
  //         }
  //       });
  //     }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.localStorageService.clear();
          this.sessionStorageService.clear();
          this.router.navigate(['/login']);
        }
        else if(error.status === 405) {
          this.router.navigate(['/error']);
        }
        // You can handle other error statuses as needed
        // Pass the error to the caller of the HTTP request
        return throwError(error);
      })
    );;
  }
    }
// }
//  else {
//   return next.handle(request).pipe(
//     catchError((error: HttpErrorResponse) => {
//       if (error.status === 401) {
//         this.localStorageService.clear();
//         this.sessionStorageService.clear();
//         this.router.navigate(['/login']);
//       } else if (error.status === 405) {
//         this.router.navigate(['/error']);
//       }
//       // You can handle other error statuses as needed
//       // Pass the error to the caller of the HTTP request
//       return throwError(error);
//     })
//   );
// }
// }
// }
