import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<Agency[]>;
import { Agency, AgencyDto } from './stage4';

@Injectable({ providedIn: 'root' })
export class AgencyService {
  constructor(private http: HttpClient) { }
  protected resourceFliterUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail');
  protected getBaseURL = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getList');
  protected resourceVaildFliterUrl = environment.SERVER_API_URL + ('api/getIAgencyValidByFilter');
  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail')
  protected resourceDeleteUrl = environment.SERVER_API_URL + ('api/deleteImplementingAgency')
  protected getAgencyUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner')
  protected getLevelMasters = environment.SERVER_API_URL + ('api/levelMasterList');
  protected filterUrl = environment.SERVER_API_URL + ('api/project/getList')
  protected TalukVillageUrl = environment.SERVER_API_URL + ('api/getLevelMasterByParentId')
  protected landDetails = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getBeneficiaryWithLegalHeir')


  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .get<Agency[]>(this.landDetails, { params: options, observe: 'response' })
  }

  validFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<Agency[]>(this.resourceVaildFliterUrl, filter, { params: options, observe: 'response' })
  }

  save(agency?: any): Observable<EntityArrayResponseType> {
    return this.http
      .post<AgencyDto[]>(this.resourceFliterUrl, agency, { observe: 'response' })
  }
  getLevelMaster(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getLevelMasters}`, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

  getOneAgency(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getAgencyUrl}?id=${id}`, { observe: 'response' });
  }
  saveBeneficiary(filter, id: number): Observable<HttpResponse<any>> {
    return this.http.post<AgencyDto>(`${this.getAgencyUrl}?parentId=${id}`, filter, { observe: 'response' });
  }


  getProjects(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      // params: options,
      observe: 'response',
    });
  }
  beneficiary(id): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
  talukAndVillages(parentId: any, LevelType) {
    return this.http
      .post<AgencyDto[]>(`${this.TalukVillageUrl}?parentId=${parentId}&levelTypeId=${LevelType}`, { observe: 'response' })
  }

  deleteAgency(id): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
}