import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { MessageService } from 'primeng/api';
import { SigninService } from '../../auth/signin/signin.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { stage3Service } from './stage3.service';
import { PaymentService } from '../../payments/payment-voucher/payment-voucher.service';
import { HttpResponse } from '@angular/common/http';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage3',
  templateUrl: './stage3.component.html',
  styleUrls: ['./stage3.component.scss']
})
export class Stage3Component implements OnInit, OnDestroy {
  @ViewChild('dialog') dialog: Dialog;
  project: Project
  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  stage2SearchFilter: any = {
  project: '',
  }
  readonly: boolean;
  // validation fields properties
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  beneficiaryDialog: boolean = false
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;
  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  totalLandPrivate: any;
  throughAct: number;
  taluk: any[];
  villages: any[];
  talukId: any;
  villageID: any;
  parentId: any;
  projectName: any;
  surveyNo: any;
  surveySubNo: any;
  subDiv: any;
  totalExtent: any;
  selectDistrictId: any;
  districtList: any;
  totalExtend: number;
  compensation: number;
  confirmValues: any;
  bankDetails: any;
  remainData: any;
  remainAmount: any;
  remainLand: any;
  totalLandOwner: any;
  fileUploadValue: any;
  indexValue: any;
  StructureValue = [];
  imageArr: any = [];
  reminExtend: any;
  partSubDiv: boolean = false;
  partSub: any;
  partDiv: any;
  success: boolean[] = [];
  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    private _decimalPipe: DecimalPipe,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private PaymentService: PaymentService,
    private projectService: stage3Service,
    private beneficiaryService: stage3Service,
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }


  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },
  ];

  getProjects() {
    this.projectService.getProjects().subscribe(
      (res: any) => {
        //console.log(res, 'res');
        this.projectList = res.body.data;
      },
      () => {
        // this.onError();
      }
    );
  }

  getExtent(name) {
    if (name == 'projectName') {
      this.stage2SearchFilter.district = '',
        this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.surveyNo = '',
        this.stage2SearchFilter.subDiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = '',
        this.stage2SearchFilter.partSubdiv = ''
      this.partSubDiv = false
    } else if (name == 'district') {
      this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.surveyNo = '',
        this.stage2SearchFilter.subDiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = ''
    } else if (name == 'taluk') {
      this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.surveyNo = '',
        this.stage2SearchFilter.subDiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = ''
    } else if (name == 'village') {
      this.stage2SearchFilter.surveyNo = '',
        this.stage2SearchFilter.subDiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = ''
    } else if (name == 'survey') {
      this.stage2SearchFilter.subDiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = '',
        this.stage2SearchFilter.partSubdiv = ''
      this.partSubDiv = false
    }
    else if (name == 'subDiv') {
      this.stage2SearchFilter.partSubdiv = '',
        this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = ''
    }
    else if (name == 'partsubDiv') {
      this.stage2SearchFilter.totalExtent = '',
        this.stage2SearchFilter.compensationAmount = ''
    }
    let filterQuery = {
      project: this.stage2SearchFilter.project.id,
      taluk: this.stage2SearchFilter.taluk.id,
      village: this.stage2SearchFilter.village.id,
    };
  }
  ifscCode(e, i) {
    let ifscCode = e.target.value;
    if (ifscCode.length == 11) {
      this.spinner.show()
      this.beneficiaryService.getIFSCCode(ifscCode).subscribe(res => {
        this.bankDetails = res.body
        this.spinner.hide()
        this.detailsOfLand.controls[i].get('bankName').setValue(this.bankDetails.BANK);
        this.detailsOfLand.controls[i].get('branchName').setValue(this.bankDetails.BRANCH);
      }, (err) => {
        this.spinner.hide()
        this.notificationService.alertError('IFSC code not found', '');
        this.detailsOfLand.controls[i].get('bankName').setValue('');
        this.detailsOfLand.controls[i].get('branchName').setValue('');
      })
    }
  }

  accountValidation(i) {
    this.spinner.show()
    let data = {
      "name": this.detailsOfLand.controls[i].get('landOwnerName').value,
      "bankIfsc": this.detailsOfLand.controls[i].get('ifscCode').value,
      "bankAccountNo": this.detailsOfLand.controls[i].get('aacountNo').value,
      "activeFlag": 'Yes'
    }
    this.beneficiaryService.accountValidations(data).subscribe(
      (res) => {
        this.spinner.hide();
        this.detailsOfLand.controls[i].get('verfyBank').setValue(res.body);
        this.success[i] = true;
      },
    (error)=> {
      this.spinner.hide();
      console.log(error);
    })
  }

  submit(data) {
    if (data == 'popup') {
      if (this.projectForm.valid) {
        this.spinner.show()
        let beneficaryData = this.projectForm.value
        let landFrom = []
        beneficaryData.LandDetailsForm.forEach(element => {
          let data = {
            "beneficiaryLandDetailId": this.parentId,
            "surveyNumber": this.stage2SearchFilter.surveyNo,
            "subDivisionNumber": this.stage2SearchFilter.subDiv,
            "benificiaryLandOwnerName": element.landOwnerName,
            "aadharNumber": element.aadhaarNo,
            "mobileNumber": element.mobileNumber,
            "nameOfAccountHolder": element.landOwnerName,
            "nameOfBank": String(element.bankName).toLowerCase(),
            "accountNumber": element.aacountNo,
            "branchName": String(element.branchName).toUpperCase(),
            "photo": this.fileUploadValue,
            "ifscCode": String(element.ifscCode).toUpperCase(),
            "extentOwned": element.ExtentOwned,
            "isConfirmed": "FALSE"
          }
          landFrom.push(data);
        });
        this.beneficiaryService.saveBeneficiary(landFrom, this.parentId).subscribe(
          (res) => {
            this.notificationService.alertSuccess('Beneficiary details submitted', '');
            this.forms();
            this.spinner.hide()
            this.addSkills()
            this.beneficiaryDialog = false
            this.isShowTable = true
            this.getConfirmValues()
          },
          (err) => {
            this.notificationService.alertError('Error!', err.error.error);
            this.spinner.hide();
          }
        );
      } else {
          console.log(this.projectForm,'this.projectForm');
          
      }
    } else {
      this.spinner.show()
      let landFrom = []
      this.confirmValues.forEach(element => {
        console.log(element, 'element')
        let data = {
          "beneficiaryLandDetailId": element.benificiaryLandDetail.id,
          "beneficiaryLandOwnerId": element.id,
          "surveyNumber": element.surveyNumber,
          "subDivisionNumber": element.subDivisionNumber,
          "benificiaryLandOwnerName": element.benificiaryLandOwnerName,
          "aadharNumber": element.aadharNumber,
          "mobileNumber": element.mobileNumber,
          "nameOfAccountHolder": element.landOwnerName,
          "nameOfBank": String(element.nameOfBank).toUpperCase(),
          "accountNumber": element.accountNumber,
          "branchName": String(element.branchName).toUpperCase(),
          "ifscCode": String(element.ifscCode).toUpperCase(),
          "photo": element.photo,
          "extentOwned": element.extentOwned,
          "isConfirmed": "TRUE"
        }
        landFrom.push(data)
      });
      this.beneficiaryService.saveBeneficiary(landFrom, this.parentId).subscribe(
        (res) => {
          this.notificationService.alertSuccess('Beneficiary details submitted', '');
          this.spinner.hide()
          this.forms();
          this.addSkills()
          this.router.navigateByUrl('/stage3-view')
        },
        (err) => {
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    }
  }

  onSelectDistrict(e) {
    this.projectService.talukAndVillage(e, '', '', '').subscribe((res: any) => {
      this.districtList = res.body.data
    })
  }

  selectProject(e) {
    this.projectName = e.projectName
    this.getConfirmValueByProject(e.id)
    this.onSelectDistrict(e.id)
    this.projectId = e.id
  }

  SelectDistrict(e) {
    this.selectDistrictId = e.id
    this.projectService.talukAndVillage(this.projectId, this.selectDistrictId, '', '').subscribe((res: any) => {
      this.taluk = res.body.data
    })
  }

  OnSelectTaluk(e) {
    this.talukId = e.id
    //console.log(e);

    this.projectService.talukAndVillage(this.projectId, this.selectDistrictId, this.talukId, '').subscribe((res: any) => {
      this.villages = res.body.data
    })

  }


  keyPressNumbersDecimal(event, lable) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      if (lable == 'extend') {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 4) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      } else if (lable == 'amount') {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 2) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return true;
  }

  OnSelectVillage(e) {
    this.villageID = e.id
    this.projectService.talukAndVillage(this.projectId, this.selectDistrictId, this.talukId, this.villageID).subscribe((res: any) => {
      this.surveyNo = res.body.data.surveyNumber;
      //console.log(this.surveyNo, 'this.surveyNo');
      // this.detailsOfLand.controls[i].get('villageName').setValue('');
    })
  }
  OnSelectSurvey(e) {
    this.surveyNo = e
    this.projectService.talukAndVillageSurvey(this.projectId, this.selectDistrictId, this.talukId, this.villageID, this.surveyNo).subscribe((res: any) => {
      this.subDiv = res.body.data.subDivisionNumber;
      //console.log(this.surveyNo, 'this.surveyNo');
      // this.detailsOfLand.controls[i].get('villageName').setValue('');
    })
  }
  OnSelectSubdivision(e) {
    this.surveySubNo = e
    this.projectService.talukAndVillageSubDiv(this.projectId, this.selectDistrictId, this.talukId, this.villageID, this.surveyNo, this.surveySubNo).subscribe((res: any) => {
      this.totalExtent = res.body.data;
      if (this.totalExtent.partSubDivisionNumber) {
        this.partSub = this.totalExtent.partSubDivisionNumber
        this.partSubDiv = true
      } else {
        this.partSubDiv = false
        this.stage2SearchFilter.totalExtent = this.totalExtent.totalExtent
        this.stage2SearchFilter.compensationAmount = this.totalExtent.totalCompensationAmount
      }
    })
    this.projectService.stae3Remain(this.projectId, this.selectDistrictId, this.talukId, this.villageID, this.surveyNo, this.surveySubNo).subscribe((res: any) => {
      this.remainAmount = res.body.data.alreadyTotalCost;
      this.remainLand = res.body.data.alreadyTotalExtent;
      this.totalLandOwner = res.body.data.alreadyTotalNumberOfLandOwners;
      this.reminExtend = res.body.data.balanceExtent;
    })
  }

  OnSelectPartSubdivision(e) {
    this.partDiv = e
    this.projectService.talukAndVillagPartSubDiv(this.projectId, this.selectDistrictId, this.talukId, this.villageID, this.surveyNo, this.surveySubNo, this.partDiv).subscribe((res: any) => {
      this.totalExtent = res.body.data;
      this.stage2SearchFilter.totalExtent = this.totalExtent.totalExtent
      this.stage2SearchFilter.compensationAmount = this.totalExtent.totalCompensationAmount
    })
  }

  getConfirmValues() {
    this.projectService.getConfirmData().subscribe((res: any) => {
      this.confirmValues = res.body.data
      this.totalCount = res.body.totalCount
    })
  }

  getConfirmValueByProject(id) {
    this.spinner.show()
    this.projectService.getConfirmProject(id).subscribe((res: any) => {
      this.isShowTable = true
      this.spinner.hide()
      this.confirmValues = res.body.data
      this.totalCount = res.body.totalCount
    }, (err) => {
      this.spinner.hide()

    })
  }
  onUploadfile1($event, index) {
    this.imageArr = []
    this.spinner.show()
    this.indexValue = index
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.PaymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.imageArr.push(res.body.fileName)
        this.detailsOfLand.controls[index].get('image').setValue(this.imageArr);
        this.spinner.hide()
        this.fileUpload = res.body.fileDownloadUri;
        this.fileUploadValue = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.spinner.hide()
      }
    );
  }

  ngOnInit() {
    this.forms();
    this.getProjects();
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);
  }

  addSkill(i) {
    let totalLand: number = 0;
    let form = this.projectForm.valid
    if (form) {
      this.detailsOfLand.push(this.detailsform());
    }
  }

  viewDocument(data) {
    this.PaymentService.viewimage(data).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }

  removeDocument(data, i) {
    this.PaymentService.deleteimage(data).subscribe(
      (res) => {
        this.detailsOfLand.controls[i].get('image').setValue('')
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }
  forms() {
    this.projectForm = this.formBuilder.group({
      LandDetailsForm: this.formBuilder.array([]),
    })
  }
  detailsform(): FormGroup {
    return this.formBuilder.group({
      landOwnerName: ['', Validators.required],
      aadhaarNo: ['12', [Validators.required, Validators.minLength(12)]],
      aacountNo: ['', Validators.required],
      ifscCode: ['', Validators.required],
      mobileNumber: ['', [Validators.required,Validators.pattern("^[6-9]\\d{9}$")]],
      bankName: ['', Validators.required],
      branchName: ['', Validators.required],
      ExtentOwned: ['', Validators.required],
      image: [''],
      verfyBank: [''],
    });
  }
  onUpload(event) {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
  }
  getData(data){
    console.log(data);
  }

  getParentId() {
    this.forms()
    this.totalExtend = 0;
    this.compensation = 0;
    let payload
    if (this.partSubDiv) {
      payload = {
        "projectID": this.projectId,
        "districtLGDCode": this.selectDistrictId,
        "talukLGDCode": this.talukId,
        "revenueLGDCode": this.villageID,
        "surveyNumber": this.stage2SearchFilter.surveyNo,
        "totalCost": this.stage2SearchFilter.compensationAmount[0],
        "extendToBeAcquired": this.stage2SearchFilter.totalExtent[0],
        "subDivisionNumber": this.stage2SearchFilter.subDiv,
        "totalNumberOfLandOwners": this.stage2SearchFilter.noOfOwner,
        'partSubDivisionNumber': this.stage2SearchFilter.partSubdiv
      }
    } else {
      payload = {
        "projectID": this.projectId,
        "districtLGDCode": this.selectDistrictId,
        "talukLGDCode": this.talukId,
        "revenueLGDCode": this.villageID,
        "surveyNumber": this.stage2SearchFilter.surveyNo,
        "totalCost": this.stage2SearchFilter.compensationAmount[0],
        "extendToBeAcquired": this.stage2SearchFilter.totalExtent[0],
        "subDivisionNumber": this.stage2SearchFilter.subDiv,
        "totalNumberOfLandOwners": this.stage2SearchFilter.noOfOwner,
      }
    }
    this.spinner.show()
    this.beneficiaryService.save(payload).subscribe(
      (res: any) => {
        this.spinner.hide()
        this.beneficiaryDialog = true
        this.parentId = res.body.data.id
        for (let index = 0; index < this.stage2SearchFilter.noOfOwner; index++) {
          this.addSkills();
        }
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide()
      }
    );
  }
  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('asPerAct').value);
    let throughAct: any = Number(this.detailsOfLand.controls[i].get('asPerPrivateNego').value)
    let total: any = privateNegotitaion + throughAct
    if (privateNegotitaion != '' && throughAct != '') {
      this.detailsOfLand.controls[i].get('totalCompensation').setValue(this._decimalPipe.transform(Number(total), "1.2-2"));
    }
  }

  getTotals() {
    let totalExtend = 0
    let compensation = 0
    this.detailsOfLand.value.forEach((element) => {
      totalExtend += Number(element.ExtentOwned);
      compensation += Number(element.compensationAmount);
    });
  }

  keyPressNumbers(event, i) {
    if (event.target.value.length == 12) {
      this.spinner.show()
      this.beneficiaryService.getAadhaar(event.target.value).subscribe(res => {
        this.bankDetails = res.body.data[0]
        if (this.bankDetails != undefined) {
          this.spinner.hide()
          this.detailsOfLand.controls[i].get('landOwnerName').setValue(this.bankDetails.benificiaryLandOwnerName);
          this.detailsOfLand.controls[i].get('aacountNo').setValue(this.bankDetails.accountNumber);
          this.detailsOfLand.controls[i].get('ifscCode').setValue(this.bankDetails.ifscCode);
          this.detailsOfLand.controls[i].get('mobileNumber').setValue(this.bankDetails.mobileNumber);
          this.detailsOfLand.controls[i].get('bankName').setValue(this.bankDetails.nameOfBank);
          this.detailsOfLand.controls[i].get('branchName').setValue(this.bankDetails.branchName);
          this.detailsOfLand.controls[i].get('image').setValue(this.bankDetails.photo);
        } else {
          this.spinner.hide()
          this.detailsOfLand.controls[i].get('landOwnerName').setValue('');
          this.detailsOfLand.controls[i].get('aacountNo').setValue('');
          this.detailsOfLand.controls[i].get('ifscCode').setValue('');
          this.detailsOfLand.controls[i].get('mobileNumber').setValue('');
          this.detailsOfLand.controls[i].get('bankName').setValue('');
          this.detailsOfLand.controls[i].get('branchName').setValue('');
          this.detailsOfLand.controls[i].get('ExtentOwned').setValue('');
          this.detailsOfLand.controls[i].get('image').setValue('');
        }
      }, (err) => {
        this.spinner.hide()
      })
      event.preventDefault();
      return;
    }
  }


  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  addlandDetails() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.totalCompensation);
    });
    //console.log(totalLand, 'totalLand');

    if (totalLand != 0) {
      if (this.stage2SearchFilter.totalExtent >= totalLand) {
        if (totalLand == this.stage2SearchFilter.totalExtent) {
          this.notificationService.alertInfo(
            'Equal',
            'Acquired land in the district is equal to total amount of land'
          );
        } else {
          this.detailsOfLand.push(this.detailsform());
        }
      } else {
        this.notificationService.alertError(
          'Alert',
          "Total Compensation determined for this survey number should not exceed Total extent Acquired"
        );
      }
    }
  }

  // getTotal(i) {
  //   //console.log(i);
  //   let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('privateNegotitaion').value);
  //   let throughAct: any = Number(this.detailsOfLand.controls[i].get('throughAct').value)
  //   let total: any = privateNegotitaion + throughAct
  //   if (privateNegotitaion != '' && throughAct != '') {
  //     //console.log(privateNegotitaion)
  //     this.detailsOfLand.controls[i].get('total').setValue(total);

  //   }
  //   let totalLand: number = 0;
  //   let act: number = 0
  //   this.detailsOfLand.value.forEach((element) => {
  //     totalLand += Number(element.privateNegotitaion);
  //     act += Number(element.throughAct);
  //   });

  //   this.totalLandPrivate = totalLand
  //   this.throughAct = act
  // }


  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.messageService.clear()
    // this.notificationService.hideLoader()
  }
}
