import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../shared/font-service/font.service';
import { ValidationService } from '../shared/validation/validation.service';
import { NotificationService } from '../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../shared/models/search-result';
import { Project } from './project.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SigninService } from '../auth/signin/signin.service';
import { PaymentService } from '../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from './project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  projectFilter
  requestingDepartment
  fundFilter
  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  toDate:Date
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;
  filterQuery: any = { typeOfAvailabilityOfFund: '', requestingAgencyId: '', projectName: '', districtName: '', page: '', size: '' };

  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  isShowTable: boolean = false;

  filter = {
    agency: '',
    projectName: '',
    district: ''
  }
  roleNameId: any;
  categoryLAO: any;
  projectVirual: any;
  pageLength: number;
  uploadedFiles: any[];
  landImage: any;
  isFile1: boolean;
  vFileExt1: any;
  vFileUrl1: string;
  viewDoc1: boolean;
  vFileExt2: any;
  vFileUrl2: string;


  viewDoc2: boolean;
  isFile2: boolean;
  fileUpload2: any;
  paperPublication: any = [];
  files: any = [];
  landImages: any = [];
  landPhototsArr: any = [];
  params: any;
  sortDirection: any = 'asc';
  constructor(
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private projectService: ProjectService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });

    this.getUserrole();
  }
  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },
  ];
  ngOnInit() {
    this.toDate = new Date()
    this.forms();
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
    this.projectService.getCategoryLao().subscribe((res: any) => {
      this.categoryLAO = res.body.data;
    });
    this.projectService.getLandUnit().subscribe((res: any) => {
      this.landUnits = res.body.data;
    });
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    this.label = 'Add';
    this.viewOnly = false;
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {

        this.roleNameId = res.body.role.id
        if (
          res.body.role.id != 1 &&
          res.body.role.id != 5 &&
          res.body.role.id != 4 &&
          res.body.role.id != 3
        ) {
          this.roleId = true;
        } else {
          this.roleId = false;
        }
      },
      (onError) => { }
    );
  }

  forms() {
    this.projectForm = this.formBuilder.group({
      requestingDep: ['', Validators.required],
      projectName: ['', Validators.required],
      sanctionDate: ['', Validators.required],
      landUnit: ['', Validators.required],
      landsAcquire: ['', Validators.required],
      fileGO: [''],
      VAcNo: [''],
      ifscCode: [''],
      bankName: [''],
      branch: [''],
      amount: [''],
      LandDetailsForm: this.formBuilder.array([]),
    });
  }
  detailsform(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      districtName: ['', Validators.required],
      landAcquireInDistrict: ['', Validators.required],
      Categorylao: ['', Validators.required],
      lao: ['', Validators.required],
      isFlag: [],
    });
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  addSkill() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (totalLand != 0) {
      if (this.projectForm.get('landsAcquire').value >= totalLand) {
        this.remainValue =
          Number(this.projectForm.get('landsAcquire').value) -
          Number(totalLand);
        if (totalLand == this.projectForm.get('landsAcquire').value) {
          this.notificationService.alertInfo(
            'Equal',
            'Acquired land in the district is equal to total amount of land'
          );
        } else {
          this.detailsOfLand.push(this.detailsform());
        }
      } else {
        this.notificationService.alertError(
          'Alert',
          'Acquired land in the district should be less than total amount of land'
        );
      }
    }
  }

  keyPressNumbersDecimal(event, lable) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      if (lable == 'land') {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 4) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      } else {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 2) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return true;
  }

  removeSkill(i: number) {
    let deleteData = this.detailsOfLand.at(i).value;
    this.deletedProject.push(deleteData);
    this.deletedProject.forEach(element => {
      if (element.id != '' && element.id != undefined) {
        this.editContent.push(element)
        this.deletedProject = []
      }
    });

    this.detailsOfLand.removeAt(i);
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    })
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.getValues('');
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }



  calcAmount() {
    if (!this.enablepublicContributionAmount) {
      this.project.administrativeSanctionAmount = this.project.totalCost;
      return;
    }
    this.projectService
      .getAmount(this.selectedpublicContribution.id, this.project.totalCost)
      .subscribe(
        (res: any) => {
          this.project.publicContributionAmount = res.body;
          this.project.administrativeSanctionAmount = Number(
            (
              this.project.totalCost - this.project.publicContributionAmount
            ).toFixed(2)
          );
        },
        () => { }
      );
  }

  openNew(val, content?: any) {
    this.deletedProject = []
    this.editContent = []
    this.deletePayload = []
    this.project = new Project();
    this.landImage = []
    this.project.fileUpload2 = ''
    this.isFile2 = false
    this.projectId = content.id;
    if (val == 'edit') {
      this.viewOnly = false;
      this.label = 'Edit';
      this.forms();
      this.districtIds = [];
      this.projectDialog = true;
      this.readonly = false;
      this.projectService.getAgency().subscribe((res: any) => {
        this.AgencyLists = res.body.data;
      });
      this.spinner.show();

      this.projectService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.spinner.hide();
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.projectService.getOnceProject(content.id).subscribe((res: any) => {
        this.singleProject = res.body.data.projectDetail;
        this.projectVirual = res.body.data.projectVirtualAccount;
        this.singleProject.projectLocation.forEach((element) => {
          this.districtIds.push(element.id);
        });
        this.projectForm.patchValue({
          requestingDep: this.singleProject.agencyId.agencyName,
          projectName: this.singleProject.projectName,
          sanctionDate: this.datepipe.transform(
            this.singleProject.adminSanctionDate,
            'dd/MM/YYYY'
          ),
          VAcNo: this.projectVirual.accountNumber,
          ifscCode: this.projectVirual.ifscCode,
          landUnit: this.singleProject.landUnitId.id,
          bankName: this.projectVirual.bankName,
          branch: this.projectVirual.branchName,
          amount: this.projectVirual.amount,
          // costOfLand: this.singleProject.tentativeLandCost,
          landsAcquire: this.singleProject.totalExtentOfLands,
          // TypeOfFund: this.singleProject.typeOfAvailabilityOfFund,
        });
        if (this.singleProject.files != '' && this.singleProject.files != 'null' ) {
          this.project.fileUpload = this.singleProject.files
          this.projectForm.get('fileGO').setValue(this.singleProject.files)
          this.isFile = true
        } else {
          this.isFile = false

        }
        this.displayItemsEdit();
      });
    } else {
      this.viewOnly = true;
      this.forms();
      this.project = new Project();
      this.projectDialog = true;
      this.readonly = true;
      this.projectService.getAgency().subscribe((res: any) => {
        this.AgencyLists = res.body.data;
      });
      this.spinner.show();
      this.projectService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.spinner.hide();
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.projectService.getOnceProject(content.id).subscribe((res: any) => {
        this.singleProject = res.body.data.projectDetail;
        this.projectVirual = res.body.data.projectVirtualAccount;
        this.singleProject = res.body.data.projectDetail;
        //console.log(this.singleProject);
        let date = this.datepipe.transform(
          this.singleProject.adminSanctionDate,
          'dd/MM/YYYY'
        );
        this.projectForm.patchValue({
          requestingDep: this.singleProject.agencyId.agencyName,
          projectName: this.singleProject.projectName,
          sanctionDate: date,
          VAcNo: this.projectVirual.accountNumber,
          ifscCode: this.projectVirual.ifscCode,
          landUnit: this.singleProject.landUnitId.id,
          bankName: this.projectVirual.bankName,
          branch: this.projectVirual.branchName,
          amount: this.projectVirual.amount,
          // costOfLand: this.singleProject.tentativeLandCost,
          landsAcquire: this.singleProject.totalExtentOfLands,
          // TypeOfFund: this.singleProject.typeOfAvailabilityOfFund,
        });
        if (this.singleProject.files != '' && this.singleProject.files != 'null') {
          this.project.fileUpload = this.singleProject.files
          this.projectForm.get('fileGO').setValue(this.singleProject.files)
          this.isFile = true
        } else {
          this.isFile = false
        }
        this.displayItems();
      });
    }
  }

  createItem(item: any) {
    if (item.adminUser.laoCategory.id == 3) {
      this.getDistrictEdit(item.adminUser.laoCategory.id);
      return this.formBuilder.group({
        id: [item.id],
        districtName: [item.districtLGDCode.id, [Validators.required]],
        landAcquireInDistrict: [item.extentLand, [Validators.required]],
        Categorylao: [item.adminUser.laoCategory.id, Validators.required],
        lao: [item.adminUser.designation, [Validators.required]],
        isFlag: [],
      });
    } else {
      return this.formBuilder.group({
        id: [item.id],
        districtName: [item.districtLGDCode.id, [Validators.required]],
        landAcquireInDistrict: [item.extentLand, [Validators.required]],
        Categorylao: [item.adminUser.laoCategory.id, Validators.required],
        lao: [{ value: "", disabled: true },],
        isFlag: [],
      });
    }
  }

  displayItems() {
    let transformedItems = this.singleProject.projectLocation.map((item: any) =>
      this.createItem(item)
    );
    this.projectForm.setControl(
      'LandDetailsForm',
      this.formBuilder.array(transformedItems)
    );
  }

  displayItemsEdit() {
    let transformedItems = this.singleProject.projectLocation.map((item: any, i) =>
      this.createItemEdit(item, i)
    );
    this.projectForm.setControl(
      'LandDetailsForm',
      this.formBuilder.array(transformedItems)
    );
  }

  createItemEdit(item: any, i) {
    this.districtId = [];
    if (item.adminUser.laoCategory.id == 3) {
      this.getDistrictEdit(item.adminUser.laoCategory.id);
      this.districtId.push(item.districtLGDCode.id);
      return this.formBuilder.group({
        id: [item.id],
        districtName: [item.districtLGDCode.id, [Validators.required]],
        landAcquireInDistrict: [item.extentLand, [Validators.required]],
        Categorylao: [item.adminUser.laoCategory.id, Validators.required],
        lao: [item.adminUser.designation, [Validators.required]],
        isFlag: [],
      });
    } else {
      this.districtId.push(item.districtLGDCode.id);
      return this.formBuilder.group({
        id: [item.id],
        districtName: [item.districtLGDCode.id, [Validators.required]],
        landAcquireInDistrict: [item.extentLand, [Validators.required]],
        Categorylao: [item.adminUser.laoCategory.id, Validators.required],
        lao: [{ value: "", disabled: true },],
        isFlag: [],
      });
    }

  }


  viewDocument2() {
    this.spinner.show()
    this.paymentService.viewimage(this.project.fileUpload2).subscribe((res) => {
      this.spinner.hide()

      const filename = res.body.fileName;
      this.vFileExt2 = filename.split('.').pop();
      this.vFileUrl2 =
        'data:image/' + this.vFileExt2 + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc2 = true;
    });
  }

  removeDocument2() {
    this.paymentService.deleteimage(this.project.fileUpload2).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile2 = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }



  getProjectsFilter(page?, fundFilter?, requestingDepartment?, params?) {
    this.page = page
    this.fundFilter = fundFilter
    this.params = requestingDepartment
    this.fundFilter = fundFilter
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    console.log(params);

    if (params) {
      this.filterQuery = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        typeOfAvailabilityOfFund: fundFilter ?? '',
        requestingAgencyId: this.params.agency.id ?? '',
        projectName: this.params.projectName ?? '',
        districtName: this.params.district ?? '',
        sortBy: params,
        sortDirection: this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      this.filterQuery = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        sort: ['createdOn,asc'],
        typeOfAvailabilityOfFund: fundFilter ?? '',
        requestingAgencyId: this.params.agency.id ?? '',
        projectName: this.params.projectName ?? '',
        districtName: this.params.district ?? ''
      };
    }

    this.projectService.getProjectFilter(this.filterQuery).subscribe(res => {
      this.spinner.hide();
      this.filterProps = this.filterProps ? this.filterProps : [];
      this.filterProps.push({
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      });
      // this.projectList = data.data;
      this.onSuccess(res.body, res.headers, page, '');

      this.isShowTable = true
    })
  }

  getDistricts(e, i) {
    if (e.id == 3) {
      this.projectService.LAOLists(e.id).subscribe((res: any) => {
        this.LAOAdmin = res.body.data;
      });
      this.detailsOfLand.controls[i].get('lao').setValidators(Validators.required);
      this.detailsOfLand.controls[i].get('lao').enable();
    } else {
      this.detailsOfLand.controls[i].get('lao').clearValidators();
      this.detailsOfLand.controls[i].get('lao').disable();
      // this.hideValue(i)  
    }

    //console.log(this.detailsOfLand.controls[i].get('lao'));

    this.detailsOfLand.controls[i].get('lao').setValue('');
  }




  clear(data) {
    if (data == 'typeOfFund') {
      this.fundFilter = ''
      this.filterQuery.typeOfAvailabilityOfFund = ''
    } else if (data == 'agency') {
      this.filter.agency = ''
      this.filterQuery.requestingAgencyId = ''
    } else if (data == 'project') {
      this.filter.projectName = ''
      this.filterQuery.projectName = ''
    } else if (data == 'district') {
      this.filter.district = ''
      this.filterQuery.district = ''
    }
    this.getProjectsFilter(this.page, this.fundFilter, this.params, '')

  }

  getDistrictEdit(e) {
    this.projectService.LAOLists(e).subscribe((res: any) => {
      this.LAOAdmin = res.body.data;
    });
  }

  removeDocument() {
    this.paymentService.deleteimage(this.project.fileUpload).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
      const formData = new FormData();
      const i = $event.files.length - 1;
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
      this.spinner.show()
      this.paymentService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
          this.notificationService.alertSuccess('Image Upload SuccessFully', '');
          this.fileUpload = res.body.fileDownloadUri;
          this.spinner.hide()
          this.projectForm.get('fileGO').setValue(res.body.fileName)
          this.project.fileUpload = res.body.fileName;
          this.isFile = true;
        },
        (onerr) => { }
      );
    
  }
  getValues(eve) {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
      this.showError = false;
    } else if (Number(this.projectForm.get('landsAcquire').value) < totalLand) {
      this.showError = true;
      this.disableButton = false;
    } else {
      this.disableButton = false;
      this.showError = false;
    }
  }
  viewDocument() {
    this.spinner.show()
    this.paymentService.viewimage(this.project.fileUpload).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    }, (err) => {
      this.spinner.hide()
    });
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.getProjectsFilter(this.page, this.fundFilter, this.params, '')
  }

  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }
  hideDialog() {
    this.ngSelectInvalid = false;
    this.project = new Project();
    this.projectDialog = false;
    this.enablepublicContributionAmount = null;
    this.selectedpublicContribution = null;
    this.isFile = false;
    this.viewOnly = false;
    this.edit = false;
  }
  saveData(label) {
    let form = this.projectForm.value;
    let landFrom = [];
    let district = [];
    this.files = []
    let params = {
      id: this.projectId,
    };
    if (this.projectForm.valid) {
      this.editContent.forEach(element => {

        if(element.landAcquireInDistrict <= 0){
            this.notificationService.alertError('Land area should not to be negative','');
            return;
        }
        let payload = {
          districtLGDCode: element.districtName,
          extentLand: element.landAcquireInDistrict,
          designation: element.lao,
          projectId: element.id,
          deletedFlag: true
        }
        this.deletePayload.push(payload)
      });
      landFrom.push(...this.deletePayload)

      this.files.push(this.project.fileUpload)
      form.LandDetailsForm.forEach((element) => {
        if(element.landAcquireInDistrict <= 0){
          this.notificationService.alertError('Land area should not to be negative','');
          return;
      }
        district.push(element.districtName);
        let data = {
          districtLGDCode: element.districtName,
          extentLand: element.landAcquireInDistrict,
          designation: element.lao,
          projectId: element.id
        };
        landFrom.push(data);
      });

      if(form.landsAcquire <= 0){
        this.notificationService.alertError('Land area should not to be negative','');
        return;
    }

      let payload = {
        agencyId: form.requestingDep.id,
        projectName: form.projectName,
        landUnitId: form.landUnit,
        adminSanctionDate: this.singleProject.adminSanctionDate,
        totalExtentOfLands: form.landsAcquire,
        files: this.files,
        projectLocation: landFrom,
      };
      this.projectService.editProject(payload, params).subscribe(
        (res) => {
          this.getProjectsFilter(this.page, this.fundFilter, this.params, '')
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.files = []
          this.landImages = []
          this.paperPublication = []
          this.hideDialog();
        },
        (err) => {
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    }

  }

  deleteAgency(data) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the project?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinner.show();
        this.projectService.deleteAgency(data.id).subscribe(
          (res: HttpResponse<any[]>) => {
            this.spinner.hide();
            this.loadPage();
          },
          () => {
            this.onError();
          }
        );
      },
    });
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.projectService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Project[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: any
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    // this.searchResult.items = data ?? [];
    this.projectList = data.data;
    this.totalCount = data.totalCount;
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onError(): void { }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      } else if ($event.type === 'select') {
        this.viewOnly = true;
        this.edit = true;
      }
      this.paymentService.getProjectByWorkid($event.value.workId).subscribe(
        (res: Project) => {
          this.project = res;
          if (this.project.technicalSanctionDate) {
            this.project.technicalSanctionDate = new Date(
              this.project.technicalSanctionDate
            );
          }
          if (this.project.agreementDate) {
            this.project.agreementDate = new Date(this.project.agreementDate);
          }
          this.projectDialog = true;
          if (this.project.administrativeSanctionDate) {
            this.project.administrativeSanctionDate = new Date(
              this.project.administrativeSanctionDate
            );
          }
          if (this.project.fileUpload) {
            this.isFile = true;
          }
          this.publicContributionAmount.forEach((element) => {
            if (element.value === this.project.contributionPercentage) {
              this.selectedpublicContribution = element;
            }
          });
          if (this.project.publicContribution == 'Yes') {
            this.enablepublicContributionAmount = true;
          }
        },
        () => {
          this.onError();
        }
      );
    }

  }
}