import { AdminUserDtoCreatedby, AdminUserDtoUpdatedBy } from "src/app/payments/payment-voucher/payment-voucher.model";

export class Agency {
    deptCode?: string;
    schemeCode?: string;
    agencyName?: string;
    gstNo?: string;
    mobileNo?: number;
    email?: string;
    designation?: string;
    levelName?: string;
    levelMasterName?: string;
    bankAccountName?: string;
    bankId?: string;
    bankAccountNo?: string;
    ifsc?: string;
    branchName?: string;
    branchMobileNo?: string;
    branchEmail?: string;
    branchAddress?: string;
}
export class AgencyDto {
    id: number
    name: string
    mobile: any
    email: any
    schemeDto: any
    zonalName: any
    leveltype: number
    gstNo: string
    designation: any
    levelMaster: number
    mapping: string
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
    bankAccName: any
    bankId: any
    accountNumber: any
    ifscCode: any
    branchName: any
    branchMobNo: any
    branchEmail: any
    address: any
    activeFlag: string
    state: any
    district: any
    health: any
    block: any
    editImplementingAgency: boolean
}