import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<any>;
import { Agency, AgencyDto } from './payment';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient) { }

  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getList');
  protected paymentUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/updateLaoStatus');
  protected paymentRejectUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/rejectVoucher');
  protected recommendVoucher = environment.SERVER_API_URL + ('api/benificiaryLandOwner/recommendForPayment')


  beneficiary(parmas): Observable<HttpResponse<any>> {

    let params = new HttpParams()
    .set('surveyNumber', parmas.sNo != undefined ? parmas.sNo :'' )
    .set('name', parmas.name != undefined ? parmas.name :'')
    .set('mobileNumber', parmas.mobileNumber != undefined ? parmas.mobileNumber :'')

    return this.http.get<AgencyDto>(`${this.resourceSaveUrl}`, { observe: 'response',params });
  }
  payment(parmas): Observable<HttpResponse<any>> {

    let params = new HttpParams()
    .set('voucherNo', parmas.voucherNo != undefined ? parmas.voucherNo :'' )
    .set('name', parmas.name != undefined ? parmas.name :'')
    .set('mobileNumber', parmas.mobileNumber != undefined ? parmas.mobileNumber :'')
    
    return this.http.get<AgencyDto>(`${this.paymentUrl}`, { observe: 'response',params });
  }

  save(beneficiary?: any): Observable<HttpResponse<any>> {
    return this.http
      .post<any>(`${this.paymentUrl}`, beneficiary, { observe: 'response' })
  }

  recommend(projectDto:any,pId){
    return this.http
    .post<any>(`${this.recommendVoucher}?project=${pId}`, projectDto, { observe: 'response' })
  }

  reject(voucherNo:string){
    return this.http
    .post(`${this.paymentRejectUrl}?voucherNo=${voucherNo}`,[], { observe: 'response',responseType:'text' })
  }


}