import {
  LevelMaster,
  LevelMasterDto,
} from 'src/app/module/level-master/level-master';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';

export type EntityResponseType = HttpResponse<LevelMaster>;
export type EntityArrayResponseType = HttpResponse<LevelMaster[]>;

@Injectable()
export class LevelMasterService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getViewLevelMasterByFilter';
    protected resourceFliter =
    environment.SERVER_API_URL + 'api/levelMasterList';
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/saveLevelMaster';
  protected resourceDeleteUrl =
    environment.SERVER_API_URL + 'api/deleteProfile';
  protected getLevelMasterDtoFliterUrl =
    environment.SERVER_API_URL + 'api/getLevelMasterByFilter';
  protected resourcegetUrl = environment.SERVER_API_URL + 'api/getLevelMaster';
  protected levelMasterByIdUrl = environment.SERVER_API_URL + 'api/levelMasterByLevelId';
  protected levelMasterById = environment.SERVER_API_URL + 'api/levelMasterByLevelId';
  protected getVillageListBySchemeUrl =
  environment.SERVER_API_URL + 'api/getVillageListByScheme';
  
  constructor(protected http: HttpClient) {}
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<LevelMaster[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }

  filterbyId(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    return this.http.get<any[]>(`${this.resourceFliter}`, {
      observe: 'response',
    });
  }

  LevelMasterDtoFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<LevelMasterDto[]>(
      this.getLevelMasterDtoFliterUrl,
      filter,
      { params: options, observe: 'response' }
    );
  }

  levelMasterByFilter(id?): Observable<any> {
    return this.http.get<LevelMasterDto>(`${this.levelMasterByIdUrl}?levelId=${id}`, {
      observe: "response",
    });
  }

  levelMasterByFilterId(id?): Observable<any> {
    return this.http.get<LevelMasterDto>(`${this.levelMasterById}?levelId=${id}`, {
      observe: "response",
    });
  }

  villageListByScheme(req?: any): Observable<any> {
   
    return this.http.post<LevelMasterDto[]>(
      this.getVillageListBySchemeUrl,req,
      { observe: 'response' }
    );
  }
  save(levelMaster?: LevelMasterDto): Observable<any> {
    return this.http.post<LevelMasterDto>(this.resourceSaveUrl, levelMaster, {
      observe: 'response',
    });
  }

  getLevelMaster(id: number): Observable<HttpResponse<any>> {
    return this.http.get<LevelMasterDto>(`${this.resourcegetUrl}?id=${id}`, {
      observe: "response",
    });
  }

  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  // }
}
