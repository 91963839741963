<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Taluks and Village</h2>
</div>
<div class="container-fluid bg">
    <div class="container bgFilter">
        <div class="row align-items-center mt-2">
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                    Name</label>
                <ng-select id="department" bindLabel="projectName" (change)="selectProject($event)" appearance="outline"
                    [(ngModel)]="fundFilter" [items]="projectList" class="form-control" appNgSelectInputRestriction>
                </ng-select>
            </div>
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Districts</label>
                <ng-select id="department" bindLabel="name" appearance="outline" (change)="selectDistrict($event)"
                    [(ngModel)]="projectDistrict" [items]="districtsByProject" class="form-control" appNgSelectInputRestriction>
                </ng-select>
            </div>
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Total extent Acquired(In
                    hectares)</label>
                <input type="number" class="form-control" name="name" readonly autocomplete="off"
                    [(ngModel)]="totalExtendsValue.totalExtent" maxlength="60"
                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
            </div>

            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Total Fund Received</label>
                <input type="number" class="form-control" name="name" readonly autocomplete="off"
                    [(ngModel)]="totalExtendsValue.fundReceived" maxlength="60"
                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
            </div>
        </div>
        <div class="flex align-items-center mt-3 ">
        </div>
    </div>
    <div class="row" *ngIf="isShowTable">
        <div class="row">
            <div class="col-md-12">
                <div class="mt-3">
                    <span>Land Acquired(In hectares): {{remainValue}}</span>
                </div>
                <form [formGroup]="projectForm" class="mt-4">
                    <div formArrayName="LandDetailsForm">
                        <table style="width:100%">
                            <tr class="text-center">
                                <th colspan="9" style="background: #017DC3" class="text-center">
                                    {{projectName}}
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="2" style="width:200px;background: #017DC3">Taluk Name</th>
                                <th rowspan="2" style="width:200px;background: #017DC3;">Village Name</th>
                                <th rowspan="2" style="width:120px;background: #017DC3;">Name of
                                    the Unit</th>
                                <th rowspan="2" style="width:120px;;background: #017DC3;">Name of the
                                    Block</th>
                                <th colspan="4" class="text-center" style="background: #017DC3;">Total Extent for which
                                    Final Award Passed(In hectares)</th>
                                <th rowspan="2" style="background: #017DC3;width:50px !important">Action</th>
                            </tr>
                            <tr>
                                <th style="background: #017DC3;width:120px;">Through Private
                                    Negotiation</th>
                                <th style="background: #017DC3;width:120px;">Through Act</th>
                                <th style="width:120px;background: #017DC3;">Total</th>
                                <th style="width:120px;background: #017DC3;">Total(In Square metre)</th>
                            </tr>
                            <tr *ngFor="let landDetails of detailsOfLand.controls; let i=index" [formGroupName]="i">
                                <td>
                                    <ng-select id="type" [items]="taluk" bindLabel="name" appearance="outline"
                                        [closeOnSelect]="true" formControlName="talukName" [clearable]="true"
                                        class="form-control" (change)="OnSelectTaluk($event,i)"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select id="type" [items]="villages" bindLabel="name" appearance="outline"
                                        [closeOnSelect]="true" formControlName="villageName" [clearable]="true"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select id="type" [items]="sampleNumber" bindLabel="key" appearance="outline"
                                        [closeOnSelect]="true" [clearable]="true" formControlName="nameOfUnit"
                                        class="form-control" 
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select id="type" [items]="sampleNumber" bindLabel="key" appearance="outline"
                                        [closeOnSelect]="true" [clearable]="true" formControlName="nameOfBlock"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                    </ng-select>
                                </td>
                                <td>
                                    <input type="number" class="form-control" name="name" (keyup)="getTotal(i)"
                                        formControlName="privateNegotitaion" (keypress)="keyPressNumbersDecimal($event)"
                                        id="name" />
                                </td>
                                <td>
                                    <input type="number" class="form-control" name="name" (keyup)="getTotal(i)"
                                        formControlName="throughAct" (keypress)="keyPressNumbersDecimal($event)"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                                </td>

                                <td>
                                    <input type="text" class="form-control" name="name" readonly autocomplete="off"
                                        maxlength="60" formControlName="total"
                                         />
                                </td>
                                <td>
                                    <input type="text" class="form-control" name="name" readonly autocomplete="off"
                                        maxlength="60" formControlName="totalInSqM"
                                         />
                                </td>
                                <td>
                                    <i class="pi pi-plus-circle mt-1" (click)="addSkill(i)"></i> &nbsp;
                                    <i class="pi pi-trash" *ngIf="detailsOfLand.length > 1"
                                        (click)="removeSkill(i)"></i>
                                </td>
                            </tr>
                            <tr style="background: rgba(1, 125, 195, 0.05); color: black;vertical-align: middle;">
                                <td colspan="4">
                                    <h5>Total Extent</h5>
                                </td>
                                <td class="">
                                    <p> {{totalLandPrivate | number}} </p>
                                </td>
                                <td class="">
                                    <p> {{throughAct | number}} </p>
                                </td>
                                <td>
                                 <p> {{totals | number}}</p>  
                                </td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                </form>
                <div class="col-md-12">
                    <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">Publication Image</label><br>
                    <small [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file
                        types are jpg, jpeg, png, pdf and
                        Maximum file size should be 1MB</small>
                </div>
                <div class="col-md-12" *ngIf="!isFile">
                    <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" maxFileSize="1000000"
                        (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" mode="basic"
                        (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false"
                        [disabled]="isFile || (viewOnly && !edit)" [showCancelButton]="false"
                        chooseLabel="{{'common.upload'|translate}}">
                    </p-fileUpload>
                </div>
                <div class="col" *ngIf="isFile">
                    <a class="cursor-pointer" (click)="viewDocument()">{{publicationImage}}</a>
                    <i *ngIf="!viewOnly || edit" class="pi pi-times add-margin" (click)="removeDocument()"></i>
                </div>
                <div class="flex align-items-center mt-2">
                    <p-button label="Submit" class="submit" [disabled]="projectForm.invalid"
                        (click)="submit()"></p-button>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
styleClass="ib-dialog">
<ng-template pTemplate="body">
    <div style='position: relative; height: 100%;'>
        <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
        <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
    </div>
</ng-template>
</p-dialog>