import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './stage3.service';
import { Agency, AgencyDto } from './stage3';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../payment/payment.service';
import { ProjectService } from 'src/app/project/project.service';
import { DatePipe } from '@angular/common';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage3-view',
  templateUrl: './stage3-view.component.html',
  styleUrls: ['./stage3-view.component.scss']
})
export class Stage3ViewComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  beneficiary: any = {}
  beneficary: any;
  ingredient
  state: any[];
  district: any[];
  projectList: any;
  beneficiaryList: any[];
  taluk: AgencyDto[];
  villages: AgencyDto[];
  items: { label: string; command: (event: any) => void; }[];
  districtList: void;
  activeIndex: number;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  totalCount: any;
  pageCount: number = 0;
  index: any;

  editDialog: boolean
  legalName: any
  pageLength: number;
  confirmValues: any;
  legalzier: boolean;
  awardsDialog: boolean;
  selectData: any;
  fileUpload: any;
  project: any = {
    fileUpload: '',
    fileUpload1: '',

  };
  isFile: boolean;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  land: any = 0;
  tree: any = 0;
  wellAmonut: any = 0;
  landAmount: any = 0;
  Building: any = 0;
  other: any = 0;
  totals: any = 0;
  selectDatas: any;
  bankDetails: any;
  fileUpload1: any;
  isFile1: boolean = false;
  vFileExt1: any;
  vFileUrl1: string;
  viewDoc1: boolean;
  vFileExtList: any;
  vFileUrlList: string;
  viewDocList: boolean;
  legalzierForm: FormGroup;
  tempLegalArr: any = [];
  disableButton: boolean;
  legalAmount: any = 0;
  indexValue: any;
  legalHeir: any;
  viewListForLegal: boolean;
  sortDirection: string = 'asc';
  disableAward: any;
  SelectData: any;
  isProceeding: boolean;
  isStructure: boolean;
  ProceedingDate: any;
  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public router: Router,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
    public ProjectService: ProjectService,
    public PaymentService: PaymentService,
  ) {

  }
  samp = [
    {
      beneficiaryStructureValueId: '',
      structuredValueName: '',
      amount: ''
    },
    {
      beneficiaryStructureValueId: '',
      structuredValueName: '',
      amount: ''
    },
    {
      beneficiaryStructureValueId: '',
      structuredValueName: '',
      amount: ''
    },
    {
      beneficiaryStructureValueId: '',
      structuredValueName: '',
      amount: ''
    }
  ]
  bank = {
    account: '',
    ifscCode: '',
    bankName: '',
    branch: '',
    aadhaarNo: ''
  }
  Proceeding = {
    Proceeding: '',
    date: '',
    id: ''
  }
  stage1 = {
    benificiaryLandOwnerName: '',
    mobileNumber: '',
    aadharNumber: '',
    ifscCode: '',
    accountNumber: '',
    photo: '',
    beneficiaryStructuredValue: '',
    beneficiaryProceeding: '',
    id: ''
  }
  stage1Filter = {
    projectName: '',
    name: '',
    mobile: ''
  }

  awardAmount: any = {
    landAmount: '',
    treeAmount: 0,
    wellAmount: 0,
    buildingAmount: 0,
    otherStructure: 0,
    totalAmount: ''
  }
  getTotals(e, val) {
    this.land = this.awardAmount.landAmount
    console.log(this.land);

    if (val == 'landAmount') {
      this.land = this.awardAmount.landAmount
      this.awardAmount.landAmount = this.land
      this.totals = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure);
      this.awardAmount.totalAmount = this.totals
    } else if (val == 'tree') {
      this.tree = Number(e.target.value)
      this.awardAmount.treeAmount = this.tree
      this.totals = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure);
      this.awardAmount.totalAmount = this.totals
      this.samp[0].amount = this.awardAmount.treeAmount
    } else if (val == 'wellAmonut') {
      this.wellAmonut = Number(e.target.value)
      this.awardAmount.wellAmount = this.wellAmonut
      this.totals = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure);
      this.awardAmount.totalAmount = this.totals
      this.samp[1].amount = this.awardAmount.wellAmount
    } else if (val == 'Building') {
      this.Building = Number(e.target.value)
      this.awardAmount.buildingAmount = this.Building
      this.totals = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure);
      this.awardAmount.totalAmount = this.totals
      this.samp[2].amount = this.awardAmount.buildingAmount
    } else if (val == 'other') {
      this.other = Number(e.target.value)
      this.awardAmount.otherStructure = this.other
      this.totals = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure);
      this.awardAmount.totalAmount = this.totals
      this.samp[3].amount = this.awardAmount.otherStructure
    }

  }
  ngOnInit() {
    this.hamHeader = "Payment status"
    this.subHeader = "Payment  /  Payment status"
    this.forms()
    this.items = [{
      label: 'Details of acquired land',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account Details',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },];
    this.loadPage()
    this.getProjects()
  }
  ngAfterViewInit() {
    this.updateDialogFont();
  }
  forms() {
    this.legalzierForm = this.formBuilder.group({
      AccountDetailsForm: this.formBuilder.array([])
    })
  }
  detailsform(): FormGroup {
    return this.formBuilder.group({
      landOwneName: ['', Validators.required],
      isBank: ['', Validators.required],
      aadhaarNo: ['', Validators.required],
      bankName: ['', Validators.required],
      bankAccountNo: ['', Validators.required],
      ifscCode: ['', Validators.required],
      legalAmount: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      branchName: ['', Validators.required],
      legalPhoto: ['', Validators.required],
      legalDocument: ['', Validators.required],
    })
  }

  removeSkill(i: number) {
    this.detailsOfAccount.removeAt(i);
  }

  get detailsOfAccount(): FormArray {
    return this.legalzierForm.get("AccountDetailsForm") as FormArray
  }

  addLegal() {
    this.detailsOfAccount.push(this.detailsform());
  }
  onSubmit() {
    let beneficiaryStructuredValue = [
      {
        "structuredValueName": "TREE",
        "amount": this.awardAmount.treeAmount
      },
      {
        "structuredValueName": "WELL",
        "amount": this.awardAmount.wellAmount
      },
      {
        "structuredValueName": "BUILDING",
        "amount": this.awardAmount.buildingAmount
      },
      {
        "structuredValueName": "OTHER_STRUCTURES",
        "amount": this.awardAmount.otherStructure
      }
    ]


    let obj = {
      "compensationAmount": this.land,
      "totalAmount": this.totals,
      "beneficiaryStructuredValue": beneficiaryStructuredValue
    }
    this.spinner.show()
    this.agencyService.saveAward(obj, this.selectDatas).subscribe(
      (res) => {
        this.awardsDialog = false
        this.awardAmount = {
          landAmount: '',
          treeAmount: 0,
          wellAmount: 0,
          buildingAmount: 0,
          otherStructure: 0,
          totalAmount: ''
        }
        this.notificationService.alertSuccess('', 'Award Amount details added successfully!!!')
        this.loadPage()
      },
      (err) => {
        this.notificationService.alertError('Error', err.error.error)
        this.spinner.hide()
      }
    )
  }

  ifscCode(e, i) {
    let ifscCode = e.target.value;
    if (ifscCode.length == 11) {
      this.agencyService.getIFSCCode(ifscCode).subscribe(res => {
        this.bankDetails = res.body
        this.detailsOfAccount.controls[i].get('bankName').setValue(this.bankDetails.BANK)
        this.detailsOfAccount.controls[i].get('branchName').setValue(this.bankDetails.BRANCH)
      }, (err) => {
      })
    }

  }

  keyPressAadhaar(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 12) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  getProjects() {
    this.ProjectService.getProjects().subscribe(
      (res: any) => {

        this.projectList = res.body.data;
        // this.state = stateList.filter(res => res.levelTypeDto.id == 1)
        // this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        // console.log(stateList, ' this.stateList');
      },
      () => {
        this.onError();
      }
    );
  }


  loadPage(page?: number, params?): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query
    if (params) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        projectId: this.stage1Filter.projectName ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        name: this.stage1Filter.name ?? '',
        sortBy: params,
        sortDirection: this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        projectId: this.stage1Filter.projectName ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        name: this.stage1Filter.name ?? '',
      };
    }

    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      () => {
        this.onError();
      }
    );
  }



  categories: any[] = [
    { name: 'Yes', key: 'Y' },
    { name: 'No', key: 'N' },

  ];

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.confirmValues = data.data
    this.totalCount = data.totalCount;

    console.log(this.beneficary, ' this.beneficary');

    // this.searchResult.items = data ?? [];
    // this.searchResult = { ...this.searchResult };
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }

  protected onError(): void {
    this.spinner.hide()
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }
  Structure = [
    {
      name: 'TREE'
    },
    {
      name: 'WELL'
    },
    {
      name: 'BUILDINGS'
    },
    {
      name: 'ANY_OTHER_STRUCTURE'
    }
  ]

  getChange(e) {
    if (e != '') {
      this.ProceedingDate = this.Proceeding.date
    } else {
      this.ProceedingDate = this.SelectData.benificiaryProceedingId.proceedingDate
    }
  }
  getAwards(data) {
    this.selectDatas = data.id
    this.awardsDialog = true
    if (data.structuredValue.length != 0) {
      this.disableAward = false
      this.awardAmount.landAmount = data.compensationAmount
      data.structuredValue.forEach(element => {
        if (element.structureValueName == 'TREE') {
          this.awardAmount.treeAmount = element.amount
        } else if (element.structureValueName == 'WELL') {
          this.awardAmount.wellAmount = element.amount
          console.log(this.awardAmount.wellAmount, '1');
        } else if (element.structureValueName == 'BUILDING') {
          this.awardAmount.buildingAmount = element.amount
          console.log(this.awardAmount.buildingAmount, '1');
        } else if (element.structureValueName == 'OTHER_STRUCTURES') {
          this.awardAmount.otherStructure = element.amount
          console.log(this.awardAmount.otherStructure, '1');
        }
      })
      this.awardAmount.totalAmount = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure)
    } else {
      this.disableAward = true
      this.awardAmount = {
        landAmount: '',
        treeAmount: 0,
        wellAmount: 0,
        buildingAmount: 0,
        otherStructure: 0,
        totalAmount: ''
      }
    }
  }

  getValues(eve, i) {
    this.indexValue = i
    let totalAmount: number = 0;
    this.detailsOfAccount.value.forEach((element) => {
      totalAmount += Number(element.legalAmount);
    });
    if (this.selectData.totalAmount == totalAmount) {
      this.disableButton = true;
    } else if (this.selectData.totalAmount < totalAmount) {
      this.disableButton = false;
    } else {
      this.disableButton = false;
    }
  }
  getLegalzier(d) {
    this.forms()
    this.addLegal()
    this.selectData = d
    this.legalzier = true
    this.legalName = ''
    this.ingredient = ''
    this.bank = {
      account: '',
      ifscCode: '',
      bankName: '',
      branch: '',
      aadhaarNo: ''
    }
    this.project.fileUpload = ''
    this.project.fileUpload1 = ''
  }

  openNew(data, beneficaryData?: any) {
    this.label = data
    this.activeIndex = 0;
    this.forms()
    this.addLegal()
    if (data == 'new') {
      this.viewOnly = false
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;

      this.getProjects()
      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.viewOnly = true
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;
      this.getProjects()
      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
      this.agencyService.beneficiary(beneficaryData.id).subscribe(
        (res: HttpResponse<any[]>) => {
          this.singleBeneficiaryList = res.body;
          console.log(this.singleBeneficiaryList);
          this.legalzierForm.patchValue({
            disTrictName: this.singleBeneficiaryList.districtLGDCode.name,
            taluk: this.singleBeneficiaryList.talukLGDCode.name,
            village: this.singleBeneficiaryList.revenueLGDCode.name,
            projectName: this.singleBeneficiaryList.projectID.projectName,
            surveyNo: this.singleBeneficiaryList.surveyNumber,
            landAcquire: this.singleBeneficiaryList.extendToBeAcquired,
            totalCost: this.singleBeneficiaryList.totalCost,
          })
          this.displayItems()
        },
        () => {
          this.onError();
        }
      );

    }
  }

  createItem(item: any) {
    return this.formBuilder.group({
      landOwneName: [item.benificiaryLandOwnerName, [Validators.required]],
      aadhaarNo: [item.aadharNumber, [Validators.required]],
      mobileNumber: [item.mobileNumber, [Validators.required]],
      accountHolderName: [item.nameOfAccountHolder, [Validators.required]],
      bankName: [item.nameOfBank, [Validators.required]],
      bankAccountNo: [item.accountNumber, [Validators.required]],
      ifscCode: [item.ifscCode, [Validators.required]],
      branchName: [item.branchName, [Validators.required]],
      amount: [item.compensationAmount, [Validators.required]],
    });
  }



  displayItems() {
    let transformedItems = this.singleBeneficiaryList.landOwners.map((item: any) =>
      this.createItem(item)
    );
    this.legalzierForm.setControl('AccountDetailsForm', this.formBuilder.array(transformedItems));
    this.legalzierForm.disable()
  }


  onSelectDistrict(e) {
    this.agencyService.talukAndVillages(e.id, 3).subscribe(res => {
      this.taluk = res
    })
    this.legalzierForm.get('taluk').setValue('')
    this.legalzierForm.get('village').setValue('')

  }
  getValue(e, i) {
    console.log(e.key);

    if (e.key == 'Y') {
      this.detailsOfAccount.controls[i].get('bankAccountNo').setValue(this.selectData.accountNumber)
      this.detailsOfAccount.controls[i].get('ifscCode').setValue(this.selectData.ifscCode)
      this.detailsOfAccount.controls[i].get('bankName').setValue(this.selectData.nameOfBank)
      this.detailsOfAccount.controls[i].get('branchName').setValue(this.selectData.branchName)
      this.detailsOfAccount.controls[i].get('aadhaarNo').setValue(this.selectData.aadharNumber)
      this.detailsOfAccount.controls[i].get('mobileNumber').setValue(this.selectData.mobileNumber)

    } else {
      this.isFile = false
      this.detailsOfAccount.controls[i].get('bankAccountNo').setValue('')
      this.detailsOfAccount.controls[i].get('ifscCode').setValue('')
      this.detailsOfAccount.controls[i].get('bankName').setValue('')
      this.detailsOfAccount.controls[i].get('branchName').setValue('')
      this.detailsOfAccount.controls[i].get('aadhaarNo').setValue('')
      this.detailsOfAccount.controls[i].get('mobileNumber').setValue('')

    }

  }

  viewDocument(data) {
    this.spinner.show()
    this.agencyService.viewimage(data).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    }, (err) => {
      this.spinner.hide()
    });
  }

  viewLegalHeir(data) {
    this.legalHeir = data
    this.viewListForLegal = true

  }
  viewDocumentForlist(image) {
    this.spinner.show()
    this.agencyService.viewimage(image).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExtList = filename.split('.').pop();
      this.vFileUrlList =
        'data:image/' + this.vFileExtList + ';base64,' + res.body.fileType;
      console.log(this.vFileUrlList, 'this.vFileUrlList');
      // Replace with your Base64-encoded image string
      this.viewDocList = true;
    }, (err) => {
      this.spinner.hide()
    });
  }
  viewDocument1(data) {
    this.spinner.show()
    this.agencyService.viewimage(data).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExt1 = filename.split('.').pop();
      this.vFileUrl1 =
        'data:image/' + this.vFileExt1 + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc1 = true;
    }, (err) => {
      this.spinner.hide()
    });
  }

  onUploadfile1($event?, label?, index?) {
    if (label == 'photo') {
      const formData = new FormData();
      const i = $event.files.length - 1;
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
      this.agencyService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
          this.notificationService.alertSuccess('Image Upload SuccessFully', '');
          console.log(res.body);
          this.fileUpload = res.body.fileDownloadUri;
          this.stage1.photo = res.body.fileName
          this.isFile = true;
        },
        (onerr) => { }
      );
    } else {
      const formData = new FormData();
      const i = $event.files.length - 1;
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
      this.agencyService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
          this.notificationService.alertSuccess('Image Upload SuccessFully', '');
          console.log(res.body);
          this.fileUpload1 = res.body.fileDownloadUri;
          this.stage1.photo = res.body.fileName
          this.isFile1 = true;
        },
        (onerr) => { }
      );
    }
  }
  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onUploadfile($event?, label?, index?) {
    if (label == 'photo') {
      const formData = new FormData();
      const i = $event.files.length - 1;
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
      this.agencyService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
          this.notificationService.alertSuccess('Image Upload SuccessFully', '');
          this.fileUpload = res.body.fileDownloadUri;
          this.detailsOfAccount.controls[index].get('legalPhoto').setValue(res.body.fileName);
          // this.stage1.photo = res.body.fileName
          this.isFile = true;
        },
        (onerr) => { }
      );
    } else {
      const formData = new FormData();
      const i = $event.files.length - 1;
      const fileData = new Blob([$event.files[i]], {
        type: $event.files[i].type,
      });
      if ($event.files[i].type === 'application/pdf') {
        formData.append('file', fileData, `.pdf`);
      } else if ($event.files[i].type === 'image/png') {
        formData.append('file', fileData, `.png`);
      } else if ($event.files[i].type === 'image/jpeg') {
        formData.append('file', fileData, `.jpeg`);
      } else {
        formData.append('file', fileData, `.jpg`);
      }
      this.agencyService.uploadImage(formData).subscribe(
        (res: HttpResponse<any>) => {
          this.notificationService.alertSuccess('Image Upload SuccessFully', '');
          console.log(res.body);
          this.fileUpload1 = res.body.fileDownloadUri;
          // this.stage1.photo = res.body.fileName
          this.detailsOfAccount.controls[index].get('legalDocument').setValue(res.body.fileName);
          this.isFile1 = true;
        },
        (onerr) => { }
      );
    }
  }
  edit(data) {
    let obj = {
      beneficiaryId: data.id,
    }
    this.spinner.show()
    this.agencyService.validation(obj).subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide()
        this.SelectData = data
        this.stage1.id = this.SelectData.id
        this.stage1.aadharNumber = this.SelectData.aadharNumber
        this.stage1.accountNumber = this.SelectData.accountNumber
        this.stage1.benificiaryLandOwnerName = this.SelectData.benificiaryLandOwnerName
        this.stage1.id = this.SelectData.id
        this.stage1.ifscCode = this.SelectData.ifscCode
        this.stage1.mobileNumber = this.SelectData.mobileNumber
        this.stage1.photo = this.SelectData.photo
        this.editDialog = true
        if (data.benificiaryProceedingId != null) {
          this.isProceeding = true
          this.Proceeding.Proceeding = this.SelectData.benificiaryProceedingId.proceedingNumber,
            this.Proceeding.id = this.SelectData.benificiaryProceedingId.id
          this.Proceeding.date = this.datepipe.transform(
            this.SelectData.benificiaryProceedingId.proceedingDate,
            'dd/MM/YYYY'
          )
          this.ProceedingDate = this.SelectData.benificiaryProceedingId.proceedingDate
        } else {
          this.isProceeding = false
          this.Proceeding.Proceeding = '',
            this.Proceeding.date = '',
            this.Proceeding.id = ''
        }
        if (data.structuredValue.length != 0) {
          this.isStructure = true
          this.awardAmount.landAmount = data.compensationAmount
          data.structuredValue.forEach(element => {
            if (element.structureValueName == 'TREE') {
              this.awardAmount.treeAmount = element.amount
              this.samp[0].amount = this.awardAmount.treeAmount
              this.samp[0].beneficiaryStructureValueId = element.id
              this.samp[0].structuredValueName = element.structureValueName
            } else if (element.structureValueName == 'WELL') {
              this.awardAmount.wellAmount = element.amount
              this.samp[1].amount = this.awardAmount.wellAmount
              this.samp[1].beneficiaryStructureValueId = element.id
              this.samp[1].structuredValueName = element.structureValueName
            } else if (element.structureValueName == 'BUILDING') {
              this.awardAmount.buildingAmount = element.amount
              this.samp[2].amount = this.awardAmount.buildingAmount
              this.samp[2].beneficiaryStructureValueId = element.id
              this.samp[2].structuredValueName = element.structureValueName
            } else if (element.structureValueName == 'OTHER_STRUCTURES') {
              this.awardAmount.otherStructure = element.amount
              this.samp[3].amount = this.awardAmount.otherStructure
              this.samp[3].beneficiaryStructureValueId = element.id
              this.samp[3].structuredValueName = element.structureValueName
            }
          })
          this.awardAmount.totalAmount = Number(this.awardAmount.landAmount) + Number(this.awardAmount.treeAmount) + Number(this.awardAmount.wellAmount) + Number(this.awardAmount.buildingAmount) + Number(this.awardAmount.otherStructure)
        } else {
          this.isStructure = false
          this.disableAward = true
          this.awardAmount = {
            landAmount: '',
            treeAmount: 0,
            wellAmount: 0,
            buildingAmount: 0,
            otherStructure: 0,
            totalAmount: ''
          }
        }
      }, (err) => {
        this.spinner.hide()
        this.notificationService.alertError('Error!', err.error.error);
        this.agencyDialog = false
      })

  }
  onSubmitEdit() {
    let obj
    if (this.isProceeding && this.isProceeding) {
      let proceed = {
        "proceedingId": this.Proceeding.id,
        "proceedingNumber": this.Proceeding.Proceeding,
        "proceedingDate": this.ProceedingDate
      }
      obj = [{
        "beneficiaryLandOwnerId": this.stage1.id,
        "benificiaryLandOwnerName": this.stage1.benificiaryLandOwnerName,
        "mobileNumber": this.stage1.mobileNumber,
        "aadharNumber": this.stage1.aadharNumber,
        "ifscCode": this.stage1.ifscCode,
        "accountNumber": this.stage1.accountNumber,
        "photo": this.stage1.photo,
        'beneficiaryStructuredValue': this.samp,
        'beneficiaryProceeding': proceed
      }]
    } else if (this.isProceeding) {
      let proceed = {
        "proceedingId": this.Proceeding.id,
        "proceedingNumber": this.Proceeding.Proceeding,
        "proceedingDate": this.ProceedingDate
      }
      obj = [{
        "beneficiaryLandOwnerId": this.stage1.id,
        "benificiaryLandOwnerName": this.stage1.benificiaryLandOwnerName,
        "mobileNumber": this.stage1.mobileNumber,
        "aadharNumber": this.stage1.aadharNumber,
        "ifscCode": this.stage1.ifscCode,
        "accountNumber": this.stage1.accountNumber,
        "photo": this.stage1.photo,
        'beneficiaryProceeding': proceed
      }]
    } else if (this.isStructure) {
      obj = [{
        "beneficiaryLandOwnerId": this.stage1.id,
        "benificiaryLandOwnerName": this.stage1.benificiaryLandOwnerName,
        "mobileNumber": this.stage1.mobileNumber,
        "aadharNumber": this.stage1.aadharNumber,
        "ifscCode": this.stage1.ifscCode,
        "accountNumber": this.stage1.accountNumber,
        "photo": this.stage1.photo,
        'beneficiaryStructuredValue': this.samp,
      }]
    } else {
      obj = [{
        "beneficiaryLandOwnerId": this.stage1.id,
        "benificiaryLandOwnerName": this.stage1.benificiaryLandOwnerName,
        "mobileNumber": this.stage1.mobileNumber,
        "aadharNumber": this.stage1.aadharNumber,
        "ifscCode": this.stage1.ifscCode,
        "accountNumber": this.stage1.accountNumber,
        "photo": this.stage1.photo,
      }]
    }
    this.spinner.show()
    this.agencyService.updateBeneficiary(obj).subscribe(
      (res: HttpResponse<[]>) => {
        this.spinner.hide()
        this.editDialog = false
        this.notificationService.alertSuccess("Edit", 'Edited Successfully!!!');
        this.loadPage()
      }, (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide()
      })
  }
  onSubmitLegal() {
    this.tempLegalArr = []
    let form = this.legalzierForm.value;
    form.AccountDetailsForm.forEach(element => {
      let obj = {
        "legalierName": element.landOwneName,
        "legalierPhoto": element.legalPhoto,
        "aadharNumber": element.aadhaarNo,
        "bankName": element.bankName,
        "accountNumber": element.bankAccountNo,
        "branchName": element.branchName,
        "ifscCode": element.ifscCode,
        "legalHierAmount": element.legalAmount,
        "legalPhoneNo": element.mobileNumber,
        "legalierDocument": element.legalDocument
      }
      this.tempLegalArr.push(obj)
    });
    this.spinner.show()
    this.agencyService.saveLegal(this.tempLegalArr, this.selectData.id).subscribe(
      (res) => {
        this.legalzier = false
        this.spinner.hide()
        this.legalName = ''
        this.ingredient = ''
        this.project.fileUpload = ''
        this.project.fileUpload1 = ''
        this.loadPage()

      })
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  OnSelectTaluk(e) {
    this.agencyService.talukAndVillages(e.id, 4).subscribe(res => {
      this.villages = res
    })
    this.legalzierForm.get('village').setValue('')

  }
  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(item => item.name === 'State' || item.name === 'District');
      },
      () => { }
    );
  }
  onProjectChange(e) {
    let districtList = e.projectLocations
    this.splitDistirct = []
    districtList.forEach(element => {
      this.splitDistirct.push(element.districtLGDCode)
    });
    this.legalzierForm.get('disTrictName').setValue('')
    this.legalzierForm.get('taluk').setValue('')
    this.legalzierForm.get('village').setValue('')
  }


  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    this.viewOnly = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.beneficiary.mobile
    );
    !this.beneficiary.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.beneficiary.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.beneficiary.email);
    !this.beneficiary.email ? (this.emailError = false) : true;
    return !this.emailError && !!this.beneficiary.email;
  }
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var charLength = event.target.value.length
    console.log(charLength);

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      if (charLength == 10) {
        event.preventDefault();
        return true;
      }
    }
  }

  validateIfsc() {
    if (this.beneficiary.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.beneficiary.ifscCode
      );

      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.beneficiary.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.beneficiary.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  validateBemail() {
    if (this.beneficiary.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.beneficiary.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }

  saveAgency() {

    let beneficaryData = this.legalzierForm.value

    console.log(beneficaryData, 'beneficaryData');


    // const isFormValid =
    // this.validateMobile() &&
    // // this.validateEmail() &&
    // this.validateIfsc()
    // this.validateBmobile() &&
    // this.validateBemail()
    // this.agency.departmentDto &&
    // this.agency.schemeDto &&
    // this.agency.name &&
    // this.agency.email &&
    // this.agency.gstNo &&
    // this.levelMasterDto;



    // if (!isFormValid) {
    //   this.ngSelectInvalid = true;
    //   return;
    // }
    // if (
    //   this.showNameError ||
    //   this.showMobileNoError ||
    //   this.showEmailUniError ||
    //   this.gstUniError
    // ) {
    //   return;
    // }

    let landFrom = []
    beneficaryData.AccountDetailsForm.forEach(element => {
      let data = {
        "benificiaryLandOwnerName": element.landOwneName,
        "aadharNumber": element.aadhaarNo,
        "mobileNumber": element.mobileNumber,
        "nameOfAccountHolder": element.accountHolderName,
        "nameOfBank": element.bankName,
        "accountNumber": element.bankAccountNo,
        "branchName": element.branchName,
        "ifscCode": element.ifscCode,
        "compensationAmount": element.amount,
      }
      landFrom.push(data)
    });

    let Payload = {
      "projectID": beneficaryData.projectName.id,
      "districtLGDCode": beneficaryData.disTrictName.id,
      "talukLGDCode": beneficaryData.taluk.id,
      "revenueLGDCode": beneficaryData.village.id,
      "surveyNumber": beneficaryData.surveyNo,
      "totalCost": beneficaryData.totalCost,
      "extendToBeAcquired": beneficaryData.landAcquire,
      "benificiardLandOwnerDto": landFrom
    }

    this.ngSelectInvalid = false;
    this.agency.leveltype = this.levelTypeDto?.id;
    this.agency.levelMaster = this.levelMasterDto?.id;
    this.spinner.show()
    this.agencyService.save(Payload).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.spinner.hide()

        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  removeDocuments(data) {
    this.agencyService.deleteimage(data).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
        this.stage1.photo = ''
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }
  removeDocument(data, i) {
    this.agencyService.deleteimage(data).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.detailsOfAccount.controls[i].get('legalPhoto').setValue('')
        this.isFile = false;
        this.stage1.photo = ''
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  removeDocument1(data, i) {
    this.agencyService.deleteimage(data).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile1 = false;
        this.detailsOfAccount.controls[i].get('legalDocument').setValue('')
        this.project.fileUpload1 = ''
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }
  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  // onSelectRow($event) {
  //   if ($event.type === 'select' || $event.type === 'view') {
  //     if($event.type === 'view'){
  //       this.viewOnly=true;
  //     }
  //     this.agencyService.getAgency($event.value.id).subscribe(
  //       (res: HttpResponse<any>) => {
  //         this.agency = res.body;
  //         this.agencyDialog = true;
  //         this.departmentListService.filter().subscribe(
  //           (res: HttpResponse<DepartmentList[]>) => {
  //             this.departmentList = res.body;
  //           },
  //           () => {
  //             this.onError();
  //           }
  //         );
  //         this.onchangeSchema();
  //       },
  //       () => {
  //         this.onError();
  //       }
  //     );
  //   } else {
  //     this.agencyService.delete($event.value.id).subscribe(
  //       (res: HttpResponse<any>) => {
  //         // this.loadPage(this.page);
  //       },
  //       () => {
  //         this.onError();
  //       }
  //     );
  //   }
  // }

}

