<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
          <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.personal.name">
            Personal Vendors
          </h5>
        </ng-template>
        <ng-template pTemplate="right">
          <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button"
            (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div #customerTable id="entities" class="col-12 px-4">
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)"
      [disableDeleteIcon]="true" [disableEditIcon]="false" (emitRow)="onSelectRow($event)"></custom-filter-table>
  </div>
  <p-dialog #dialog [(visible)]="personalVendorsDialog" [style]="{ width: '850px' }" [modal]="true"
    (onHide)="hideDialog()" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
      {{ "vendor.personal.add" | translate }}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">
          <div class="row">
            <div class="row gy-2">
              <div class="col-12">
                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.personal.details">
                  Personal Vendor Details
                </h6>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name"
                  for=" name">Name</label>
                <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="personalVendor.name"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.name }" class="form-control"
                  maxlength="60"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="30" uppercase
                  (ngModelChange)="checkName()" />
                <div *ngIf="showNameError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{showNameError}}</small>
                </div>
              </div>
              <!-- <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    jhiTranslate="vendor.companyName" for=" companyName">Company Name</label>
                                <input type="text" class="form-control" name="companyName"
                                    [(ngModel)]="personalVendor.companyName" id="companyName"
                                    [ngClass]="{'is-invalid': ngSelectInvalid}" />
                            </div> -->
              <div class="col-sm-6 col-md-4">

                <label for=" designation" [ngClass]="fontService.labelClass" jhiTranslate="vendor.designation"
                  class="form-control-label req">Designation</label>
                <input type="text" id="designation" autocomplete="off" name="companyName"
                  [(ngModel)]="personalVendor.designation"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.designation }" class="form-control"
                  maxlength="50" />

              </div>
              <!-- <div class="col-sm-6 col-md-4">
                <label for=" staffId" [ngClass]="fontService.labelClass" jhiTranslate="vendor.staff.id"
                  class="form-control-label req">Staff Id</label>
                <input type="text" id="registrationNo" name="registrationNo" autocomplete="off" [(ngModel)]="personalVendor.staffId"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.staffId }" class="form-control"
                  maxlength="30" (ngModelChange)="checkstaffId()" />
                  <p *ngIf="showStaffError" style="font-size: 14px; color: #ff0000">
                    {{showStaffError }}
                  </p>
              </div> -->
              <div class="col-sm-6 col-md-4">
                <label for=" mobileNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.mobile"
                  class="form-control-label req">Mobile No</label>
                <input type="text" id="mobileNo" name="mobileNo" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" [(ngModel)]="personalVendor.mobile"
                  [ngClass]="{ 'is-invalid': !personalVendor.mobile && ngSelectInvalid }" (input)="validateMobile()"
                  class="form-control" maxlength="10" (ngModelChange)="checkmobile()" />
                <div *ngIf="showMobileError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                    Number.</small>
                </div>
                <div *ngIf="showMobileUniError" class="text-danger">
                  <small [ngClass]="fontService.smallClass"
                    jhiTranslate="error.showMobileUniError">{{showMobileUniError}}</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <label for=" email" [ngClass]="fontService.labelClass" jhiTranslate="vendor.email"
                  class="form-control-label">E-mail</label>
                <input type="text" id="email" name="email" autocomplete="off" [(ngModel)]="personalVendor.email"
                  (input)="validateEmail()" class="form-control" maxlength="60" onblur="this.value =this.value.trim();"
                  (ngModelChange)="checkemail()" />
                <div *ngIf="emailError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                    Address.</small>
                </div>
                <div *ngIf="emailUniError" class="text-danger">
                  <small [ngClass]="fontService.smallClass"
                    jhiTranslate="error.showEmailUniError">{{emailUniError}}</small>
                </div>
              </div>

              <div class="col-sm-6 col-md-4">
                <label for=" aadhaar" [ngClass]="fontService.labelClass" jhiTranslate="vendor.aadhaarno"
                  class="form-control-label req">Aadhaar Number</label>
                <input type="text" name="aadhaar" autocomplete="off" id="aadhaar"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  onblur="this.value =this.value.trim();" [(ngModel)]="personalVendor.aadhaarNo"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.aadhaarNo }" (input)="validateAadhaar()"
                  class="form-control" maxlength="12" (ngModelChange)="checkaadharNo()" />
                <div *ngIf="aadhaarError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter a valid
                    Aadhaar Number.</small>
                </div>
                <div *ngIf="aadhaarError" class="text-danger">
                  <small [ngClass]="fontService.smallClass"
                    jhiTranslate="error.aadhaarNoUniError">{{aadhaarNoUniError}}</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <label for=" panNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.panno"
                  class="form-control-label">PAN No</label>
                <input type="text" id="panNo" uppercase name="panNo" autocomplete="off"
                  [(ngModel)]="personalVendor.panNo" (input)="validatePan()" class="form-control" maxlength="10"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" (ngModelChange)="checkPanNo()" />
                <div *ngIf="panError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a valid PAN
                    Number.</small>
                </div>
                <div *ngIf="panNoUniError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">{{panNoUniError}}</small>
                </div>
              </div>
            </div>
            <div class="row gy-2 mt-3">
              <div class="col-12">
                <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.parmenent">
                  Permenent Address
                </h6>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.buildingno">Building No/Door No</label>
                <input type="text" id="doorNo" name="doorNo" autocomplete="off" (change)="addresschange('check')"
                  [(ngModel)]="personalVendor.doorNo"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.doorNo }" class="form-control"
                  maxlength="20" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" street" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.street">Street</label>
                <input type="text" class="form-control" (change)="addresschange('check')" name="street" id="street"
                  [(ngModel)]="personalVendor.street" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.street }" maxlength="50" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label" for=" landmark" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.landmark">Landmark</label>
                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')"
                  name="landmark"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [(ngModel)]="personalVendor.area" id="landmark" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" city" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.city">City</label>
                <input type="text" class="form-control" autocomplete="off" name="city" (change)="addresschange('check')"
                  id="city" [(ngModel)]="personalVendor.city"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.city }" maxlength="20" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" district" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.district">District</label>
                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')"
                  name="district" [(ngModel)]="personalVendor.district" id="district"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.district }" maxlength="20" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" state" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.state">State</label>
                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')"
                  name="state" id="state" [(ngModel)]="personalVendor.state"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.state }" maxlength="50" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" pinCode" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.pincode">Pincode</label>
                <input type="text" class="form-control" autocomplete="off" name="pinCode"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  (change)="addresschange('check')" id="pinCode" [(ngModel)]="personalVendor.pinCode"
                  (input)="validatePin()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.pinCode }"
                  maxlength="6" />
                <div *ngIf="pinError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                </div>
              </div>
            </div>
            <!-- <div class="row gy-2 mt-3">
           
            </div> -->
            <div class="row gy-2 mt-3">
              <div class="col-12">
                <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.commercial">
                  Commercial Address
                </h6>
              </div>
              <div class="col-12">
                <span>
                  <p-checkbox (onChange)="addresschange()" [(ngModel)]="checked" binary="true"></p-checkbox>
                  <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass"
                    jhiTranslate="error.sameAs">Same as Permanent Address</label>
                </span>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.buildingno">Building No/Door No</label>
                <input type="text" class="form-control" name="doorNo" autocomplete="off" id="doorNo"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [(ngModel)]="personalVendor.companyDoorNo"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyDoorNo }" maxlength="50" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street"
                  for=" street">Street</label>
                <input type="text" class="form-control" autocomplete="off" name="street" id="street"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [(ngModel)]="personalVendor.companyStreet"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyStreet }"
                  (input)="ngSelectInvalid = !personalVendor.companyStreet ? true : false" maxlength="50" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark"
                  for=" landmark">Landmark</label>
                <input type="text" class="form-control" name="landmark" autocomplete="off" id="landmark"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="50"
                  [(ngModel)]="personalVendor.companyArea" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city"
                  for=" city">City</label>
                <input type="text" class="form-control" name="city" autocomplete="off" id="city"
                  [(ngModel)]="personalVendor.companyCity"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyCity }" maxlength="50" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district"
                  for=" district">District</label>
                <input type="text" class="form-control" name="district" autocomplete="off" id="district"
                  [(ngModel)]="personalVendor.companyDistrict"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyDistrict }" maxlength="20" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state"
                  for=" state">State</label>
                <input type="text" class="form-control" name="state" autocomplete="off"
                  [(ngModel)]="personalVendor.companyState"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase" id="state"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyState }" maxlength="20" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode"
                  for=" pinCode">Pincode</label>
                <input type="text" class="form-control" name="pinCode" autocomplete="off"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                  [(ngModel)]="personalVendor.companyPinCode" id="pinCode"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.companyPinCode }" maxlength="6"
                  (input)="validateCPin()" />
                <div *ngIf="cPinError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                </div>
              </div>
            </div>
            <div class="row gy-2 mt-3">
              <div class="col-12">
                <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                  Vendor Account Details
                </h6>
              </div>
              <div class="col-sm-6 col-md-4">
                <div class="col-sm-6 col-md-4">
                  <label class="form-control-label req" [ngClass]="fontService.labelClass"
                    jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                  <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline"
                    [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="readonly"
                    (change)="getBankBranchList()" [items]="bankList" [(ngModel)]="personalVendor.bankName"
                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.bankName }">
                  </ng-select>

                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                  jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline"
                  [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="readonly"
                  (search)="getBankBranchList($event)" [items]="bankBranchList" [(ngModel)]="bankBranch"
                  (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.bankBranch}">
                </ng-select>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode"
                  for=" ifsc">IFSC Code</label>
                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11"
                  oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" uppercase [readonly]="readonly"
                  [(ngModel)]="personalVendor.ifscCode" id="ifsc"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.ifscCode }" maxlength="11"
                  (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                <div *ngIf="ifscError" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno"
                  for=" accountNo">Account Number</label>
                <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="17"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="readonly"
                  [(ngModel)]="personalVendor.accountNumber" id="accountNo" (change)="accountDetailsChanged()"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.accountNumber }" />
                <div *ngIf="accountNoUniError" class="text-danger">
                  <small [ngClass]="fontService.smallClass"
                    jhiTranslate="error.accountNoUniError">{{accountNoUniError}}</small>
                </div>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                  jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                  Passbook</label>
                <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60"
                  oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="readonly"
                  [(ngModel)]="personalVendor.bankAccName" id="bankAccountName"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !personalVendor.bankAccName }" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr"
                  for=" micr">MICR</label>
                <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly"
                  [(ngModel)]="personalVendor.micr" maxlength="9" id="micr" />
              </div>
              <div class="col-12 justify-content-center">
                <button pButton pRipple class="p-button-success custom-button" [disabled]="readonly"
                  (click)="accountValidation()" styleClass="button">
                  <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.verifyaccount">Verify Account</span>
                </button>
                <div *ngIf="accountInvalidError" class="text-danger">
                  <small [ngClass]="fontService.smallClass">{{"error.accountinvaliderror" | translate}}</small>
                </div>
                <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                  <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the
                    account</small>
                </div>
              </div>

              <!-- </div>
            <div class="row mt-3"> -->

            </div>

            <!-- </div>
            <div class="row mt-3"> -->

          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>
      <button *ngIf="!personalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
        (click)="savePersonalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
      </button>
      <button *ngIf="personalVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
        (click)="savePersonalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>