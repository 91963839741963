<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Agency</h2>
</div>
<div class="container bg">
    <form [formGroup]="agencyForm">
        <div class="col-md-12">
            <div class="form-inner-panel" novalidate>
                <div class="row gy-2">
                    <div class="col-md-12">
                        <h6 [ngClass]="fontService.headingClass" class="">
                            Requesting Agency Details
                        </h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            jhiTranslate="agency.name" for="agencyName">Name</label>
                        <input type="text" id="agencyName" name="agencyName" maxlength="100" autocomplete="off"
                            [disabled]="viewOnly" formControlName="name" onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase" class="form-control" />

                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Government
                            Type</label>
                        <ng-select id="department" [items]="govermentType" bindLabel="name" appearance="outline"
                            [closeOnSelect]="true" [clearable]="true" class="form-control"
                            formControlName="govermentType" [disabled]="viewOnly">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label" [ngClass]="fontService.labelClass" for="designaiton">Address
                            1</label>
                        <input type="text" id="designaiton" formControlName="address1" name="designaiton"
                            class="form-control" maxlength="50" [disabled]="viewOnly" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label" [ngClass]="fontService.labelClass" for="designaiton">Address
                            2</label>
                        <input type="text" id="designaiton" name="designaiton" [disabled]="viewOnly"
                            formControlName="address2" class="form-control" maxlength="50" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label" [ngClass]="fontService.labelClass"
                            for="designaiton">Street</label>
                        <input type="text" id="designaiton" name="designaiton" [disabled]="viewOnly"
                            class="form-control" formControlName="street" maxlength="50" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            for="designaiton">City</label>
                        <input type="text" id="designaiton" name="designaiton" [disabled]="viewOnly"
                            class="form-control" formControlName="city" maxlength="50" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            for="levelMasterName">District Name</label>
                        <input type="text" name="designaiton" [disabled]="viewOnly" class="form-control"
                            formControlName="districtName" maxlength="50" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            for="levelName">State</label>
                        <ng-select id="scheme" formControlName="state" [items]="state" bindLabel="name"
                            appearance="outline" [disabled]="viewOnly" [closeOnSelect]="true" [clearable]="true"
                            class="form-control">
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            for="designaiton">Pincode</label>
                        <input type="text" id="designaiton" formControlName="pincode" name="designaiton"
                            [disabled]="viewOnly" class="form-control" minlength='6'
                            (keypress)="keyPressNumberForPincode($event)" />
                    </div>
                </div>
                <div class="row gy-2">
                    <div class="col-md-12">
                        <h6 [ngClass]="fontService.headingClass" class="mt-3">
                            Authorized Person Details
                        </h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            jhiTranslate="agency.name" for="agencyName">Agency Name</label>
                        <input type="text" id="agencyName" name="agencyName" maxlength="100" autocomplete="off"
                            [disabled]="viewOnly" onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase" formControlName="nameAuth" class="form-control" />

                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            jhiTranslate="user.mobile.nunmber" for="mobileNo">Mobile No</label>
                        <input type="text" id="mobileNo" class="form-control" name="mobileNo" autocomplete="off"
                            formControlName="mobile" (keypress)="keyPressNumberForMobile($event)"
                            (input)="validateMobile()" (ngModelChange)="checkmobile()" minlength="10" />
                        <!-- 
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile Number.</small>
                            </div> -->
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            jhiTranslate="user.email" for="email">Email</label>
                        <input type="text" id="email" name="email" class="form-control" [disabled]="viewOnly"
                            formControlName="email" maxlength="75" autocomplete="off" (ngModelChange)="checkemail()" />

                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            jhiTranslate="user.designaiton" for="designaiton">Designaiton</label>
                        <input type="text" id="designaiton" name="designaiton" class="form-control" maxlength="50"
                            [disabled]="viewOnly" formControlName="designation" onblur="this.value =this.value.trim();"
                            style="text-transform:uppercase" autocomplete="off" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label " [ngClass]="fontService.labelClass" for="mobileNo">Office
                            Mobile No</label>
                        <input type="number" id="mobileNo" class="form-control" name="mobileNo" autocomplete="off"
                            [disabled]="viewOnly" formControlName="OfficeMobile" />

                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label" [ngClass]="fontService.labelClass" for="email">Office
                            Email</label>
                        <input type="email" id="email" [disabled]="viewOnly" name="email" class="form-control"
                            formControlName="OfficeEmail" onblur="this.value =this.value.trim();" maxlength="75"
                            autocomplete="off" (ngModelChange)="checkemail()" />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!viewOnly" class="mt-3">
            <button pButton pRipple icon="pi pi-times" iconPos="left" class="p-button-text custom-button cancelButton"
                routerLink="/agency">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.cancelbutton"></span>
            </button> &nbsp;
            <button pButton pRipple  icon="pi pi-check" class="p-button-text custom-button saveButton"
                (click)="saveAgency()" type="submit" [disabled]="agencyForm.invalid">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
            </button>
           
        </div>
    </form>


    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>