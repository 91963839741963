import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor( private http: HttpClient) { }
  protected getUser = environment.SERVER_API_URL + 'api/validateUser';
  protected changePassword = environment.SERVER_API_URL + 'api/forgetPassword';
  protected validateForgetOtpUrl  = environment.SERVER_API_URL + 'api/validateForgetOtp';
  protected getOtp = environment.SERVER_API_URL + 'api/forgotOtp';
 
  getUserDetails(user: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.getUser}?username=${user}`,[], { observe: 'response' });
  }

  getUserOtp(user: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getOtp}?username=${user}`, { observe: 'response' });
  }

  validateOtp(userid:string,otp:any,id): any {
    return this.http.post(`${this.validateForgetOtpUrl}?otp=${otp}&id=${userid}&OtpId=${id}`,{}, { responseType: 'text' });
  }

  setPassword(id:string,password:string){
    return this.http.get<any>(`${this.changePassword}?id=${id}&password=${password}`, { observe: 'response' });
  }
  

}
