import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected getRequestingDepartment = environment.SERVER_API_URL + 'api/dashboard/getRequestingDepartments';
  protected getExtendLand = environment.SERVER_API_URL + 'api/dashboard/getAcquiredLandByProjects';
  protected getMajorSpentUrl = environment.SERVER_API_URL + 'api/dashboard/getAmountDetails';
  protected getAmountSpents = environment.SERVER_API_URL + 'api/dashboard/getTotalAmountSpent';
  protected getAmountBalance = environment.SERVER_API_URL + 'api/dashboard/getTotalBalanceAmount';

  protected getProjectValues = environment.SERVER_API_URL + 'api/dashboard/getTotalProjectValue';
  protected getDistrict = environment.SERVER_API_URL + 'api/dashboard/getDistrictDetails';
  protected getProjects = environment.SERVER_API_URL + 'api/dashboard/getDistrictByProjects';
  protected getCompFundByLevelUrl = environment.SERVER_API_URL + 'api/getCompFundByLevel';
  protected getAmountReceiveValue = environment.SERVER_API_URL + 'api/dashboard/getTotalAmountReceived';
  protected getProjectList = environment.SERVER_API_URL + 'api/dashboard/getMonthWiseProjects';
  protected getPanchayatFundReportUrl = environment.SERVER_API_URL + 'api/getFundDetailsByLevelMaster';
  protected getMajorCompSpentUrl = environment.SERVER_API_URL + 'api/getMajorCompSpentByLevel';
  filterProps: any;
  constructor(protected http: HttpClient) { }
  getFundReport() {
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, { observe: 'response' });
  }
  getSpentAmout() {
    return this.http.get<any>(
      `${this.getAmountSpents}`, { observe: 'response' });
  }
  getAmountBalances() {
    return this.http.get<any>(
      `${this.getAmountBalance}`, { observe: 'response' });
  }
  getProjectValue() {
    return this.http.get<any>(
      `${this.getProjectValues}`, { observe: 'response' });
  }
  getAmountReceive() {
    return this.http.get<any>(
      `${this.getAmountReceiveValue}`, { observe: 'response' });
  }
  getProjectsss() {
    return this.http.get<any>(
      `${this.getProjectList}`, { observe: 'response' });
  }
  getRequestingDepartmet() {
    return this.http.get<any>(
      `${this.getRequestingDepartment}`, { observe: 'response' });
  }
  getExtendLands() {
    return this.http.get<any>(
      `${this.getExtendLand}`, { observe: 'response' });
  }
  getRequestingDepartments() {
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, { observe: 'response' });
  }


  getDistricts() {
    return this.http.get<any>(
      `${this.getDistrict}`, { observe: 'response' });
  }
  getProjectsByDistrict(id) {
    return this.http.get<any>(
      `${this.getProjects}?districtId=${id}`, { observe: 'response' });
  }

  getFunddetails(levelMasterId: number) {
    return this.http.get<any>(
      `${this.getPanchayatFundReportUrl}?levelMasterId=${levelMasterId}`,
      { observe: 'response' }
    );
  }
  getCompFundByLevel(levelId, parentId, compTypeId) {
    return this.http.get<any>(
      `${this.getCompFundByLevelUrl}?levelId=${levelId}&parentId=${parentId}&compTypeId=${compTypeId}`,
      { observe: 'response' }
    );
  }


}