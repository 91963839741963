import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<any[]>;
export type EntityResponseType = HttpResponse<any>;
export type EntityschemeResponseType = HttpResponse<any>;
@Injectable(
  {
    providedIn:'root'
  }
)
export class SchemeListService {
  protected resourceFliterUrl = environment.SERVER_API_URL + ('api/schemeList');
  protected SchemefilterIAUrl = environment.SERVER_API_URL + ('api/getDashBoardSchemeOnIAID?');
  protected resourceVaildFliterUrl = environment.SERVER_API_URL + ('api/getSchemeValidByFilter');
  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/saveScheme');
 // http://localhost:8088/api/getDashBoardSchemeBasedOnIAID?finYear=2023-2024

//https://sdatapi.demodev.in/api/getDashBoardSchemeBasedOnIAID?finYear=2023-2024
  constructor(protected http: HttpClient) { }
  filterscheme(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }

    return this.http
      .post<any[]>(this.resourceFliterUrl, filter, {

        observe: 'response',
      });
  }
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }

    return this.http
      .get<any[]>(this.resourceFliterUrl, {

        observe: 'response',
      });
  }
  filterSchemeByIA(data): Observable<EntityschemeResponseType> {


    return this.http
      .get<any>(`${this.SchemefilterIAUrl}finYear=${data}`, {

        observe: 'response',
      });
  }
  save(schemeList?: any): Observable<EntityResponseType> {
    return this.http
      .post<any>(this.resourceSaveUrl, schemeList, { observe: 'response' })
  }
  validFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<any[]>(this.resourceVaildFliterUrl, filter, { params: options, observe: 'response' })
  }
  filterSchemedata(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    return this.http
      .get<any>(this.resourceFliterUrl, {
        observe: 'response',
      });
  }
}
