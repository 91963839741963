import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RaiseRequestService {

  constructor(private http: HttpClient) { }
  protected estimationChargeFilter = environment.SERVER_API_URL + ('api/establishmentCharge/getAgency');
  protected estimationChargeFilter1 = environment.SERVER_API_URL + ('api/establishmentChargeItem/getAmount');
  protected ItemMaster = environment.SERVER_API_URL + ('api/establishmentChargeItemMaster');
  protected getProject = environment.SERVER_API_URL + ('api/establishmentChargeProjectFundAllocation/getProjectList');
  protected ItemSubMaster = environment.SERVER_API_URL + ('api/establishmentChargeSubItem');
  protected establishment = environment.SERVER_API_URL + ('api/establishmentChargeItem');
  protected establishmentUpdate = environment.SERVER_API_URL + ('api/establishmentChargeItem/updateById');
  protected establishmentAmount = environment.SERVER_API_URL + ('api/establishmentChargeProjectFundAllocation');

  getFilter(req) {
    const options = createRequestOption(req);
    
    return this.http.get(`${this.estimationChargeFilter}`, { observe: 'response' ,params:options})
  }
  get2Filter(req) {
    const options = createRequestOption(req);
    return this.http.get(`${this.estimationChargeFilter1}`, { observe: 'response' ,params:options})
  }
  getProjectID(req) {
    return this.http.get(`${this.establishment}`, { observe: 'response' })
  }
  
  getItemMaster() {
    return this.http.get(`${this.ItemMaster}`, { observe: 'response' })
  }
  getStatusRequest(req) {
    const options = createRequestOption(req);

    return this.http.get(`${this.establishmentAmount}`, { observe: 'response' ,params:options})
  }
  getProjects(id,proid) {
    return this.http.get(`${this.getProject}?agencyId=${id}&projectId=${proid}`, { observe: 'response' })
  }
  getItemSubMaster(id) {
    return this.http.get(`${this.ItemSubMaster}?establishmentChargeItemId=${id}`, { observe: 'response' })
  }

  postEstablishment(data) {
    return this.http.post(`${this.establishment}`,data, { observe: 'response' })
  }
  putEstablishment(data,id) {
    return this.http.post(`${this.establishmentUpdate}/${id}`,data, { observe: 'response' })
  }

  postEstablishmentAmount(data) {
    return this.http.post(`${this.establishmentAmount}?amountStatus=true`,data, { observe: 'response' })
  }

  getEstablishment() {
    return this.http.get(`${this.establishment}`, { observe: 'response' })
  }
  getAmountDetails() {
    return this.http.get(`${this.establishmentAmount}`, { observe: 'response' })
  }

}
