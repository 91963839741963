<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>{{hamHeader}}</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row px-4 bgFilter align-items-center">
            <div class="row align-items-center mt-2">
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">Project
                        Name</label>
                    <ng-select id="department" bindLabel="projectName" bindValue='id'
                        [(ngModel)]="stage1Filter.projectName" [items]="projectList" appearance="outline"
                        class="form-control" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Name</label>
                    <input type="text" class="form-control" name="name" [(ngModel)]="stage1Filter.name"
                        autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">Mobile Number</label>
                    <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                        [(ngModel)]="stage1Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                        style="text-transform:uppercase" id="name" appSerachInputRestriction/>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Aadhar Number</label>
                    <input type="text" class="form-control" [(ngModel)]="stage1Filter.aadhaar" name="name"
                        autocomplete="off" maxlength="60" (keypress)="keyPressNumberForAadhaar($event)"
                        style="text-transform:uppercase" id="name" appSerachInputRestriction/>
                </div>
            </div>
            <div class="flex align-items-center mt-3 ">
                <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Payment Status
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row mt-3">
        <div #customerTable id="entities" class="col-md-12 px-1">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">S.No
                        </th>
                        <th scope="col">Beneficiary Name <span (click)="loadPage(page,'benificiaryLandOwnerName')"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Survey
                            Number <span (click)="loadPage(page,'surveyNumber')"><i class="fa fa-sort"></i> </span></th>
                        <th scope="col">Subdivision
                            Number <span (click)="loadPage(page,'subDivisionNumber')"><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Mobile
                            Number <span (click)="loadPage(page,'mobileNumber')"><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Bank Name <span (click)="loadPage(page,'nameOfBank')"><i class="fa fa-sort"></i>
                            </span>
                        </th>
                        <th scope="col">Account Number <span (click)="loadPage(page,'accountNumber')"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Aadhaar Number <span (click)="loadPage(page,'aadharNumber')"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Amount <span (click)="loadPage(page,'totalAmount')"><i class="fa fa-sort"></i>
                            </span>
                        </th>
                        <th scope="col">Status
                        </th>
                        <th scope="col">Utr No
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of paymentList;let i =index">
                        <td>{{generateIndex(i)}}</td>
                        <td>{{data.benificiaryLandOwnerName}}</td>
                        <td>{{data.surveyNumber}}</td>
                        <td>{{data.subDivisionNumber}}</td>
                        <td>{{data.mobileNumber}}</td>
                        <td>{{data.nameOfBank}}</td>
                        <td>{{data.accountNumber}}</td>
                        <td>{{data.aadharNumber}}</td>
                        <td>{{data.compensationAmount | currency:'INR'}}</td>
                        <td>
                            {{data.laoStatus}}
                        </td>
                        <td>
                            {{data.utrNo}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" alt="">
            </div>
            <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>
    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            District User
        </p-header>



    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>