<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>User's List</h2>
</div>
<div class="container-fluid bg">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                </ng-template>
                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center mt-3 bgFilter">
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">User
                    Name</label>
                <input type="text" class="form-control" name="name" autocomplete="off" [(ngModel)]="filter.userName"
                    maxlength="60" id="name" />
            </div>
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Mobile</label>
                <input type="text" class="form-control" (keypress)="keyPressNumberForMobile($event)" name="name"
                    autocomplete="off" [(ngModel)]="filter.mobile" maxlength="60" id="name" />
            </div>
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">District</label>
                <input type="text" class="form-control" name="name" autocomplete="off" [(ngModel)]="filter.district"
                    maxlength="60" id="name" />
            </div>
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Designaiton</label>
                <input type="text" class="form-control" name="name" autocomplete="off" [(ngModel)]="filter.designaiton"
                    maxlength="60" id="name" />
            </div>
            <div class="col-sm-2 col-md-1 mt-3">
                <p-button label="Search" class="submit" (click)="submitForFilter()"></p-button>
            </div>
            <!-- <div class="col-sm-6 col-md-6 mt-3">
                <span *ngIf="filterQuery.userName != '' ">User Name <i class="pi pi-times iconClose"
                        (click)="clear('userName')"></i>
                </span> &nbsp;
                <span *ngIf="filterQuery.mobile !='' ">Mobile <i class="pi pi-times iconClose"
                        (click)="clear('mobile')"></i></span> &nbsp;
                <span *ngIf="filterQuery.district != '' ">District <i class="pi pi-times iconClose"
                        (click)="clear('district')"></i></span>&nbsp;
                <span *ngIf="filterQuery.designation != '' ">Designation <i class="pi pi-times iconClose"
                        (click)="clear('designation')"></i></span>
            </div> -->
        </div>
    </div>
    <div class="row tableDesign">
        <div class="reqButton">
            <h5 [ngClass]="fontService.headingClass" class="pt-2 head">
                User's List
            </h5>
        </div>
        <div #customerTable id="entities" class="col-md-12 px-5 mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">User Name <span (click)="loadPage(page,'userName')"><i class="fa fa-sort"></i>
                            </span></th>
                        <th scope="col">Designation <span (click)="loadPage(page,'designation')"><i
                                    class="fa fa-sort"></i> </span></th>
                        <th scope="col">District <span (click)="loadPage(page,'district')"><i class="fa fa-sort"></i>
                            </span>
                        </th>
                        <th scope="col">Category of LAO <span (click)="loadPage(page,'laoCategory')"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Role <span (click)="loadPage(page,'role')"><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Mobile Number <span (click)="loadPage(page,'mobile')"><i class="fa fa-sort"></i>
                            </span></th>
                        <th scope="col">Status </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of agencyList;let i = index">
                        <td>{{generateIndex(i)}}</td>
                        <td>{{data.userName}}</td>
                        <td>{{data.designation != null ? data.designation :'--'}}</td>
                        <td>{{data.levelMasterId?.name}}</td>
                        <td>{{data.laoCategory != null ? data.laoCategory?.category:'-'}}</td>
                        <td>{{data.role?.name}}</td>
                        <td>{{data.mobile != null ? data.mobile :'-'}}</td>
                        <td>{{data.profileStatus == "Yes" ? "Active":'In Active'}}</td>

                        <td>
                            <span (click)="openNew('view',data)">
                                View
                            </span> &nbsp;
                            <span *ngIf="data.profileStatus == 'Yes'" (click)="openNew('edit',data)">
                                Edit
                            </span> &nbsp;
                            <span *ngIf="data.profileStatus == 'Yes'" (click)="getDeActivate(data.id)">
                                Deactivate
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" width="419px" alt="">
            </div>
            <p-paginator *ngIf="totalCount != 0" [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>

    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px', height:'400px'}" [modal]="true"
        [closable]="true" (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">


        <p-header [ngClass]="fontService.headingClass">
            User Details
        </p-header>
        <form #laoForm="ngForm">
            <div class="col-md-12">
                <div class="form-inner-panel" novalidate>
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                for="agencyName">Designaiton</label>
                            <input type="text" id="agencyName" required name="designaiton" (ngModelChange)="checkName()"
                                [(ngModel)]="fund.designaiton" [disabled]="viewOnly" class="form-control" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">District
                            </label>
                            <ng-select id="department" bindLabel="name" name='district' required bindValue="id"
                                appearance="outline" [(ngModel)]="fund.district" [closeOnSelect]="true"
                                [clearable]="true" [items]="district" class="form-control" [disabled]="viewOnly"
                                (change)="onDistrict($event)">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                for="agencyName">Mobile
                                No</label>
                            <input type="number" id="agencyName" name="mobile" (ngModelChange)="checkName()"
                                #mobileNo="ngModel" ngModel (keypress)="keyPressNumberForMobile($event)" required
                                [(ngModel)]="fund.mobile" class="form-control" [disabled]="viewOnly"
                                pattern="^[6-9]\d{9}$" />
                            <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                                <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                                    Please enter valid mobile number
                                </small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                for="agencyName">Email</label>
                            <input type="email" id="agencyName" name="email" required (ngModelChange)="checkName()"
                                [(ngModel)]="fund.email" class="form-control" [disabled]="viewOnly" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Category of
                                LAO</label>
                            <ng-select id="department" bindLabel="category" required bindValue="id" appearance="outline"
                                [(ngModel)]="fund.category" [closeOnSelect]="true" [clearable]="true" name="lao"
                                [items]="categoryLAO" class="form-control" [disabled]="viewOnly"
                                (change)="onLAO($event)">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!viewOnly" class="mt-4 ">
                <button pButton pRipple icon="pi pi-times" iconPos="left"
                    class="p-button-text custom-button cancelButton" (click)="hideDialog()">
                    <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.cancelbutton"></span>
                </button> &nbsp;
                <button pButton pRipple icon="pi pi-check" [disabled]="laoForm.invalid"
                    class="p-button-text custom-button saveButton" (click)="saveAgency('edit')" type="submit">
                    <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.savebutton"></span>
                </button>
            </div>
        </form>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>