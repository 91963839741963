<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Request Funds</h2>
</div>
<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="false" [activeIndex]="activeIndex"
        (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
</div>
<div class="container-fluid bg mt-4" *ngIf="activeIndex == 0">
    <div class="container row mx-auto align-items-end bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">
                Name of the Requisition Agency</label>
            <ng-select bindLabel="agencyName" bindValue="id" appearance="outline" [(ngModel)]="page1Submit.agency"
                [items]="AgencyLists" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">Name of the LAO</label>
            <input type="text" class="form-control" [(ngModel)]="laoName" name="name" readonly id="name" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Financial year for which the
                Establishment Charge Proposed</label>
            <ng-select id="type" [items]="getYear" bindLabel="id" bindLabel="name" appearance="outline"
                [closeOnSelect]="true" [clearable]="true" class="form-control" [(ngModel)]="page1Submit.finYear" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Posts sanction available up to
                which date?</label>
            <p-calendar [readonlyInput]="true" [(ngModel)]="date"></p-calendar>

        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">If any proposal sent to Government
                for retention of posts, please mention such date (If applicable)</label>
            <p-calendar [readonlyInput]="true" [(ngModel)]="date1"></p-calendar>
        </div>
        <div class="col-sm-3 col-md-3 mt-3">
            <p-button label="Submit" class="submit" (click)="submitPage1()"></p-button>
        </div>
    </div>

    <div class="container-fluid bg">
        <div class="tableDesign">
            <div class="row">
                <div class="col-md-12">
                    <p-toolbar styleClass="toolbar">
                        <ng-template pTemplate="right">
                        </ng-template>
                    </p-toolbar>
                </div>
            </div>
            <div class="row mt-3">
                <div #customerTable id="entities" class="col-md-12  px-5">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col"> Name of the Requisition Agency</th>
                                <th scope="col">Name of the LAO </th>
                                <th scope="col">Financial year for which the
                                    Establishment Charge Proposed</th>
                                <th scope="col">
                                    Posts sanction available up to
                                    which date?
                                </th>
                                <th scope="col">
                                    Proposal Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of estimationList;let i = index">
                                <td>{{generateIndex(i)}}</td>
                                <td>
                                    {{data.requestingAgencyId.agencyName}}
                                </td>
                                <td>
                                    {{data.laoName.userName}}
                                </td>
                                <td class="text-center">{{data.financialYear.name}}</td>
                                <td>{{data.postSanctionDate | date}}</td>
                                <td>{{data.retentionDate | date}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                        [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
                </div>
            </div>
        </div>
        <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    </div>
</div>
<div class="container-fluid bg mt-4" *ngIf="activeIndex == 1">
    <div class="container row mx-auto align-items-end bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">
                Name of the Requisition Agency</label>
            <ng-select i bindLabel="agencyName" bindValue="id" appearance="outline" [(ngModel)]="page2Submit.reqAgency"
                [items]="agency" class="form-control" (change)="getExtent('agency')" (change)="selectProject($event)" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">Name of the LAO</label>

            <input type="text" class="form-control" [(ngModel)]="page2Submit.lao" name="name" readonly id="name" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Post sanction available
                upto</label>
            <input type="text" class="form-control" name="name" readonly [(ngModel)]="page2Submit.postSanction" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">
                Proposal sent to Government for retension of Posts upto ( if any)</label>
            <input type="text" class="form-control" name="name" readonly autocomplete="off" maxlength="60"
                [(ngModel)]="page2Submit.proposal">
        </div>
    </div>
    <div class="container">
        <form [formGroup]="projectForm">
            <table class="table mt-3" formArrayName="LandDetailsForm">
                <thead>
                    <tr>
                        <th scope="col">Sl.No</th>
                        <th scope="col">MAJOR HEAD</th>
                        <th scope="col">SUB HEAD</th>
                        <th scope="col">Total Amount
                            Requested by LAO(In
                            Rupees)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let landDetails of detailsOfLand.controls; let i=index" [formGroupName]="i">
                        <td scope="row">{{i+1}}</td>
                        <td>
                            <ng-select bindLabel="itemName" bindValue="id" appearance="outline" [items]="itemMaster"
                                formControlName="majorHead" class="form-control" (change)="selectMajor($event,i)" appNgSelectInputRestriction>
                            </ng-select>
                        </td>
                        <td>
                            <ng-select bindLabel="subItemName" bindValue="id" [items]="minorHeads" appearance="outline"
                                formControlName="subHead" class="form-control" (change)="selectMinor($event)" appNgSelectInputRestriction> 
                            </ng-select>
                        </td>
                        <td> <input type="number" class="form-control" name="name" formControlName="totalAmount"
                                id="name" />
                        </td>
                        <td>
                            <span [hidden]="!this.detailsOfLand.valid">
                                <i class="pi pi-plus-circle mt-1" (click)="addSkill(i)"></i> &nbsp;
                                <i class="pi pi-trash" *ngIf="detailsOfLand.length > 1" (click)="removeSkill(i)"></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-md-12">
                <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">
                    Upload document if any?</label><br>
                <small [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file
                    types are jpg, jpeg, png, pdf and
                    Maximum file size should be 1MB</small>
            </div>
            <div class="col-md-12" *ngIf="!isFile">
                <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUploadfile1($event)"
                    [customUpload]="true" auto="true" (uploadHandler)="onUploadfile1($event)" multiple="false"
                    [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                </p-fileUpload>
            </div>
            <div class="col" *ngIf="isFile">
                <a class="cursor-pointer" (click)="viewDocument()">{{project.fileUpload}}</a>
                <i class="pi pi-times add-margin" (click)="removeDocument()"></i>
            </div>
            <div class="col-3">
                <label class="form-control-label " [ngClass]="fontService.labelClass">
                    Remarks</label>
                <input type="text" class="form-control" name="name" style="width: 300px;" formControlName="remarks"
                    autocomplete="off" maxlength="60">
            </div>
        </form>
        <div class="d-flex justify-content-center mt-3">
            <p-button label="Check the status of fund
            availability" (click)="CheckStatus()" severity="warning"></p-button> &nbsp;
            <p-button label="Back" severity="secondary"></p-button>
        </div>
    </div>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>



</div>

<div class="container-fluid bg mt-4" *ngIf="activeIndex == 2">
    <div class="container row mx-auto align-items-end bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">
                Name of the Requisition Agency</label>
            <ng-select bindLabel="agencyName" bindValue="id" appearance="outline" [(ngModel)]="page3Submit.reqAgency"
                [items]="agency1" class="form-control" (change)="getExtent('agency1')"
                (change)="selectProject1($event)" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label " [ngClass]="fontService.labelClass">Name of the LAO</label>
            <input type="text" class="form-control" [(ngModel)]="page3Submit.lao" name="name" readonly id="name" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Post sanction available
                upto</label>
            <input type="text" class="form-control" name="name" readonly [(ngModel)]="page3Submit.postSanction" />
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">
                Proposal sent to Government for retension of Posts upto ( if any)</label>
            <input type="text" class="form-control" name="name" readonly autocomplete="off" maxlength="60"
                [(ngModel)]="page3Submit.proposal">
        </div>

        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">
                Total amount requested by LAO (In Rupees)</label>
            <input type="number" class="form-control" name="name" readonly [(ngModel)]="page3Submit.totalAmount" />
        </div>
    </div>
    <div class="container">
        <form [formGroup]="estimationForm">
            <div formArrayName="page3Arr">
                <table class="table mt-3">
                    <thead>
                        <tr>
                            <th scope="col">Sl.No</th>
                            <th scope="col">Name
                                of the
                                project</th>
                            <th scope="col">Total
                                funds
                                deposited
                                till date</th>
                            <th scope="col">Funds
                                available
                                as on
                                date</th>
                            <th>Select</th>
                            <th>Amount to be allotted
                                for establishment
                                charges? (In Rupees)</th>
                            <th>
                                Percentage of
                                Amount allotted
                                as establishment
                                charge
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let landDetails of detailsOfEstablishment.controls; let i=index"
                            [formGroupName]="i">
                            <td>{{generateIndex(i)}}</td>
                            <td>
                                <ng-select id="type" (change)="projectPage3($event,i)" [items]="projectList" formControlName="projectName"
                                    style="width: 200px;" bindValue="id" bindLabel="projectName" appearance="outline"
                                    [closeOnSelect]="true" [clearable]="true" class="form-control" appNgSelectInputRestriction>
                                </ng-select>
                            </td>
                            <td>
                                <input type="text" class="form-control" name="name" style="width: 100px;" readonly
                                    formControlName="totalFunds" autocomplete="off" maxlength="60">
                            </td>
                            <td>
                                <input type="text" class="form-control" name="name" style="width: 100px;" readonly
                                    formControlName="availableBalance" autocomplete="off" maxlength="60">
                            </td>
                            <td>
                                <p-checkbox [binary]="true" inputId="binary" formControlName="selectOption"
                                    (onChange)="selectOption($event,i)"></p-checkbox>
                            </td>
                            <td>
                                <input type="number" class="form-control" (keyup)="allotAmount($event,i)" name="name"
                                    style="width: 180px;" formControlName="allotAmount" autocomplete="off" 
                                    maxlength="60">
                            </td>
                            <td>
                                <input type="text" readonly class="form-control" 
                                [ngClass]="{ 'is-invalid':detailsOfEstablishment.controls[i].get('percentage').touched}" name="name" style="width: 180px;"
                                    formControlName="percentage" autocomplete="off" maxlength="60">
                            </td>
                            <td>
                                <i class="pi pi-plus-circle mt-1" (click)="addEstablishment('end')"></i> &nbsp;
                                <i class="pi pi-trash" *ngIf="detailsOfEstablishment.length > 1"
                                    (click)="removeEstablishment(i)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-3">
                <label class="form-control-label" [ngClass]="fontService.labelClass">
                    Remarks</label>
                <input type="text" class="form-control" formControlName='remarks' name="name" style="width: 50px;" autocomplete="off"
                    maxlength="60"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase" id="name" />

            </div>
        </form>

        <div class="d-flex justify-content-center mt-3">
        <p-button (click)="verifyPayment()" label="Recommend to CAO 
            LAATAN" severity="secondary"></p-button> &nbsp;
            <p-button label="Back" severity="secondary"></p-button>
        </div>

    </div>

    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    <p-dialog header="Info" [(visible)]="warn" [style]="{width: '450px'}" >
        <p>
            Total amount proposed as
            establishment charge exceeds
            the fund available in the
            project, please correct!
        </p>
        <p-button class="mx-auto" label="Ok" (click)='warn=false'></p-button>
    </p-dialog>


</div>