import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { ConfirmationService } from 'primeng/api';
import { SigninService } from '../../auth/signin/signin.service';
import { PaymentService } from '../../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { RaiseRequestService } from './raise-request.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-raise-request',
  templateUrl: './raise-request.component.html',
  styleUrls: ['./raise-request.component.scss']
})
export class RaiseRequestComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  SampleForm: NgForm
  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project = {
    fileUpload: ""
  };
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  page1Submit: any = {
    agency: "",
    finYear: '',

  }

  items = [
    {
      label: 'Page 1',
    },
    {
      label: 'Page 2',
    },
    {
      label: 'Page 3',
    }
  ];
  activeIndex: number = 0;
  minorHeads = []
  minorHead: any
  index: any;
  pageCount: number = 0;
  pageLength: number;
  fileUpload: any;
  isFile: boolean = false;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  AgencyLists: any;
  laoName: any;
  getYear: any;
  totalCount: any;

  products = [
    {
      id: '1000',
      code: '20000000',
      name: '1000000',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: '5000000',
      quantity: 5000000,
      inventoryStatus: 'INSTOCK',
      rating: 5
    },

  ];

  projectForm: FormGroup;

  finYear = [

  ]
  majorHead: any

  filterProps: any;
  commercialList: Project[];
  page2Submit: any = {
    reqAgency: '',
    lao: '',
    postSanction: '',
    proposal: ''
  }

  page3Submit: any = {
    reqAgency: '',
    lao: '',
    postSanction: '',
    proposal: '',
    totalAmount: ''
  }

  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;
  date1: any
  selectDistrictId: any;
  projectName: any;
  projectId: any;
  taluk: any;
  districkList: any;
  estimationList: any;
  talukId: any;
  villages: any;
  villageID: any;
  districtId: any;
  hamHeader: string;
  agency: any = [];
  laoID: any;
  chargeID: any;
  itemMaster: any;
  agency1: any;
  EatablishmentchargeID1: any;
  laoID1: any;
  agencyId: any;
  projectList: any = [];
  rowIndex: any;
  disableImage: boolean = true;
  estimationForm: FormGroup;
  amountDetails: any;
  danger: boolean;
  warn: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    protected RaiseRequestService: RaiseRequestService,
    private loginService: SigninService,
    private projectService: ProjectService,
    private paymentService: PaymentService,
    private ConfirmationService: ConfirmationService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }
  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];

  getProjects(page) {

    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.projectService.estimationCharge({ ...query }).subscribe(
      (res: any) => {
        this.estimationList = res.body.data;
        this.totalCount = res.body.totalCount
      },
      () => {
        // this.onError();
      }
    );
  }

  getExtent(name) {
    if (name == 'agency') {
      this.page2Submit.lao = ''
      this.page2Submit.postSanction = ''
      this.page2Submit.proposal = ''
    } else {
      this.page3Submit.lao = ''
      this.page3Submit.postSanction = ''
      this.page3Submit.proposal = ''
      this.page3Submit.totalAmount = ''
    }
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.getProjects(page);
  }

  onSelectDistrict(e) {
    this.projectService.talukAndVillage(e, '').subscribe((res: any) => {
      this.taluk = res.body.data
    })
  }

  selectProject1(e) {
    let obj = {
      agencyId: this.page3Submit.reqAgency,
      laoId: this.page3Submit.lao,
      postSanctionDate: this.page3Submit.postSanction,
      retentionDate: this.page3Submit.proposal
    }


    this.RaiseRequestService.get2Filter(obj).subscribe((res: any) => {
      this.page3Submit.lao = res.body.data[0].userName
      let obj = {
        agencyId: this.page3Submit.reqAgency,
        laoId: res.body.data[0].id,
        postSanctionDate: this.page3Submit.postSanction,
        retentionDate: this.page3Submit.proposal

      }
      this.laoID1 = res.body.data[0].id
      this.RaiseRequestService.get2Filter(obj).subscribe((res: any) => {
        this.page3Submit.postSanction = this.datepipe.transform(res.body.data, 'yyyy-MM-dd')
        let obj = {
          agencyId: this.page3Submit.reqAgency,
          laoId: this.laoID1,
          postSanctionDate: this.page3Submit.postSanction,
          retentionDate: this.page3Submit.proposal
        }
        this.RaiseRequestService.get2Filter(obj).subscribe((res: any) => {
          this.page3Submit.proposal = this.datepipe.transform(res.body.data[0], 'yyyy-MM-dd')
          let obj = {
            agencyId: this.page3Submit.reqAgency,
            laoId: this.laoID1,
            postSanctionDate: this.page3Submit.postSanction,
            retentionDate: this.page3Submit.proposal,
          }
          this.RaiseRequestService.get2Filter(obj).subscribe((res: any) => {
            this.page3Submit.totalAmount = res.body.data.laoAmount
            this.EatablishmentchargeID1 = res.body.data.establishmentChargeItemId



            this.agencyId = res.body.data.agencyId
            this.formPage3()
            this.addEstablishment('begin')
            this.getProject(this.agencyId, '', '')
          })
        })
      })
    })
  }


  projectPage3(e, i) {
    console.log(e.id);
    this.getProject(this.agencyId, e.id, i)

  }

  getProject(id, proId, i) {
    this.RaiseRequestService.getProjects(id, proId).subscribe((res: any) => {
      if (proId != '' && id != '') {
        this.amountDetails = res.body.data
        this.detailsOfEstablishment.controls[i].get('totalFunds').setValue(this.amountDetails.totalAmount)
        this.detailsOfEstablishment.controls[i].get('availableBalance').setValue(this.amountDetails.availableAmount)
      } else if (id) {
        this.projectList = res.body.data
      }
    })
  }

  select(e, data) {
    console.log(data.id);


  }

  selectProject(e) {
    let obj = {
      agencyId: this.page2Submit.reqAgency,
      laoId: this.page2Submit.lao,
      postSanctionDate: this.page2Submit.postSanction
    }
    this.RaiseRequestService.getFilter(obj).subscribe((res: any) => {
      this.page2Submit.lao = res.body.data[0].userName
      let obj = {
        agencyId: this.page2Submit.reqAgency,
        laoId: res.body.data[0].id,
        postSanctionDate: this.page2Submit.postSanction
      }
      this.laoID = res.body.data[0].id
      this.RaiseRequestService.getFilter(obj).subscribe((res: any) => {
        this.page2Submit.postSanction = res.body.data 
        let obj = {
          agencyId: this.page2Submit.reqAgency,
          laoId: this.laoID,
          postSanctionDate: this.page2Submit.postSanction
        }
        this.RaiseRequestService.getFilter(obj).subscribe((res: any) => {
          this.page2Submit.proposal =res.body.data;
          this.chargeID = res.body.data.establishmentChargeId
        })
      })
    })
  }
  CheckStatus() {
    let tempArr = []
    let form = this.projectForm.value
    console.log(form);

    form.LandDetailsForm.forEach(element => {
      let obj = {
        "establishmentChargeItemId": element.majorHead,
        "establishmentChargeSubItemId": element.subHead,
        "laoAmount": element.totalAmount
      }
      tempArr.push(obj)
    });
    let payload = {
      establishmentCharges: tempArr,
      establishmentChargeRequestId: this.chargeID,
      remark: form.remarks,
      agencyId: this.page2Submit.reqAgency,
      file: this.project.fileUpload
    }
    this.RaiseRequestService.postEstablishment(payload).subscribe((res: any) => {
      this.forms();
      this.addSkills();
      this.isFile = false;
      this.project.fileUpload = ''
      this.projectForm.reset();
      this.notificationService.alertSuccess('Establishment Charge added SuccessFully', '');
    })
  }

  selectMajor(e, i) {
    this.RaiseRequestService.getItemSubMaster(e.id).subscribe((res: any) => {
      this.minorHeads = res.body.data
      this.detailsOfLand.controls[i].get('subHead').setValue('');
    })
  }

  selectMinor(e) {
    console.log(e);
  }
  verifyPayment() {
    let temp = []
    if (this.detailsOfEstablishment.status == 'INVALID') {
      this.ConfirmationService.confirm({
        header: 'Are you sure to proceed further?',
        message: "The proposed allotment should not exceed 14.5% ceiling fixed for establishment charges in a project.",
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.warn = true
        },
        reject: () => {
        }
      });
    } else {
      console.log(this.estimationForm.value);
      this.estimationForm.value.page3Arr.forEach(element => {
        let obj = {
          "projectId": element.projectName,
          "laoStatus": "TRUE",
          "laoAllocateAmount": element.allotAmount,
          "laoAllocatePercentage": element.percentage
        }
        temp.push(obj)
      });
      console.log(this.EatablishmentchargeID1, 'this.EatablishmentchargeID1');

      let data = {
        "projectFundAllocations": temp,
        "establishmentChargeItemId": this.EatablishmentchargeID1,
        "agencyId": this.agencyId,
        "remark": this.estimationForm.value.remarks
      }
      this.spinner.show()
      this.RaiseRequestService.postEstablishmentAmount(data).subscribe((res: any) => {
        this.formPage3()
        this.addEstablishment('begin')
        this.spinner.hide()
        this.notificationService.alertSuccess('Added SuccessFully', '');
        // this.agency1 = res.body.data;
      })

    }

  }



  onActiveIndexChange(event: number) {
    this.activeIndex = event;
    if (this.activeIndex == 1) {
      let obj = {
        agencyId: this.page2Submit.reqAgency != '' ? this.page2Submit.reqAgency : '',
        laoId: this.laoID != undefined ? this.laoID : '',
        postSanctionDate: this.page2Submit.postSanction != '' ? this.page2Submit.postSanction : ''
      }
      this.RaiseRequestService.getFilter(obj).subscribe((res: any) => {
        this.agency = res.body.data;
        console.log(this.agency, 'this.agency');
      })
      this.RaiseRequestService.getItemMaster().subscribe((res: any) => {
        this.itemMaster = res.body.data;
      })
    } else if (this.activeIndex == 2) {
      this.formPage3()
      let obj = {
        agencyId: this.page3Submit.reqAgency != '' ? this.page3Submit.reqAgency : '',
        laoId: this.page3Submit.lao != '' ? this.page3Submit.lao : '',
        postSanctionDate: this.page3Submit.postSanction != '' ? this.page3Submit.postSanction : '',
        retentionDate: this.page3Submit.proposal != '' ? this.page3Submit.proposal : ''
      }
      this.RaiseRequestService.get2Filter(obj).subscribe((res: any) => {
        this.agency1 = res.body.data;
      })
    }
  }

  OnSelectVillage(e) {
    this.villageID = e.id
  }

  ngOnInit() {
    this.forms();
    this.addSkills();
    this.getProjects(1);
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    this.projectService.getYear().subscribe((res: any) => {
      this.getYear = res.body;
      console.log(this.getYear, 'this.getYear');

    });
    this.loginService.getuserDto().subscribe(
      (res) => {
        console.log();
        this.laoName = res.body.userName
        this.districtId = res.body.levelMasterId
        // this.onSelectDistrict(res.body.levelMasterId)

      })

  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);
  }
  submitPage1(param?: any) {
    let obj = {
      "requestingAgencyId": this.page1Submit.agency,
      "financialYear": this.page1Submit.finYear.id,
      "postSanctionDate": this.date,
      "retentionDate": this.date1
    }
    this.spinner.show()
    this.projectService.page1Submit(obj, param).subscribe((res: any) => {
      this.spinner.hide()
      this.getProjects(1)
      this.districkList = res.body.data;
      this.notificationService.alertSuccess('Establishment Charge request data added SuccessFully', '');
      this.page2Submit = {
        reqAgency: '',
        lao: '',
        postSanction: '',
        proposal: ''
      }
    }, (err) => {
      this.confirm1(err)
      this.spinner.hide()

    })
  }
  confirm1(err) {
    this.ConfirmationService.confirm({
      message: err.error.error,
      header: 'Do you want to continue?',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        let params = {
          finYear: true
        }
        this.submitPage1(params)
      },
      reject: () => {
      }
    });
  }


  getUserrole() {

  }
  addSkill(i) {

    this.detailsOfLand.push(this.detailsform());
  }
  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.fileUpload = res.body.fileDownloadUri;
        this.project.fileUpload = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => { }
    );
  }

  removeDocument() {
    this.paymentService.deleteimage(this.project.fileUpload).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  viewDocument() {
    this.paymentService.viewimage(this.project.fileUpload).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  forms() {
    this.projectForm = this.formBuilder.group({
      remarks: [''],
      LandDetailsForm: this.formBuilder.array([]),
    })
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      majorHead: ['', Validators.required],
      subHead: ['', Validators.required],
      totalAmount: ['', Validators.required],
    });
  }


  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('totalAmount').value);
    let total: any = privateNegotitaion
    if (privateNegotitaion != '') {
      //conso.log(privateNegotitaion)
      this.detailsOfLand.controls[i].get('totalCompensation').setValue(total);

    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  ngAfterViewInit() {
  }


  formPage3() {
    this.estimationForm = this.formBuilder.group({
      remarks: [''],
      page3Arr: this.formBuilder.array([]),
    })
  }

  page3Form(): FormGroup {
    return this.formBuilder.group({
      projectName: ['', Validators.required],
      totalFunds: ['', Validators.required],
      availableBalance: ['', Validators.required],
      selectOption: [''],
      allotAmount: [{ value: '', disabled: true }, Validators.required],
      percentage: [{ value: '', disabled: true }, [Validators.required, Validators.max(14.5)]]
    });
  }
  get detailsOfEstablishment(): FormArray {
    return this.estimationForm.get('page3Arr') as FormArray;
  }

  allotAmount(e, i) {
    let totalfunds: any = this.detailsOfEstablishment.controls[i].get('totalFunds').value
    if (totalfunds != 0) {
      let percentage = (Number(e.target.value) / Number(totalfunds)) * 100
      this.detailsOfEstablishment.controls[i].get('percentage').setValue(percentage)
      if (percentage > 14.5) {
        this.danger = true
        this.detailsOfEstablishment.controls[i].get('percentage').markAsTouched()
      } else {
        this.danger = false
        this.detailsOfEstablishment.controls[i].get('percentage').markAsUntouched()
      }
    } else {
      this.detailsOfEstablishment.controls[i].get('percentage').setValue('0')
    }


  }
  addEstablishment(lable) {
    if (lable == 'begin') {
      this.detailsOfEstablishment.push(this.page3Form());
    } else {
      if (this.estimationForm.valid) {
        this.detailsOfEstablishment.push(this.page3Form());
      }
    }
  }
  selectOption(e, i) {
    if (e.checked) {
      this.detailsOfEstablishment.controls[i].get('allotAmount').enable()
      this.detailsOfEstablishment.controls[i].get('percentage').enable()
    } else {
      this.detailsOfEstablishment.controls[i].get('allotAmount').disable()
      this.detailsOfEstablishment.controls[i].get('percentage').disable()
    }
  }

  removeEstablishment(i: number) {
    this.detailsOfEstablishment.removeAt(i);
  }

}
