<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Taluks and Village List</h2>
</div>
<div class="container-fluid bg">
    <div>
        <div class="row">
            <div class="col-md-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass">
                            Taluks and Village
                        </h5>
                    </ng-template>
                    <ng-template pTemplate="right">
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row mt-3 ">
            <div #customerTable id="entities" class="col-md-12">
                <div class="container bgFilter mb-3">
                    <div class="row align-items-center mt-2">
                        <div class="col-sm-4 col-md-3">

                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                                Name</label>

                            <ng-select id="department" bindLabel="projectName" bindValue='id'
                                [(ngModel)]="stage1Filter.projectName" [items]="projectList" appearance="outline"
                                class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Village
                                Name</label>

                            <input type="text" class="form-control" [(ngModel)]="stage1Filter.villageName" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">

                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk Name</label>

                            <input type="text" class="form-control" [(ngModel)]="stage1Filter.talukName" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                    </div>
                    <div class="flex align-items-center mt-3 ">
                        <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Project <span (click)="loadPage(page,'projectName')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Taluk <span (click)="loadPage(page,'talukName')"><i class="fa fa-sort"></i>
                                </span></th>
                            <th scope="col">Village <span (click)="loadPage(page,'villageName')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Name of the Unit <span (click)="loadPage(page,'nameOfUnit')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Name of the Block <span (click)="loadPage(page,'nameOfBlock')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Through Act <span (click)="loadPage(page,'throughAct')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Through Private Negotiation <span
                                    (click)="loadPage(page,'throughPrivateNegotiation')"><i class="fa fa-sort"></i>
                                </span></th>
                            <th scope="col">Total Extent Land <span (click)="loadPage(page,'totalExtentLand')"><i
                                        class="fa fa-sort"></i> </span></th>
                            <th scope="col">Status </th>
                            <th scope="col">Action </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of beneficary;let i =index">
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.projectId.projectName}}</td>
                            <td>{{data.taluk.name}}</td>
                            <td>{{data.village.name}}</td>
                            <td>{{data.nameOfUnit}}</td>
                            <td>{{data.nameOfBlock}}</td>
                            <td>{{data.throughAct }}</td>
                            <td>{{data.throughPrivateNegotiation }}</td>
                            <td>{{data.totalExtentLand }}</td>
                            <td>
                                --
                            </td>
                            <td>
                                <a class="cursor-pointer"  (click)="edit(data)"> Edit </a>
                            </td>

                        </tr>
                    </tbody>

                </table>


                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>

                <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                    [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>

            </div>
        </div>
    </div>

    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            Taluks and Village
        </p-header>
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                    for="agencyName">Taluk</label>
                <input type="text" id="agencyName" class="form-control"style="background: lightgray;"  name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off"  readonly
                    [(ngModel)]="stage1.taluk"
                     />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                    for="agencyName">Village</label>
                <input type="text" id="agencyName" class="form-control" style="background: lightgray;" name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off"  readonly
                    [(ngModel)]="stage1.village"
                     />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                    for="agencyName">Through Private Negotiation</label>
                <input type="number" id="agencyName" class="form-control"  name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off" 
                    style="text-transform:uppercase" [(ngModel)]="stage1.throughPrivateNegotiation"
                     />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                for="agencyName">Through Act</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off" 
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [(ngModel)]="stage1.throughAct"
                     />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                for="agencyName">Name of Unit</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off" 
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [(ngModel)]="stage1.nameOfUnit"
                     />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" 
                for="agencyName">Name of Block</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName" (ngModelChange)="checkName()" maxlength="100"
                    autocomplete="off" 
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [(ngModel)]="stage1.nameOfBlock"
                     />
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div >
                <button pButton pRipple icon="pi pi-check"
                    class="p-button-text custom-button saveButton" (click)="onSubmit()" type="submit">
                    <span class="button-label"  >Submit</span>
                </button>
            </div>
        </ng-template>


    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>