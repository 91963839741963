import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SigninService } from 'src/app/auth/signin/signin.service';

export class UserContext {
  id: number;
  userName: string;
  password: string;
  mobile: string;
  email: string;
  resetPassword: string;
  loginAttempt: number;
  zonalName: string;
  role: Role;
  resetDate: Date;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
  activeFlag: string;
  profileStatus: string;
  implementingAgencyId: number;
  levelMasterId: number;
  keyCloakUuid: string;
}
class Role {
  id: number;
  code: string;
  name: string;
  createdOn: Date;
  updatedOn: Date;
}
@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  userRoleCode: string;
  userRoleName: string;

  constructor(private loginService: SigninService) { }

  // private userContext = new Subject<UserContext>();
  private userContext = new BehaviorSubject<UserContext>(new UserContext());

  public getuserContext(): BehaviorSubject<UserContext> {
    return this.userContext;
  }

  setUserRole(roleCode: string, roleName: string) {
    this.userRoleCode = roleCode;
    this.userRoleName = roleName;
  }
  public fetchAndSetuserContext(): void {
    this.loginService.getuserDto().subscribe(
      (response) => {
        this.userContext.next(response.body);
      },
      (err) => { }
    );
  }



  getuserRoleName(): string {
    return this.userRoleName;
  }


  getUserRoleCode(): string {
    return this.userRoleCode;
  }
}

