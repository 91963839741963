import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertMessage } from './alert-message';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private alerts = new BehaviorSubject<AlertMessage>(new AlertMessage());
  private loader = new BehaviorSubject<boolean>(false);
  alertsObserver = this.alerts.asObservable();

  constructor() { }

  getAlerts(): BehaviorSubject<AlertMessage> {
    return this.alerts;
  }

  alertSuccess(summary: string, params: string): void {
    const alertMsg = new AlertMessage();
    alertMsg.summary = summary;
    alertMsg.detail = params;
    alertMsg.severity = 'success';
    alertMsg.life = 5000;

    this.alerts.next(alertMsg);
  }

  alertInfo(summary: string, params: string): void {
    const alertMsg = new AlertMessage();
    alertMsg.summary = summary;
    alertMsg.detail = params;
    alertMsg.severity = 'info';
    alertMsg.life = 5000;

    this.alerts.next(alertMsg);
  }

  alertWarn(summary: string, params: string): void {
    const alertMsg = new AlertMessage();
    alertMsg.summary = summary;
    alertMsg.detail = params;
    alertMsg.severity = 'warn';
    alertMsg.life = 5000;

    this.alerts.next(alertMsg);
  }

  alertError(summary: string, params: string): void {
    const alertMsg = new AlertMessage();
    alertMsg.summary = summary;
    alertMsg.detail = params;
    alertMsg.severity = 'error';
    alertMsg.life = 5000;

    this.alerts.next(alertMsg);
  }

  showLoader(): void {
    this.loader.next(true);
  }

  hideLoader(): void {
    this.loader.next(false);
  }

  getLoader(): BehaviorSubject<boolean> {
    return this.loader;
  }
}
