<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>LAO Details</h2>
</div>
<div class="container bg">
    <div class="col-md-12">
        <div class="form-inner-panel" novalidate>
            <div class="row gy-2">
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Designaiton</label>
                    <input type="text" id="agencyName" name="agencyName" [(ngModel)]="fund.designaiton"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">District
                    </label>
                    <ng-select id="department" bindLabel="name" bindValue="id" appearance="outline"
                        [(ngModel)]="fund.district" [closeOnSelect]="true" [clearable]="true" [items]="district"
                        class="form-control" [disabled]="viewOnly" (change)="onDistrict($event)">
                    </ng-select>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="agencyName">Mobile
                        No</label>
                    <input type="number" id="agencyName" #mobileNo="ngModel" ngModel name="agencyName" (keypress)="keyPressNumberForMobile($event)"
                        [(ngModel)]="fund.mobile" class="form-control" minlength="10" pattern="^[6-9]\d{9}$" />
                    <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                        <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                            Please enter valid mobile number
                        </small>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Email</label>
                    <input type="email" id="agencyName" name="agencyName" [(ngModel)]="fund.email"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">Category of
                        LAO</label>
                    <ng-select id="department" bindLabel="category" bindValue="id" appearance="outline"
                        [(ngModel)]="fund.category" [closeOnSelect]="true" [clearable]="true" [items]="categoryLAO"
                        class="form-control" [disabled]="viewOnly" (change)="onLAO($event)">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!viewOnly" class="mt-3">
        <button pButton pRipple icon="pi pi-times" iconPos="left" class="p-button-text custom-button cancelButton"
            routerLink="/lao-list">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
        </button> &nbsp;
        <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button saveButton"
            (click)="saveLao('add')" type="submit" [disabled]="mobileNo.invalid">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
        </button>
    </div>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>