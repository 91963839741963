
export class Agency {
    deptCode?:string;
    schemeCode?:string;
    agencyName?:string;
    gstNo?:string;
    mobileNo?:number;
    email?:string;
    designation?:string;
    levelName?:string;
    levelMasterName?:string;
    bankAccountName?:string;
    bankId?:string;
    bankAccountNo?:string;
    ifsc?:string;
    branchName?:string;
    branchMobileNo?:string;
    branchEmail?:string;
    branchAddress?:string;  
}

export class AgencyDto{
    id: number
    name: string
    mobile: any
    email: any
    schemeDto: any
    zonalName: any
    leveltype: number
    gstNo: string
    designation: any
    levelMaster: number
    mapping: string
    agency:any
    govermentType:any
    address1:any
    address2:any
    street:any
    city:any
    district:any
    state:any
    offliceMobile:any
    ooficeemail:any
    pincode:any
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
    bankAccName: any
    bankId: any
    accountNumber: any
    ifscCode: any
    branchName: any
    branchMobNo: any
    branchEmail: any
    address: any
    activeFlag: string

    health: any
    block: any
    editImplementingAgency: boolean
  }