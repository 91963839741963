import { NotificationService } from 'src/app/alert/notification.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninService } from './signin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as forge from 'node-forge';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Captcha } from './login.model';
import { HttpResponse } from '@angular/common/http';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import {
  UserContext,
  UserRoleService,
} from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { authModel } from './signin.module';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'ib-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false })
  username!: ElementRef;

  showOtp = false;
  inValidCaptcha = false;
  inValidUser: boolean = false;
  publicKey: string;
  loginForm = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
    captcha: [null, [Validators.required]],
    rememberMe: [false],
  });

  otpForm = this.fb.group({
    otp: [null, [Validators.required]],
  });
  image: any;
  captchaText: any;
  otpTimer: number;
  timer: any;
  enableResendButton: boolean;
  disableButton: boolean = false;
  otpError: string;
  passwordReset: string;
  profileStatus: string;
  uname: string;
  pwd: string;
  hidden: boolean = true;
  captchaDetails: Captcha;
  captchaExpireError: string;
  spinner: boolean = false;
  reSendCount: number = 1;
  userContextSubscription: Subscription;
  userContext: UserContext;
  otpId: any;
  disableOtp: boolean;
  passwordError: string;
  userNameError: string;
  lockedError: string;
  checkStateLogin: any;
  resentRedirect: boolean;
  constructor(
    private loginService: SigninService,
    private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private navbarService: NavbarService,
    protected notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private userRoleService: UserRoleService,
    public spinners: NgxSpinnerService,
    private encryptionService: EncryptService
  ) { }

  ngOnInit(): void {
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.loadCaptcha();
    // this.testintegration();
    // if already authenticated then navigate to home page
  }

  testintegration() {
    this.loginService.testintegration().subscribe((res: any) => {
      console.log("nic", res);
    }, (error) => { console.log("nic", error); });

  }
  loadCaptcha() {
    this.spinner = true;
    this.loginForm.controls['captcha'].setValue('');
    this.loginService.getCapthcaImage().subscribe(
      (res: any) => {
        this.spinner = false;
        this.captchaDetails = JSON.parse(res);
        let objectURL =
          'data:image/jpeg;base64,' + this.captchaDetails.captchaImage;
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      },
      (onError) => {
        this.spinner = false;
      }
    );
  }
  startTimer() {
    clearInterval(this.timer);
    this.otpTimer = 60;
    this.timer = setInterval(() => {
      this.otpTimer--;

      if (this.otpTimer === 0) {
        this.stopTimer();
        this.otpError = null;
      }
    }, 1000);
  }

  stopTimer() {
    if (this.reSendCount < 6) {
      this.enableResendButton = true;
      this.reSendCount = this.reSendCount + 1;
    } else {
      this.logout();
      this.showOtp = false;
      this.loginForm.reset();
      this.otpError = null;
      this.reSendCount = 1;
      this.otpForm.controls['otp'].setValue('');
    }
    clearInterval(this.timer);
  }

  ngAfterViewInit(): void {
    // this.username.nativeElement.focus();
  }
  navToForgotPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ForgotPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    } else {
      this.router.navigate(['/ForgotPassword']);
    }
  }
  navToResetPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ResetPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    } else {
      this.router.navigate(['/ResetPassword']);
    }
  }

  loginOtp(): void {
    this.spinners.show();
    if (!this.otpForm.get('otp')!.value) {
      this.notificationService.alertError('Invalid Otp', '');
      return;
    }
    this.loginService
      .otpvalidate(
        this.encryptDatatest(this.otpForm.get('otp')!.value),
        this.otpId
      )
      .subscribe(
        (res: any) => {
          this.spinners.hide();
          if (res === 'Correct') {
            if (this.passwordReset == 'Yes') {
              this.navToResetPassword();
            } else {
              this.router.navigateByUrl('home');
            }
          } 
          // else {
          //   if (res === 'Maximum Attempt Reached') {
          //     this.disableOtp = true;
          //   }
          //   this.otpError = res;
          // }
        },
        (onError) => {
          console.log('dkdkkdkdkdkdkdkdk', onError)
          this.spinners.hide();
          if (onError.status === 400) {
            this.otpError = onError.error;
            if(this.resentRedirect && onError.error === 'Maximum Attempt Reached'){
              this.otpError = null;
              this.navToLogin();
            }else if(onError.error === 'Maximum Attempt Reached') {
              this.disableOtp = true;
              this.stopTimer();
              this.enableResendButton = true;
            }
          }
        }
      );
  }
  login(): void {

    this.inValidCaptcha = false;
    this.inValidUser = false;
    this.captchaExpireError = null;
    this.passwordError = null;

    this.userNameError = null;

    this.passwordError = null;

    this.lockedError = null;

    if (
      !this.loginForm.get('username')!.value ||
      !this.loginForm.get('password')!.value
    ) {
      this.inValidUser = true;
      return;
    }
    if (!this.loginForm.get('captcha')!.value) {
      this.inValidCaptcha = true;
      return;
    }
    if (!this.passwordPolicyValidator(this.loginForm.get('password')!.value)) {
      // Display an error message or handle the password policy violation
      this.passwordError =
        'Password must be a minimum of 8 characters including Number, Upper, Lower and one special character';
      return;
    }
    this.spinners.show();
    let payload = new authModel();
    payload.username = this.loginForm.get('username')!.value;
    payload.password = this.loginForm.get('password')!.value;
    payload.rememberMe = true;
    payload.captchaText = this.loginForm.get('captcha')!.value;
    payload.id = this.captchaDetails.captchaId;
    this.loginService
      .login({ requestPayload: this.encryptDatatest(JSON.stringify(payload)) })
      .subscribe(
        () => {
          // this.sharedService.getMasterService();
          this.getUserrole();
          this.spinners.hide();
        },
        (onError) => {
          console.log('sadasdsadsadasdsadsadasdsa', onError);
          if (onError.status === 500) {
            this.userNameError = 'Invalid Username';
          } else if (onError.status === 404) {
            this.passwordError = 'Invalid Password';
          } else if (onError.status === 400) {
            this.lockedError =
              'Account Locked! Please click the Forget Password and reset your password';
          } else if (onError.status === 401) {
            this.lockedError = 'Invalid Credentials';
          } else if (onError.status === 409) {
            this.lockedError = 'User disabled by department';
          } else if (onError.status === 406) {
            this.lockedError = 'User already login';
          }
          else if (onError.status === 417) {
            this.lockedError = 'Captcha Expired, Please Re-enter Captcha';
          } else if (onError.status === 422) {
            this.lockedError = 'Captcha Invalid';
          }
          this.spinners.hide();
        }
      );
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.passwordReset = res.body.resetPassword;
        this.profileStatus = res.body.profileStatus;
        localStorage.setItem('userId', this.encryptionService.encryptData(res.body.id));
        localStorage.setItem('roleCodevalue', this.encryptionService.encryptData(res.body.role.code));
        localStorage.setItem('roleCode', res.body.role.code);
        this.userRoleService.setUserRole(res.body.role.code, res.body.role.name);
        this.passwordReset = res.body.resetPassword;
        this.profileStatus = res.body.profileStatus;
        this.checkStateLogin = res.body.role.code;
        if (this.checkStateLogin !== 'SA') {
          if (this.passwordReset == 'Yes') {
            this.navToResetPassword();
          } 
          this.getUserOtp();
          this.startTimer();
        } else {
          this.showOtp = false;
          if (this.passwordReset == 'Yes') {
            this.navToResetPassword();
          } else {
            this.router.navigateByUrl('home');
          }
        }
      },
      (onError) => { }
    );
  }

  getUserOtp(resend?) {
    this.loginService.getOtp().subscribe(
      (res) => {
        this.spinners.hide();
        this.otpId = res;
        if (resend) {
          this.disableOtp = false;
          this.otpError = null;
          this.enableResendButton = false;
          this.startTimer();
          this.resentRedirect = true;

        } else {
          this.showOtp = true;
        }
      },
      (onError) => {}
    );
  }

  
  navToLogin() {
    this.logout();
    this.showOtp = false;
    this.loginForm.reset();
  }
  logout(): void {
    this.loginService.stopRefreshTokenTimer();
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
        this.localStorageService.clear();
        this.sessionStorageService.clear();
      },
      (err) => { }
    );
  }

  passwordPolicyValidator(password: string): boolean {
    const minLength = 8;
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );
    return password.length >= minLength && hasSpecialChar;
  }

  encryptDatatest(data: string): string {
    const captchaLength = 16; // Specify the desired captcha length
    const saltCharacters =
      'abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890';
    let captchaStr = '';

    while (captchaStr.length < captchaLength) {
      const index = Math.floor(Math.random() * saltCharacters.length);
      captchaStr += saltCharacters.charAt(index);
    }
    const captcha = captchaStr;
    console.log(captcha);
    console.log(captcha.toString());
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(captcha);
    const paddedData = this.zeroPadData(data).toString();
    const encryptedData = CryptoJS.AES.encrypt(paddedData, key, {
      iv: iv,
    }).toString();
    const encryptedTextWithIV = captcha.toString() + encryptedData.toString();

    const base64CipherText1 = encryptedData.toString(CryptoJS.enc.Base64);

    console.log('base64CipherText1' + base64CipherText1);

    const base64CipherText = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encryptedTextWithIV)
    );
    console.log('base64CipherText', base64CipherText);
    return base64CipherText;
  }

  zeroPadData(data: string): string {
    const blockSize = 16;
    const paddingLength = blockSize - (data.length % blockSize);
    const paddedData = data + '\x00'.repeat(paddingLength);
    return paddedData;
  }


  //   async encrypt(value: string){
  //     // const publicKeyText = await this.loginService.loadPemFile("/assets/public_key.pem");
  //     // const publicKey = forge.pki.publicKeyFromPem(publicKeyText);
  //     // const encryptedValue = publicKey.encrypt("hello", 'RSA-OAEP');
  //     // const encryptedValueBase64 = forge.util.encode64(encryptedValue);

  //     const encryptRsa = new EncryptRsa();
  // const publicKeyText = await this.loginService.loadPemFile('/assets/public_key.pem');
  // const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({
  //   text: 'hello world',
  //   publicKey,
  // });

  //   }

  // async loadPemFile() {
  //   const pemContent = await this.loginService.loadPemFile("/assets/mypublickey.der");
  //   const data = 'my secret data';
  //   const ciphertext = CryptoJS.AES.encrypt('my secret data', pemContent, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7
  //   }).toString();
  //   // const encrypted = CryptoJS.AES.encrypt(data, pemContent);
  //   // const rsa = Forge.pki.publicKeyFromPem(pemContent);
  //   // const publicKey = window.btoa(rsa.encrypt('stateadmin1'));
  // }
  //   encryptData(data) {
  //     this.loadPemFile()

  //     // const plaintext = 'This is my plaintext data';
  //     // const paddedPlaintext = pad.pkcs7.encrypt(enc.Utf8.parse(plaintext), {
  //     //   blockSize: 16,
  //     // });
  //     // const encrypted = AES.encrypt(paddedPlaintext, environment.encryption_Key, {
  //     //   iv: iv,
  //     // });
  //     // const ciphertext = encrypted.ciphertext.toString(enc.Base64);
  //   }
  // encrypt(text: string){

  //   const message = 'Hello, world!';
  //   const key = 'secret key 123';
  //   const encryptedMessage = CryptoJS.AES.encrypt(message, environment.encryption_Key).toString();

  // }
}