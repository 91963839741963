import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  fontClass: string = '';
  headingClass: string = '';
  labelClass: string = '';
  smallClass: string = '';
  buttonClass: string = '';
  regularClass: string = '';
  regularBoldClass: string = '';
  dropDownClass: string = '';
  tabClass  : string = '';

  setFontClass(lang: string) {
    if (lang === 'en') {
      this.headingClass = 'english-heading';
      this.fontClass = 'english-font';
      this.labelClass = 'english-label';
      this.smallClass = 'english-small';
      this.buttonClass = 'english-button';
      this.regularClass = 'english-regular';
      this.regularBoldClass = 'english-regular-bold';
      this.dropDownClass = 'english-dropdown';
      this.tabClass = 'english-tab';
    } else {
      this.headingClass = 'tamil-heading';
      this.fontClass = 'tamil-font';
      this.labelClass = 'tamil-label';
      this.smallClass = 'tamil-small';
      this.buttonClass = 'tamil-button';
      this.regularClass = 'tamil-regular';
      this.regularBoldClass = 'tamil-regular-bold';
      this.dropDownClass = 'tamil-dropdown';
      this.tabClass = 'tamil-tab';
    }
  }
}