import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SigninService } from '../../auth/signin/signin.service';
import { PaymentService } from '../../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage4',
  templateUrl: './stage4.component.html',
  styleUrls: ['./stage4.component.scss']
})
export class Stage4Component implements OnInit {

  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;

  products = [
    {
      id: '1000',
      code: '20000000',
      name: '1000000',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: '5000000',
      quantity: 5000000,
      inventoryStatus: 'INSTOCK',
      rating: 5
    },

  ];

  filterProps: any;
  commercialList: Project[];
  stage2SearchFilter: any = {
    project: '',
  }
  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  visible = false
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  requestingDepartment: any = ''
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;
  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  totalLandPrivate: any;
  throughAct: number;
  taluk: any[];
  villages: any[];
  talukId: any;
  villageID: any;
  beneficiaryData: any;
  projectName: any;
  amountDetails: any;
  temp: any[] = [];
  totalAmount: number = 0;
  districkList: any;
  selectDistrictId: any;
  availableAmount: any = {};
  enableGenerateVoucher: boolean = true;
  isApprove: boolean;
  VoucherNo: string;
  param1: any;
  enableSignButton: boolean;
  param2: any;
  param3: any;
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private projectService: ProjectService
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }


  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];

  getProjects() {
    this.projectService.getProjectListforStage4().subscribe(
      (res: any) => {

        this.projectList = res.body.data;

      },
      () => {
        // this.onError();
      }
    );
  }


  confirm1() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: (type) => {

      }
    });
  }

  getExtent(name) {
    if (name == 'projectName') {
      this.stage2SearchFilter.district = '',
        this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.totalExtent = ''
    }
    else if (name == 'district') {
      this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.totalExtent = ''
    } else if (name == 'taluk') {
      this.stage2SearchFilter.village = ''
      this.stage2SearchFilter.totalExtent = ''
    } else if (name == 'village') {
      this.stage2SearchFilter.totalExtent = ''
    }

    let filterQuery = {
      project: this.stage2SearchFilter.project.id,
      district: this.stage2SearchFilter.district.id,
      taluk: this.stage2SearchFilter.taluk.id,
      village: this.stage2SearchFilter.village.id,
    };

    if (filterQuery.project != undefined && filterQuery.district && filterQuery.taluk && filterQuery.village) {
      this.projectService.stage2Filter(filterQuery).subscribe((res: any) => {
        this.stage2SearchFilter.totalExtent = res.body.data.totalExtent
      })

    }
    //conso.log(filterQuery, 'filterQuery');




  }

  generateVoucher(e, data) {
    Object.assign(data, { 'voucherGeneration': "TRUE" });
    if (e.checked) {
      this.enableGenerateVoucher = false
      if (this.temp.length != 0) {
        let isCheck = this.temp.every((currentValue) => currentValue.id != data.id)
        if (isCheck) {
          this.temp.push(data)
        }
      } else {
        this.temp.push(data)
      }
    } else {
      let delIndex = this.temp.findIndex(element => element.id == data.id);
      this.temp.splice(delIndex, 1)
      if (this.temp.length == 0) {
        this.enableGenerateVoucher = true;
      } else {
        this.enableGenerateVoucher = false;
      }
    }

  }

  getDate(data){
    console.log(data);
    
  }

  getSurvey() {
    let filterQuery = {
      project: this.stage2SearchFilter.project?.id,
      district: this.stage2SearchFilter.district?.id,
      taluk: this.stage2SearchFilter.taluk?.id,
      village: this.stage2SearchFilter.village?.id,
    };
    console.log(filterQuery,'filter');
    
    if (filterQuery.project && filterQuery.district && filterQuery.taluk && filterQuery.village) {
      this.spinner.show()

      this.projectService.stage4Filter(filterQuery).subscribe((res: any) => {
        this.beneficiaryData = res.body.data.beneficiaries
        this.totalAmount = 0
        this.amountDetails = res.body.data.funds
        this.totalCount = res.body.totalCount;
        this.spinner.hide()
        this.beneficiaryData.forEach(res => {
          this.totalAmount += Number(res.totalAmount);
        }
        )
        this.isShowTable = true
      }, (err) => {
        this.notificationService.alertError(
          'Alert',
          err.error.error
        );
        this.spinner.hide()
      }
      )
    } else{
      this.notificationService.alertError(
        'Alert',
        'Please select all the details'
      );
    }
  }
  submit() {
    let beneficaryData = this.projectForm.value
    let landFrom = []
    this.projectService.saveVoucherGenerate(this.temp).subscribe(
      (res) => {
        this.notificationService.alertSuccess('Voucher Generated successfully and downloaded.', '');
        this.forms();
        this.isApprove = true;
        this.addSkills();
        this.router.navigate(['/stage5'], { state: { example: this.stage2SearchFilter } })
        this.isShowTable = false
        this.stage2SearchFilter = {}
        this.VoucherNo = res.body;
        // this.getfile();
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );

  }

  getfile() {
    this.projectService
      .getEmudrarequest(this.VoucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {

          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          // if(!this.voucherGenerationDto.vendorTransactionDto.id){
          //   this.router.navigate(['/PaymentVoucher'], {
          //     queryParams: {
          //       id: res.body.paramFour
          //     },
          //   });
          // }
          // add more parameters as needed
        },
        () => { }
      );
  }

  recommended() {
    this.projectService.recommend(this.temp, this.projectId).subscribe(
      (res: any) => {
        this.visible = true
        this.availableAmount = res.body.data
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );

  }

  hide(){
    this.temp=[]
  }

  selectProject(e) {
    this.projectName = e.projectName
    this.projectId = e.id
    this.projectService.talukAndVillageforStage4(e.id, '', '', '').subscribe((res: any) => {
      this.districkList = res.body.data
    })
  }

  SelectDistrict(e) {
    this.selectDistrictId = e.id
    this.projectService.talukAndVillageforStage4(this.projectId, this.selectDistrictId, '', '').subscribe((res: any) => {
      //conso.log(res);
      this.taluk = res.body.data
    })
  }

  OnSelectTaluk(e) {
    this.talukId = e.id
    //conso.log(e);

    this.projectService.talukAndVillageforStage4(this.projectId, this.selectDistrictId, this.talukId, '').subscribe((res: any) => {
      this.villages = res.body.data
    })

  }
  OnSelectVillage(e) {
    this.villageID = e.id
    this.projectService.talukAndVillageforStage4(this.projectId, this.selectDistrictId, this.talukId, this.villageID).subscribe((res: any) => {
      this.villages = res.body.data
    })
  }
  ngOnInit() {
    this.forms();
    this.addSkills();
    this.getProjects()
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.districtId = res.body.levelMasterId
        // this.onSelectDistrict(res.body.levelMasterId)

      })
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
    // this.loginService.getuserDto().subscribe(
    //   (res) => {
    //     this.user = res.body;
    //   },
    //   (onError) => {}
    // );
    this.cols = [
      {
        field: 'projectName',
        header: 'Name of the project',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'agencyId',
        header: 'Requesting Agency',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDowns',
      },
      {
        field: 'landUnitId',
        header: 'Lant Unit',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'type',
      },

      {
        field: 'tentativeLandCost',
        header: 'Project Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);
  }

  getUserrole() {

  }
  addSkill(i) {
    let totalLand: number = 0;
    let form = this.projectForm.valid
    if (form) {
      this.detailsOfLand.push(this.detailsform());
    }
  }

  forms() {
    this.projectForm = this.formBuilder.group({
      LandDetailsForm: this.formBuilder.array([]),
    })
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      surveyNo: ['', Validators.required],
      subdivisionNo: ['', Validators.required],
      tamilnilam: ['', Validators.required],
      acquireAsAward: ['', Validators.required],
      asPerAct: ['', Validators.required],
      asPerPrivateNego: ['', Validators.required],
      asPerActCost: ['', Validators.required],
      asPerPrivateNegoCost: ['', Validators.required],
      totalCompensation: ['', Validators.required]
    });
  }


  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('asPerAct').value);
    let throughAct: any = Number(this.detailsOfLand.controls[i].get('asPerPrivateNego').value)
    let total: any = privateNegotitaion + throughAct
    if (privateNegotitaion != '' && throughAct != '') {
      //conso.log(privateNegotitaion)
      this.detailsOfLand.controls[i].get('totalCompensation').setValue(total);

    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  addlandDetails() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.totalCompensation);
    });
    //conso.log(totalLand, 'totalLand');

    if (totalLand != 0) {
      if (this.stage2SearchFilter.totalExtent >= totalLand) {
        if (totalLand == this.stage2SearchFilter.totalExtent) {
          this.notificationService.alertInfo(
            'Equal',
            'Acquired land in the district is equal to total amount of land'
          );
        } else {
          this.detailsOfLand.push(this.detailsform());
        }
      } else {
        this.notificationService.alertError(
          'Alert',
          "Total Compensation determined for this survey number should not exceed Total extent Acquired"
        );
      }
    }
  }

  // getTotal(i) {
  //   //conso.log(i);
  //   let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('privateNegotitaion').value);
  //   let throughAct: any = Number(this.detailsOfLand.controls[i].get('throughAct').value)
  //   let total: any = privateNegotitaion + throughAct
  //   if (privateNegotitaion != '' && throughAct != '') {
  //     //conso.log(privateNegotitaion)
  //     this.detailsOfLand.controls[i].get('total').setValue(total);

  //   }
  //   let totalLand: number = 0;
  //   let act: number = 0
  //   this.detailsOfLand.value.forEach((element) => {
  //     totalLand += Number(element.privateNegotitaion);
  //     act += Number(element.throughAct);
  //   });

  //   this.totalLandPrivate = totalLand
  //   this.throughAct = act
  // }


  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  ngAfterViewInit() {
  }

}