import { AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountSnaService } from './account-sna.service';
import { AccountSna, AccountSnaDto } from './account-sna';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { DepartmentListService } from './department-list.service';
import { SchemeListService } from './scheme-list.service';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BankDetailsService } from '../bank-details.service';
import { EncryptService } from 'src/app/shared/encrypt.service';
@Component({
  selector: 'app-account-sna',
  templateUrl: './account-sna.component.html',
  styleUrls: ['./account-sna.component.scss']
})
export class AccountSnaComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;

  accountSnaDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<AccountSna> = new SearchResult<AccountSna>();
  accountSna?: AccountSnaDto;
  selectedPersonalVendors: AccountSna[] = [];
  submitted?: boolean;
  selectedMapping: string;
  departmentList: any[];
  schemeList: any[];
  readonly: boolean = false
  itemsPerPage: any = 1;
  page: number;
  filterProps: any;
  accountChange: any = false;

  // validation fields properties
  public showMobileError = false;
  public emailError = false;
  public ifscError = false;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;
  @ViewChildren(NgSelectComponent) ngSelectComponents: QueryList<NgSelectComponent>;
  isAdd: any;
  isView: any;
  isEdit: boolean;
  isDelete: boolean;
  snaAccount: AccountSna[];
  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const targetElement = event.target as HTMLElement;
    const isInsideNgSelect = targetElement.closest('.ng-option');
    if (!isInsideNgSelect) {
      this.ngSelectComponents.forEach((ngSelect: NgSelectComponent) => {
        ngSelect.close();
      });
    }
  }
  constructor(
    private accountSnaService: AccountSnaService,
    private confirmationService: ConfirmationService,

    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private bankdetailsService: BankDetailsService,
    private encryptionService: EncryptService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }


  ngOnInit() {

    this.loadPage(1);
  }
  setRoleBasedCondition() {
    let val = this.encryptionService.decryptData(localStorage.getItem('menuDataRole'))
    //console.log(val,"menu")
    let menu = [];
    menu = JSON.parse(val);
    //console.log(menu)
    let complete: boolean = false
    let completesubMenu: boolean = false
    let submenu = []
    let role = [];
    for (let i = 0; i < menu.length && complete == false; i++) {
      // console.log(menu[i])
      if (menu[i].menuTab === 'ACCOUNT') {
        // console.log(menu[i])
        submenu.push(menu[i].subMenus);


        // console.log(submenu)
        for (let j = 0; j < submenu[0].length && completesubMenu == false; j++) {

          if (submenu[0][j].subMenuTab === 'SNA') {
            role.push(submenu[0][j]);
            this.isAdd = role[0].crud.create;
            this.isView = role[0].crud.read;
            if (role[0].crud.update == false) {
              this.isEdit = true
            } else {
              this.isEdit = false
            }
            if (role[0].crud.delete == false) {
              this.isDelete = true
            } else {
              this.isDelete = false
            }
            completesubMenu = true
          } else {
            completesubMenu = false
          }
          // console.log(this.isAdd,this.isDelete,this.isEdit,this.isView)

        }
        complete = true
      } else {
        complete = false
      }

    }


  }
  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.readonly = false;
    this.accountSna = new AccountSnaDto();
    this.accountSnaDialog = true;
    this.loadValue()
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.ifscError = false;
    this.ngSelectInvalid = false;
    this.accountSna = new AccountSnaDto();
    this.accountSnaDialog = false;
    this.submitted = false;

  }

  loadValue() {
    this.departmentListService
      .filter()
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.departmentList = res.body
        },
        () => {
          this.onError();
        }
      );
    this.schemeListService
      .filter()
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.schemeList = res.body
        },
        () => {
          this.onError();
        }
      );
  }


  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(this.accountSna.managerMobNo);
    !this.accountSna.managerMobNo ? this.showMobileError = false : true;

    return !this.showMobileError && !!this.accountSna.managerMobNo;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.accountSna.branchEmail)
    !this.accountSna.branchEmail ? this.emailError = false : true;
    return !this.emailError && !!this.accountSna.branchEmail;

  }
  getBankBranchdetails() {
    this.bankdetailsService.getBankDetails(this.accountSna.ifscCode)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.accountSna.branch = res.body.BRANCH;
          this.accountSna.bankName = res.body.BANK;
          // this.accountSna.micr = res.body.MICR;
        },
        () => { }
      );
  }
  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(this.accountSna.ifscCode)
    if (!this.ifscError) {
      this.getBankBranchdetails();
    }
    !this.accountSna.ifscCode ? this.ifscError = false : true;
    return !this.ifscError && !!this.accountSna.ifscCode;


  }

  saveSna() {
    this.accountSna.departmentDto = {
      id: 2
    },
      this.accountSna.schemeDto = {
        id: 1
      }
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validateIfsc() &&
      this.accountSna.departmentDto &&
      this.accountSna.schemeDto &&
      this.accountSna.name &&
      this.accountSna.code &&
      this.accountSna.shortName &&
      this.accountSna.accountNumber &&
      this.accountSna.ifscCode &&
      this.accountSna.branch &&
      this.accountSna.address &&
      this.accountSna.managerMobNo &&
      this.accountSna.managerName &&
      this.accountSna.branchEmail


    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandatory fields", '')
      return;
    }
    this.ngSelectInvalid = false;
    if (this.accountChange) {
      if (!this.accountSna.retypebankAccountNo) {
        this.ngSelectInvalid = true;
        return;
      }
      if (
        this.accountSna.accountNumber === this.accountSna.retypebankAccountNo
      ) {
        this.accountSnaService
          .save(this.accountSna)
          .subscribe(
            (res: HttpResponse<AccountSnaDto[]>) => {
              this.accountSnaDialog = false;
              this.loadPage(this.page);
              this.notificationService.alertSuccess('Saved Successfully', '');
            },
            () => {
              this.onError();
              this.notificationService.alertError('Error!', '');
            }
          );
      }
      else {
        this.notificationService.alertError("Account number mismatch", '')
      }
    }
    this.accountSnaService
      .save(this.accountSna)
      .subscribe(
        (res: HttpResponse<AccountSnaDto[]>) => {
          this.accountSnaDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onSelectRow($event) {
    //console.log($event)

    if ($event.type === "select") {
      this.accountSnaService
        .getSna($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.accountSna = res.body
            this.accountSnaDialog = true;
            this.loadValue()
          },
          () => {
            this.onError();
          }
        );
    }

    else if ($event.type === "View") {
      this.accountSnaService
        .getSna($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.accountSna = res.body
            this.accountSnaDialog = true;
            this.readonly = true;
            this.loadValue()
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      this.accountSnaService
        .delete($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.loadPage(this.page)
          },
          () => {
            this.onError();
          }
        );
    }
  }

  delete(id) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinner.show()
        this.accountSnaService.delete(id).subscribe(res => {
          this.notificationService.alertSuccess("Account Deleted", '')
          this.loadPage()
          this.spinner.hide()
        }, (err) => {
          this.spinner.hide()
          this.notificationService.alertError(err.error.error, '')
        })
      },
    });
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? []
    this.accountSnaService
      .getAllSnaList()
      .subscribe(
        (res: HttpResponse<AccountSna[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: AccountSna[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide()
    this.page = page;
    if (navigate) {
      this.router.navigate(["/account-sna"], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,

        },
      });
    }
    this.snaAccount = data ?? [];
    console.log(this.snaAccount);

    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
  }

  onLazyLoad(event) {
    if (event) {
      this.filterProps = event.filterDefinitions
      this.itemsPerPage = event.size;
      this.loadPage(event.page);
    }

  }
}
