import { ResetService } from './../../auth/reset-password/reset.service';
import { AuthUser, ResetPassword } from './../../auth/reset-password/authuser';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserService } from '../checker-project/checker.service';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  login?: AuthUser;
  keyCloakUuid:string;
   public pwdMismatch:boolean=false;
  phide:boolean=true;
  cphide:boolean=true;
  oldhide:boolean=true;
    resetPwdForm = this.fb.group({
      oldPassword: [null,[Validators.required]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
    passwordError: string;
  userName: any;
    constructor( private fb: FormBuilder,private route: ActivatedRoute,private router: Router,
      private resetService: ResetService,private notificationService:NotificationService,
      private navbarService:NavbarService,private localStorageService:LocalStorageService,
      private sessionStorageService:SessionStorageService,private encryptService:EncryptService,private loginService: SigninService,private userService: UserService,) { }
  
  
  
    ngOnInit(): void {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.userName = res.body.userName;
        },
        (onError) => {}
      );
      
    }
    get password(){
      return this.resetPwdForm.get('password')!.value;
    }
    get confirmPassword(){
      return this.resetPwdForm.get('confirmPassword')!.value;
    }
    navToLogin() {
      this.logout();
    }

   
    submit(){
      this.pwdMismatch=false;
      this.passwordError=null;
   
      const passwordDto = new ResetPassword();
      if (this.resetPwdForm.invalid) {
      return;
      }
      if (
        !this.passwordPolicyValidator(
          this.resetPwdForm.get("confirmPassword")!.value
        )
      ) {
        // Display an error message or handle the password policy violation
        this.passwordError =
          "Password must be a minimum of 8 characters including Number, Upper, Lower and one special character";
        return;
      }
        if(this.password==this.confirmPassword){
          console.log('sshhssshshshshhshs',this.resetPwdForm)
          passwordDto.UserName = this.encryptService.encryptData(this.userName);
          passwordDto.currentPassword = this.encryptService.encryptData(
            this.resetPwdForm.get("confirmPassword")!.value
          );
          passwordDto.oldPassword = this.encryptService.encryptData(
            this.resetPwdForm.get("oldPassword")!.value
          );
     
      this.userService.changePassword(passwordDto).subscribe(
        (res: any) => {
          this.notificationService.alertSuccess('Changed Successfully', '');
          this.logout();
        },
        () => {
          this.notificationService.alertError('Something went wrong', '');
        }
      );
        }
        else{
          this.pwdMismatch=true;
        }  
   
    }
  
    passwordPolicyValidator(password: string): boolean {
      const minLength = 8;
      const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
        password
      );
      return password.length >= minLength && hasSpecialChar;
    }
  
    logout(): void {
      this.navbarService.logout().subscribe(
        (res: HttpResponse<any>) => {},
        (err) => {}
      );
      this.localStorageService.clear();
      this.sessionStorageService.clear();
      this.router.navigate(['login']);
    }
  }


