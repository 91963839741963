<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<header *ngIf="showHead">
  <ib-navbar></ib-navbar>
</header>
<section id="content">
  <app-alert></app-alert>
  <router-outlet></router-outlet>
</section>  
<!-- <footer *ngIf="showHead">
  <app-footer></app-footer>
</footer> -->
<p-dialog header="Profile" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
  [resizable]="false">
  <div class="row">
    <div class="col-3">
      <h6><b>Name:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.name}}</h6>
    </div> 
    <div class="col-3">
      <h6><b>Mobile No:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.mobileNo}}</h6>
    </div> 
  </div>
  
  <div class="row mt-2">
    <div class="col-3">
      <h6><b>Date of Birth:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.dateOfBirth}}</h6>
    </div> 
    <div class="col-3">
      <h6><b>Aadhaar No:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.aadhaarNo}}</h6>
    </div> 
  </div>
 
  <div class="row mt-2">
    <div class="col-3">
      <h6><b>Pan No:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.panNo}}</h6>
    </div> 
    <div class="col-3">
      <h6><b>District:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.district}}</h6>
    </div> 
  </div>
  <div class="row mt-2">
    <div class="col-3">
      <h6><b>Email:</b></h6>
    </div>
    <div class="col-3">
      <h6>{{userProfile?.emailId}}</h6>
    </div> 
  </div>
</p-dialog>