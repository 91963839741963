import { AgencyDto } from './../agency/agency';
import { LevelMaster } from './../level-master/level-master';
import {
  limitTransList,
  FinYear,
  FormulaTabelModel,
  limitTransDto,
} from './limit-translist.module';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<limitTransList[]>;

@Injectable({ providedIn: 'root' })
export class LimitTransListService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getIAComponentLimitTransByFilter';
  protected resourceValidFliterUrl =
    environment.SERVER_API_URL + 'api/getIAComponentLimitTransValidByFilter';
  protected getLevelMaster =
    environment.SERVER_API_URL + 'api/getLevelMasterByLevelTypeId';
  protected getComponentbyLevelById =
    environment.SERVER_API_URL + 'api/getComponentTypeByLevelId';
  protected getComponentbyLevelByIdGf =
    environment.SERVER_API_URL + 'api/getComponentTypeByLevelIdGf';
  protected getImplementingAgencyByLevelMasterId =
    environment.SERVER_API_URL + 'api/getImplementingAgencyByLevelMasterId';
  protected getfinYearList = environment.SERVER_API_URL + 'api/finYearList';
  protected calcFormula = environment.SERVER_API_URL + 'api/formulaCalculation';
  protected formulaTableFilter =
    environment.SERVER_API_URL + 'api/getIAComponentLimitFormulaTempGFByFilter';
  protected getStatusUrl = environment.SERVER_API_URL + 'api/statusCheck';
  protected schedulerMethod =
    environment.SERVER_API_URL + 'api/schedulerMethod';
  protected savelimittransUrl = environment.SERVER_API_URL + 'api/saveLimitTrans';
  protected getAmountAndLBCUrl =
    environment.SERVER_API_URL + 'api/getAmountByLevelMasterAndScheme';
  protected getAmountByLevelMasterAndSchemeLimitUrl =
    environment.SERVER_API_URL + 'api/getAmountByLevelMasterAndSchemeLimit';
  protected getlevelTypeListByLevelUrl =
    environment.SERVER_API_URL + 'api/levelTypeListByLevel';
    protected getlevelTypeListUrl =
    environment.SERVER_API_URL + 'api/levelTypeList';
  protected saveDistrictBudgetAllocationUrl =
    environment.SERVER_API_URL + 'api/saveDistrictBudgetAllocation';
  protected DistrictBudgetAllocationByFilterUrl = 
  environment.SERVER_API_URL + 'api/DistrictBudgetAllocationByFilter';
  protected getLimitByFinYearUrl =
  environment.SERVER_API_URL + 'api/getLimitByFinYearId'
  protected getSubHeadLimitTransUrl = environment.SERVER_API_URL + 'api/getLimitBasedOnFinYearAndSchemeAndSubHead';
  protected getIALevelmasterUrl = environment.SERVER_API_URL + 'api/getImplementingAgencyByLevelMasters';
  protected getSubHeadLimitAmountUrl = environment.SERVER_API_URL + 'api/getSubComponentData';
  protected getHeadLimitAmountUrl = environment.SERVER_API_URL + 'api/getComponentMasterData';
  protected getBudgetAllocationAmount = environment.SERVER_API_URL + 'api/iaLimitAllocation';
  protected getLevelMasters = environment.SERVER_API_URL + 'api/levelMasterByLevelId';
  protected getCategorys = environment.SERVER_API_URL + 'api/componentTypeList?schemeId=1';

  protected getComponent= environment.SERVER_API_URL + 'api/componentTypeList?schemeId=1';
  protected getDistrictBudgetAllocationAmount = environment.SERVER_API_URL + 'api/districtBudgetAllocation';
  protected getApproveFundsStatus = environment.SERVER_API_URL+('api/iaLimitTransStatus');
  // protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveProfile');
  // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
  constructor(protected http: HttpClient) {}
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<limitTransList[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  vaildFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<limitTransList[]>(
      this.resourceValidFliterUrl,
      filter,
      { params: options, observe: 'response' }
    );
  }
  approveFunds(id: number,status:any) {
    return this.http.post(`${this.getApproveFundsStatus}?id=${id}&status=${status}`,{}, { observe: 'response' , responseType:'text'});
  }
  districtFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<limitTransList[]>(
      this.DistrictBudgetAllocationByFilterUrl,
      filter,
      { params: options, observe: 'response' }
    );
  }
  getLevelMasterById(id: any): Observable<EntityArrayResponseType> {
    return this.http.get<any>(this.getLevelMaster, {
      params: { id: id },
      observe: 'response',
    });
  }
  getLevelMasterFilter(): Observable<EntityArrayResponseType> {
    return this.http.get<any>(this.getLevelMasters, {
      observe: 'response',
    });
  }
  getComponentType(): Observable<EntityArrayResponseType> {
    return this.http.get<any>(this.getLevelMasters, {
      observe: 'response',
    })
  }

  getCategory(): Observable<EntityArrayResponseType> {
    return this.http.get<any>(this.getCategorys, {
      observe: 'response',
    })
  }
  getlevelTypeListByLevel(): Observable<any> {
    return this.http.get<any>(this.getlevelTypeListByLevelUrl, {
      observe: 'response',
    });
  }

  getlevelTypeList(id?): Observable<any> {
    return this.http.get<any>(`${this.getlevelTypeListUrl}`, {
      observe: 'response',
    });
  }

  

  getComponentbyLevel(id: any): Observable<any> {
    return this.http.get<any[]>(this.getComponentbyLevelById, {
      params: { id: id },
      observe: 'response',
    });
  }

  getComponentbyLevelGf(id: any): Observable<any> {
    return this.http.get<any[]>(this.getComponentbyLevelByIdGf, {
      params: { id: id },
      observe: 'response',
    });
  }

  getImplementingAgencyByLevelMaster(id: any): any {
    return this.http.get<AgencyDto[]>(
      this.getImplementingAgencyByLevelMasterId,
      { params: { id: id }, observe: 'response' }
    );
  }

  getFinYear(): Observable<any> {
    return this.http.get<FinYear[]>(this.getfinYearList, {
      observe: 'response',
    });
  }

  calcAmountByFormula(limitTransListDto): Observable<any> {
    return this.http.post<any>(this.calcFormula, limitTransListDto, {
      observe: 'response',
    });
  }

  tableFormulaFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<FormulaTabelModel[]>(
      this.formulaTableFilter,
      filter,
      { params: options, observe: 'response' }
    );
  }

  getStatus(levelTypeDto: any): any {
    return this.http.post<any>(this.getStatusUrl, levelTypeDto, {});
  }

  schedule(levelTypeDto: any): any {
    return this.http.post<any>(this.schedulerMethod, levelTypeDto, {});
  }

  save(savelimittrans?: any): Observable<any> {
    return this.http.post<limitTransDto[]>(
      this.savelimittransUrl,
      savelimittrans,
      { observe: 'response' }
    );
  }

  getAmountAndLBC(req?: any): Observable<any> {
    return this.http.post<any[]>(this.getAmountAndLBCUrl, req, {
      observe: 'response',
    });
  }

  getBlockLimit(req?: any): Observable<any> {
    return this.http.post<any[]>(this.getAmountByLevelMasterAndSchemeLimitUrl, req, {
      observe: 'response',
    });
  }

  saveDistrictBudget(savelimittrans?: any) {
    return this.http.post(this.saveDistrictBudgetAllocationUrl,savelimittrans , {
      observe: 'response',
      responseType: 'text',
    });
  }

  getLimitByFinYear(id:any, schemeId:any){
    return this.http.get<any>(
      this.getLimitByFinYearUrl,
      { params: { finYearId: id , schemeId:schemeId }}
    );
  }

  getSubHeadLimit(finyearId,schemeId,subHeadId): Observable<any> {
    let data={
      "finYearId":finyearId,

      "schemeId":schemeId,
   
      "subHeadId":subHeadId
    }
   
    return this.http.post<any>(
      this.getSubHeadLimitTransUrl,
  data,
      {  observe: 'response' }
    );
  }

  getSubHeadLimitAmount(schemeId: number,headId:number) {
    return this.http.get(
      `${this.getSubHeadLimitAmountUrl}?schemeId=${schemeId}&headId=${headId}`,
      { observe: 'response' }
    );
  }

  getHeadLimitAmount(schemeId: number) {
    return this.http.get<any>(
      `${this.getHeadLimitAmountUrl}?schemeId=${schemeId}`,
      { observe: 'response' }
    );
  }

  getDistrictBudgetAllocation(checkRole,schemeId,finYear,iaId,levelId,levelMasterId){
    if(checkRole != "state"){
      return this.http.get(
        `${this.getBudgetAllocationAmount}?schemeId=${schemeId}&finYearId=${finYear}`
        );
        //&iaId=${iaId}&levelId=${levelId}&levelMasterId=${levelMasterId}
      
    }else{
      return this.http.get(
        `${this.getDistrictBudgetAllocationAmount}?schemeId=${schemeId}&finYearId=${finYear}&iaId=${iaId}&levelId=${levelId}&levelMasterId=${levelMasterId}`
        );
    }
    
  }
}
