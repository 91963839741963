import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './stage1.service';
import { Agency, AgencyDto } from './stage1';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/project/project.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage1-view',
  templateUrl: './stage1-view.component.html',
  styleUrls: ['./stage1-view.component.scss']
})
export class Stage1ViewComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  beneficiary: any = {}
  beneficary: any;
  state: any[];
  district: any[];
  projectList: any;
  beneficiaryList: any[];
  taluk: AgencyDto[];
  villages: AgencyDto[];
  items: { label: string; command: (event: any) => void; }[];
  beneficaryForm: FormGroup;
  districtList: void;
  activeIndex: number;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  totalCount: any;
  stage1Filter = {
    projectName: '',
    villageName: '',
    talukName: ''
  }
  stage1 = {
    throughPrivateNegotiation: '',
    throughAct: '',
    taluk:'',
    village:'',
    nameOfBlock: '',
    nameOfUnit: '',
    id: ''
  }
  pageCount: number = 0;
  index: any;
  pageLength: number;
  sortDirection: string = 'asc';
  SelectData: any;

  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private projectService: ProjectService,

    private spinner: NgxSpinnerService,
    public router: Router,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.hamHeader = "Payment status"
    this.subHeader = "Payment  /  Payment status"
    this.forms()
    this.items = [{
      label: 'Details of acquired land',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account Details',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },];
    this.loadPage()
    this.getProjects()
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }


  forms() {
    this.beneficaryForm = this.formBuilder.group({
      disTrictName: ['', Validators.required],
      taluk: ['', Validators.required],
      village: ['', Validators.required],
      projectName: ['', Validators.required],
      surveyNo: ['', Validators.required],
      landAcquire: ['', Validators.required],
      totalCost: ['', Validators.required],
      AccountDetailsForm: this.formBuilder.array([])
    })
  }


  detailsform(): FormGroup {
    return this.formBuilder.group({
      landOwneName: ['', Validators.required],
      aadhaarNo: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      accountHolderName: ['', Validators.required],
      bankName: ['', Validators.required],
      bankAccountNo: ['', Validators.required],
      ifscCode: ['', Validators.required],
      branchName: ['', Validators.required],
      amount: ['', Validators.required],
    })
  }

  removeSkill(i: number) {
    this.detailsOfAccount.removeAt(i);
  }

  get detailsOfAccount(): FormArray {
    return this.beneficaryForm.get("AccountDetailsForm") as FormArray
  }

  addSkill() {
    this.detailsOfAccount.push(this.detailsform());

  }

  getProjects() {
    this.projectService.getProjects().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
      }
    )
  }
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  edit(data) {
    let obj ={
      projectId:data.projectId.id,
      talukId:data.taluk.id,
      villageId:data.village.id
    }
    this.spinner.show()
    this.agencyService.validation(obj).subscribe(
      (res: HttpResponse<any>) => {
        if(res.body.message == 'You are able to update'){
          this.SelectData = data
          this.stage1.id = this.SelectData.id
          this.stage1.nameOfBlock = this.SelectData.nameOfBlock
          this.stage1.nameOfUnit = this.SelectData.nameOfUnit
          this.stage1.village = this.SelectData.village.name
          this.stage1.taluk = this.SelectData.taluk.name

          this.stage1.throughAct = this.SelectData.throughAct
          this.stage1.throughPrivateNegotiation = this.SelectData.throughPrivateNegotiation
          
          this.agencyDialog = true
          this.spinner.hide()
        } else {
          this.notificationService.alertError('Error!', '');
        }
      },
      (err) => {
        this.spinner.hide()
        this.notificationService.alertError('Error!', err.error.error);
        this.agencyDialog = false
      }
    );

  }
  loadPage(page?: number, param?: any): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query
    if (param) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: this.stage1Filter.projectName ?? '',
        villageName: this.stage1Filter.villageName ?? '',
        talukName: this.stage1Filter.talukName ?? '',
        sortBy: param,
        sortDirection: this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: this.stage1Filter.projectName ?? '',
        villageName: this.stage1Filter.villageName ?? '',
        talukName: this.stage1Filter.talukName ?? ''
      };
    }

    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.beneficary = data.data
    this.totalCount = data.totalCount;
  }

  onPageChange(event: PageEvent) {
    console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }

  protected onError(): void {
    this.spinner.hide()
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  openNew(data, beneficaryData?: any) {
    this.label = data
    this.activeIndex = 0;
    this.forms()
    this.addSkill()
    if (data == 'new') {
      this.viewOnly = false
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;

      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.viewOnly = true
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;
      this.getProjects()
      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
      this.agencyService.beneficiary(beneficaryData.id).subscribe(
        (res: HttpResponse<any[]>) => {
          this.singleBeneficiaryList = res.body;
          console.log(this.singleBeneficiaryList);
          this.beneficaryForm.patchValue({
            disTrictName: this.singleBeneficiaryList.districtLGDCode.name,
            taluk: this.singleBeneficiaryList.talukLGDCode.name,
            village: this.singleBeneficiaryList.revenueLGDCode.name,
            projectName: this.singleBeneficiaryList.projectID.projectName,
            surveyNo: this.singleBeneficiaryList.surveyNumber,
            landAcquire: this.singleBeneficiaryList.extendToBeAcquired,
            totalCost: this.singleBeneficiaryList.totalCost,
          })
          this.displayItems()
        },
        () => {
          this.onError();
        }
      );


    }


  }

  createItem(item: any) {
    console.log(item);

    return this.formBuilder.group({
      landOwneName: [item.benificiaryLandOwnerName, [Validators.required]],
      aadhaarNo: [item.aadharNumber, [Validators.required]],
      mobileNumber: [item.mobileNumber, [Validators.required]],
      accountHolderName: [item.nameOfAccountHolder, [Validators.required]],
      bankName: [item.nameOfBank, [Validators.required]],
      bankAccountNo: [item.accountNumber, [Validators.required]],
      ifscCode: [item.ifscCode, [Validators.required]],
      branchName: [item.branchName, [Validators.required]],
      amount: [item.compensationAmount, [Validators.required]],
    });
  }



  displayItems() {
    let transformedItems = this.singleBeneficiaryList.landOwners.map((item: any) =>
      this.createItem(item)
    );
    this.beneficaryForm.setControl('AccountDetailsForm', this.formBuilder.array(transformedItems));
    this.beneficaryForm.disable()
  }
  onSelectDistrict(e) {
    this.agencyService.talukAndVillages(e.id, 3).subscribe(res => {
      this.taluk = res
    })
    this.beneficaryForm.get('taluk').setValue('')
    this.beneficaryForm.get('village').setValue('')
  }
  OnSelectTaluk(e) {
    this.agencyService.talukAndVillages(e.id, 4).subscribe(res => {
      this.villages = res
    })
    this.beneficaryForm.get('village').setValue('')
  }
  onSubmit() {

    let obj = [{
      "id": this.stage1.id,
      "throughPrivateNegotiation": this.stage1.throughPrivateNegotiation,
      "throughAct": this.stage1.throughAct,
      "nameOfBlock": this.stage1.nameOfBlock,
      "nameOfUnit": this.stage1.nameOfUnit
    }]
    this.spinner.show()
    this.agencyService.edit(obj).subscribe(
      (res: HttpResponse<[]>) => {
        this.spinner.hide()
        this.agencyDialog = false
        this.notificationService.alertSuccess('Edit!','Taluks and village data edited Successfully!!!');

        this.loadPage()
      },(err)=>{
        this.notificationService.alertError('Error!',err.error.error);
        this.spinner.hide()
      })
      
  }
  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(item => item.name === 'State' || item.name === 'District');

      },
      () => { }
    );
  }
  onProjectChange(e) {
    let districtList = e.projectLocations
    this.splitDistirct = []
    districtList.forEach(element => {
      this.splitDistirct.push(element.districtLGDCode)
    });
    this.beneficaryForm.get('disTrictName').setValue('')
    this.beneficaryForm.get('taluk').setValue('')
    this.beneficaryForm.get('village').setValue('')
  }


  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    this.viewOnly = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.beneficiary.mobile
    );
    !this.beneficiary.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.beneficiary.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.beneficiary.email);
    !this.beneficiary.email ? (this.emailError = false) : true;
    return !this.emailError && !!this.beneficiary.email;
  }
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var charLength = event.target.value.length
    console.log(charLength);

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      if (charLength == 10) {
        event.preventDefault();
        return true;
      }
    }
  }

  validateIfsc() {
    if (this.beneficiary.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.beneficiary.ifscCode
      );

      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.beneficiary.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.beneficiary.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  validateBemail() {
    if (this.beneficiary.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.beneficiary.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }

  saveAgency() {

    let beneficaryData = this.beneficaryForm.value

    console.log(beneficaryData, 'beneficaryData');


    // const isFormValid =
    // this.validateMobile() &&
    // // this.validateEmail() &&
    // this.validateIfsc()
    // this.validateBmobile() &&
    // this.validateBemail()
    // this.agency.departmentDto &&
    // this.agency.schemeDto &&
    // this.agency.name &&
    // this.agency.email &&
    // this.agency.gstNo &&
    // this.levelMasterDto;



    // if (!isFormValid) {
    //   this.ngSelectInvalid = true;
    //   return;
    // }
    // if (
    //   this.showNameError ||
    //   this.showMobileNoError ||
    //   this.showEmailUniError ||
    //   this.gstUniError
    // ) {
    //   return;
    // }

    let landFrom = []
    beneficaryData.AccountDetailsForm.forEach(element => {
      let data = {
        "benificiaryLandOwnerName": element.landOwneName,
        "aadharNumber": element.aadhaarNo,
        "mobileNumber": element.mobileNumber,
        "nameOfAccountHolder": element.accountHolderName,
        "nameOfBank": element.bankName,
        "accountNumber": element.bankAccountNo,
        "branchName": element.branchName,
        "ifscCode": element.ifscCode,
        "compensationAmount": element.amount,
      }
      landFrom.push(data)
    });

    let Payload = {
      "projectID": beneficaryData.projectName.id,
      "districtLGDCode": beneficaryData.disTrictName.id,
      "talukLGDCode": beneficaryData.taluk.id,
      "revenueLGDCode": beneficaryData.village.id,
      "surveyNumber": beneficaryData.surveyNo,
      "totalCost": beneficaryData.totalCost,
      "extendToBeAcquired": beneficaryData.landAcquire,
      "benificiardLandOwnerDto": landFrom
    }

    this.ngSelectInvalid = false;
    this.agency.leveltype = this.levelTypeDto?.id;
    this.agency.levelMaster = this.levelMasterDto?.id;
    this.spinner.show()
    this.agencyService.save(Payload).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.spinner.hide()

        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  // onSelectRow($event) {
  //   if ($event.type === 'select' || $event.type === 'view') {
  //     if($event.type === 'view'){
  //       this.viewOnly=true;
  //     }
  //     this.agencyService.getAgency($event.value.id).subscribe(
  //       (res: HttpResponse<any>) => {
  //         this.agency = res.body;
  //         this.agencyDialog = true;
  //         this.departmentListService.filter().subscribe(
  //           (res: HttpResponse<DepartmentList[]>) => {
  //             this.departmentList = res.body;
  //           },
  //           () => {
  //             this.onError();
  //           }
  //         );
  //         this.onchangeSchema();
  //       },
  //       () => {
  //         this.onError();
  //       }
  //     );
  //   } else {
  //     this.agencyService.delete($event.value.id).subscribe(
  //       (res: HttpResponse<any>) => {
  //         // this.loadPage(this.page);
  //       },
  //       () => {
  //         this.onError();
  //       }
  //     );
  //   }
  // }

  ngOnDestroy(): void {
    this.spinner.hide();

  }
}