<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Level Master</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right"  *ngIf="pageCRUDdata?.crud.create">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button" (click)="openNew()" styleClass="button"><span class="button-label"
                            [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span></button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div #customerTable id="entities" class="col-12 px-4 mt-4">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [enableView]="pageCRUDdata?.crud.read" 
            [disableDeleteIcon]="!pageCRUDdata?.crud.delete" [disableEditIcon]="!pageCRUDdata?.crud.update"
            (searchParam)="onLazyLoad($event)" (emitFilter)="filterTable($event)" [searchButton]="true" 
            [districtFilter]="true" [blockFilter]="true" [VillageFilter]="true"></custom-filter-table>
    </div>

    <p-dialog #dialog [(visible)]="levelMasterDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"master.add.level.master.name"|translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="master.department.name">Department</label>
                            <!-- <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="departmentDto" [items]="departmentList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentDto }">
                            </ng-select> -->
                            <p>{{this.departmentDto ? this.departmentDto.name : ''}}</p>                        
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.scheme.name">Scheme</label>
                            <!-- <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="schemeDto" [items]="schemeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onchangeSchema()" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeDto }">
                            </ng-select> -->
                            <p>{{this.schemeDto ? this.schemeDto.name : ''}}</p>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.level.type.name">Level Type</label>
                            <!-- <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="levelMaster.levelTypeDto" [items]="levelType" bindLabel="name" appearance="outline" (change)="onLevelType()" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelMaster.levelTypeDto }">
                            </ng-select> -->
                            <div class="card">
                                <!-- <p-treeSelect class="impliBlock" [style]="{'width':'60vws !important;'}"
                                appendTo="body" [(ngModel)]="levelTypeData"
                               [options]="treeLevelData" placeholder="Select Level" (onNodeSelect)="onLevelParentType($event)" (onNodeUnselect)="nodeUnselect($event)">
                           </p-treeSelect> -->
                    
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.Parent.name">Parent Id</label>
                            <div class="card">
                                <!-- <p-treeSelect [(ngModel)]="sublevelTypeData" [options]="SublevelTypeData" (onNodeSelect)="onLevelParentType($event)" (onNodeUnselect)="nodeUnselect($event)" ></p-treeSelect> -->
                                 <!-- <p-treeSelect class="impliBlock" [style]="{'width':'60vws !important;'}"
                                    appendTo="body" [(ngModel)]="sublevelTypeData"
                                    [options]="SublevelTypeData" placeholder="Select Level Parent">
                                </p-treeSelect>  -->
                            </div>
                        </div>
                      
                        <div class="col-sm-6 col-md-4" *ngIf="levelMaster?.levelTypeDto?.name === 'District' || levelMaster?.levelTypeDto?.name === 'Block' || levelMaster?.levelTypeDto?.name === 'Village' ">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.state.name">State</label>
                            <!-- <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="stateListDto" [items]="stateList" bindLabel="name" appearance="outline" (change)="onStateLevelChange()" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !stateListDto }">
                            </ng-select> -->
                            <div class="card">
                                <!-- <p-treeSelect [(ngModel)]="stateListDto" [options]="stateList" (onNodeSelect)="onStateLevelChange($event)" (onNodeUnselect)="nodeUnselect($event)" ></p-treeSelect> -->
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelMaster?.levelTypeDto?.name === 'Block' || levelMaster?.levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.district.name">District</label>
                            <!-- <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="districtListDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !districtListDto }">
                            </ng-select> -->
                            <div class="card">
                                <!-- <p-treeSelect [(ngModel)]="districtListDto" [options]="districtLevelList" (onNodeSelect)="onDistrictLevelChange($event)" (onNodeUnselect)="nodeUnselect($event)" ></p-treeSelect> -->
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="levelMaster?.levelTypeDto?.name === 'Village'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.block.name">Block</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="BlockLevelDto" [items]="this.blockLevelList" bindLabel="name" appearance="outline" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !BlockLevelDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="levelName" [ngClass]="fontService.labelClass" jhiTranslate="master.level.name.name">Level Name</label>
                            <input type="text" class="form-control" [(ngModel)]="levelMaster.name" maxlength="100" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="levelName" autocomplete="off" id="levelName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelMaster.name }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="lgdCode" [ngClass]="fontService.labelClass" jhiTranslate="master.lgd.code.name">LGD Code</label>
                            <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" name="lgdCode" autocomplete="off" [(ngModel)]="levelMaster.code"
                                id="lgdCode" maxlength="6" [ngClass]="{ 'is-invalid': ngSelectInvalid && !levelMaster.code }" (ngModelChange)="checkCodeNo()" />
                            <div *ngIf="codeError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showCodeError">{{codeError}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
                    class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.cancelbutton"></span></button>
            <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()"><span
                    class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.savebutton"></span></button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>