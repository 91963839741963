import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<Agency[]>;
import { Agency, AgencyDto } from './agency';

@Injectable({ providedIn: 'root' })
export class AgencyService {
   
    constructor(private http: HttpClient) { }
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/requestingAgency/getList');
    protected getLevelMasters = environment.SERVER_API_URL+('api/allLevelMasterList');
    protected getSingleAgency = environment.SERVER_API_URL+('api/requestingAgency');
    protected resourceVaildFliterUrl = environment.SERVER_API_URL+('api/getIAgencyValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/requestingAgency')
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteImplementingAgency')
    protected getAgencyUrl = environment.SERVER_API_URL+('api/getImplementingAgency')

    filter(req?: any,filter?:any): Observable<any> {
      const options = createRequestOption(req);
        return this.http
        .get<Agency[]>(this.resourceFliterUrl, { params:options, observe: 'response' })
    }

    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<Agency[]>(this.resourceVaildFliterUrl,filter, { params:options, observe: 'response' })
    }

    save(agency?:any): Observable<EntityArrayResponseType> {
        return this.http
        .post<AgencyDto[]>(this.resourceSaveUrl,agency, {observe: 'response' })
    }

    edit(agency?:any,id?:any): Observable<EntityArrayResponseType> {
      return this.http
      .post<AgencyDto[]>(this.resourceSaveUrl+'/'+id,agency, {observe: 'response' })
    }
    delete(id: number): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.resourceDeleteUrl}/${id}`, { observe: 'response' });

      }
  
      getAgency(id: number): Observable<HttpResponse<any>> {
        return this.http.get<AgencyDto>(`${this.getAgencyUrl}?id=${id}`, { observe: 'response' });

      }

      getLevelMaster(): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.getLevelMasters}`, { observe: 'response' });
      }

      getOneAgency(id): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.getSingleAgency}/${id}`, { observe: 'response' });
      }
      deleteAgency(id): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.getSingleAgency}/${id}`, { observe: 'response' });
      }

}