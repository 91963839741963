import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidateService } from './validate.service';
import { SigninService } from '../signin/signin.service';
import * as forge from 'node-forge';
import { environment } from 'src/environments/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { EncryptService } from 'src/app/shared/encrypt.service';
import { User } from '../signin/signin.module';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  keyCloakUuid:string;
  public pwdMismatch:boolean=false;
  showOtp:boolean = false;
  inValidOtp:boolean = false;
  otpverified:boolean=false;
  hide:boolean=true;
  phide:boolean=true;
  inValidUser:boolean=false;
  passwordError:string;
 otpTimer:any;
 timer:any;
 resendOtp:boolean=false;
 otpButtonName:string = "Send OTP";
 disableButton:boolean=false;
 
  otpForm= this.fb.group({
   username: [null, [Validators.required]],
   otp: [null, [Validators.required]]
 });
  forgotPwdForm = this.fb.group({
     password: [null, [Validators.required]],
     confirmPassword: [null, [Validators.required]],
   });
   userDto: any;
   otpId: any;
   otpError: null;
   phoneNo: string;
   enableResendButton: boolean;
   reSendCount: number = 0;
   isSubmitDisabled: boolean =false;
   constructor( private fb: FormBuilder,private route: ActivatedRoute,private router:Router,
     private validateService: ValidateService,private notificationService:NotificationService,
     private loginService: SigninService,private localStorageService:LocalStorageService,
     private sessionStorageService:SessionStorageService,private encryptService:EncryptService) { }
 
   ngOnInit(): void {
     this.loginService.preStatus().toPromise();
     this.route.queryParams.subscribe(params => {
       this.otpForm.controls['username'].setValue(atob(params['username']));
     });
   }
   get username(){
     return this.otpForm.get('username')!.value;
   }
   get otp(){
     return this.otpForm.get('otp')!.value;
   }
   get password(){
     return this.forgotPwdForm.get('password')!.value;
   }
   get confirmPassword(){
     return this.forgotPwdForm.get('confirmPassword')!.value;
   }
   navToLogin() {
     this.router.navigate(['/login']);
   }
   startTimer() {
     this.otpTimer = 60; 
 
     this.timer = setInterval(() => {
       this.otpTimer--; 
 
       if (this.otpTimer === 0) {
         this.stopTimer();
       }
     }, 1000);
   }
 
   stopTimer() {
     this.disableButton = false;
     if(this.reSendCount < 2){
       this.enableResendButton = true;
       this.reSendCount =this.reSendCount +1;
     }
     else{
       this.showOtp = false;
       this.otpError=null;
       this.reSendCount = 1;
       this.otpForm.controls['otp'].setValue('');
       
     }
     clearInterval(this.timer);
   }
 
   sendotp(resend?){
     this.otpError= null;
     this.isSubmitDisabled=false;
     this.validateService.getUserDetails(this.encryptService.encryptData(this.username)).subscribe(
      
       (res)=>{
         this.otpId = res.body.levelOne;
         if(res.body.activeFlag === "No"){
           this.notificationService.alertError('User is disabled by department','')
           return
         }
         this.phoneNo = '****'+res.body.mobile.substring(6, 10);
         if (resend) {
           this.enableResendButton = false;
           this.startTimer();
           this.notificationService.alertSuccess('Otp Resend Sucessfully','')
         } 
        this.showOtp = true;
        this.userDto = res.body;
        this.startTimer();
        this.resendOtp=true;
        this.disableButton=true;
     },
     () => {
       this.notificationService.alertError('Please Enter Valid UserName','')
     }
     );
   }
 
   getUserOtp() {
     this.validateService.getUserOtp(this.username).subscribe(
       (res) => {       
           this.startTimer();
           this.resendOtp=true;
           this.disableButton=true;
       },
       (onError) => {}
     );
   }
   verifyotp(){
     this.inValidOtp=false;
     this.otpError = null;
     if (!this.otpForm.get('otp')!.value) {
       this.inValidOtp=true;
       return;
     }
 
     console.log(this.otpForm.get('otp').value,"value")
   
     this.validateService.validateOtp(this.userDto.id,this.encryptService.encryptData(this.otpForm.get('otp')!.value),this.otpId).subscribe(
       (res) => {
         
           if (res === "Correct") {
 
             let payload = new User();
             payload.otp = this.encryptDatatest(this.otpForm.get('otp')!.value);
             payload.userName = this.encryptDatatest(this.userDto?.userName);
             payload.roleCode = this.encryptDatatest(this.userDto?.roleDto?.code);
             this.localStorageService.clear('X_FORM_CODE')
             this.localStorageService.store(
               'X_FORM_CODE',
               this.encryptDatatest(JSON.stringify(payload))
             );
 
             this.loginService.checkForgetOtpValidate(this.otpId).subscribe(
 
               (res) => {
                 let splitArray: string[];
                 splitArray = res.split("\\");
 
                 if (splitArray[0] === payload.otp && splitArray[1] === payload.roleCode) {
                   this.otpverified=true;
                   this.showOtp=false;                  
                 } else {
                   this.navToLogin();
                 }
               }),
               (error) => {
                 this.navToLogin();
               }
            
           } else {
             this.inValidOtp=true;
           }
         
       },
       (onError) => {
         if (onError.status === 400) {
           this.otpError = onError.error;
          if(onError.error === 'Maximum Attempt Reached'){
           if(this.reSendCount == 0){
             this.reSendCount = 1;
           }
           this.isSubmitDisabled=true
            this.stopTimer();
           
           }
           
           
         } 
       }
     );
     
   }
  
   submit(){
     this.passwordError=null;
     this.pwdMismatch=null;
     if (this.forgotPwdForm.invalid) {
     return;
     }
         if(this.password==this.confirmPassword){
           this.validateService.setPassword(this.userDto.id,this.encryptService.encryptData(this.password)).subscribe(
           ()=>{
             this.notificationService.alertSuccess('Password successfully changed', '');
             this.router.navigateByUrl('login');
           },
           (onError)=>{
             if (onError.status == 400)
             {
               this.passwordError = onError.error.errorKey;
             }
             else{
               this.passwordError='Password reset failed!';
             }
       
       }
         );
         }
         else{
           this.pwdMismatch=true;
         }       
 
   
     
   
   }
 
   encryptDatatest(data: string): string {
     const captchaLength = 16; // Specify the desired captcha length
     const saltCharacters =
       'abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890';
     let captchaStr = '';
 
     while (captchaStr.length < captchaLength) {
       const index = Math.floor(Math.random() * saltCharacters.length);
       captchaStr += saltCharacters.charAt(index);
     }
     const captcha = captchaStr;
     console.log(captcha);
     console.log(captcha.toString());
     const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
     const iv = CryptoJS.enc.Utf8.parse(captcha);
     const paddedData = this.zeroPadData(data).toString();
     const encryptedData = CryptoJS.AES.encrypt(paddedData, key, {
       iv: iv,
     }).toString();
     const encryptedTextWithIV = captcha.toString() + encryptedData.toString();
 
     const base64CipherText1 = encryptedData.toString(CryptoJS.enc.Base64);
 
     console.log('base64CipherText1' + base64CipherText1);
 
     const base64CipherText = CryptoJS.enc.Base64.stringify(
       CryptoJS.enc.Utf8.parse(encryptedTextWithIV)
     );
     console.log('base64CipherText', base64CipherText);
     return base64CipherText;
   }
 
   zeroPadData(data: string): string {
     const blockSize = 16;
     const paddingLength = blockSize - (data.length % blockSize);
     const paddedData = data + '\x00'.repeat(paddingLength);
     return paddedData;
   }
}
