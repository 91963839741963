<div class="Main">
  <div class="container-fluid mt-3">
    <div class="row card-content">
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg" style=" background: #ffecf7;
" >
          <div class="card-body">
            <p> Total No of
              Requisitioning Agency</p>
            <h5>{{fundDetails?.totalNoOfReqAgencies}}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg" style=" background: #FEF5E5;
">
          <div class="card-body">
            <p> Total No of Projects</p>
            <h5>{{fundDetails?.totalNoOfProjects}}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg" style="background: #e8ebff;">
          <div class="card-body">
            <p>Total Project Value</p>
            <h5>{{fundDetails?.projectValue }}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg" style="background: #E6FFFA;">
          <div class="card-body">
            <p>Total Amount Received</p>
            <h5>{{fundDetails?.totalFundReceived }}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg" style="background: #cbedd3;">
          <div class="card-body">
            <p>Total Amount Spent</p>
            <h5>{{fundDetails?.totalFundSpent}}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card cardBg1" style="background: #fdd7d7;">
          <div class="card-body">
            <p>Total Available Balance</p>
            <h5>{{fundDetails?.totalAvailableAmount }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Project
            </h4>
            <p-chart type="bar" [data]="dataProject" [options]="optionsProject" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Total Project Value
            </h4>
            <p-chart type="bar" [data]="dataProjectValue" [options]="optionsProjectValue" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Total Amount Received
            </h4>
            <p-chart type="line" [data]="AmountReceivedata" [options]="optionsProject" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3" >
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Total Amount Spent
            </h4>
            <p-chart type="line" [data]="AmountSpentdata" [options]="AmountSpentOptions" height="273px"></p-chart>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Total Available Balance
            </h4>
            <p-chart type="line" [data]="AmountBalancedata" [options]="AmountBalanceOptions" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Land Acquired(In Hectare)
            </h4>
            <p-chart type="bar" [data]="dataSideBar" [options]="optionsSideBar" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              District Wise
            </h4>
            <div>
              <canvasjs-chart [options]="options" (chartInstance)="getChartInstance($event)"
                [styles]="{width: '100%', height: '273px'}"></canvasjs-chart>
              <button class="backButton" *ngIf="isButtonVisible" (click)="handleClick($event)">Back</button>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 360px;">
            <h4 class="p-3 dataHeader">
              Top Requisitioning Departments
            </h4>
            <p-chart type="bar" [data]="dataBar" [options]="optionsBar" height="273px"></p-chart>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>