<div class="row">
    <!-- <div class="row px-8 g-3">
        <div *ngIf="finYearFilter" class="col-sm">
            <label class="form-control-label" for="scheme">Fin Year</label>
            <ng-select id="scheme" [(ngModel)]="finYearDto" [items]="finYear" bindLabel="name" appearance="outline" (search)="onStateLevelfilterChange($event)" [searchable]="false" [closeOnSelect]="true" [clearable]="false" class="form-control" (change)="onDistrictLevelFilterChange()">
            </ng-select>
        </div>
        <div *ngIf="districtFilter" class="col-sm">
            <label class="form-control-label" for="scheme">District</label>
            <ng-select id="scheme" [(ngModel)]="districtListDto" [searchable]="false" [items]="districtLevelList" bindLabel="name" appearance="outline" (search)="onStateLevelfilterChange($event)" [closeOnSelect]="true" [clearable]="false" class="form-control" (change)="onDistrictLevelFilterChange()">
            </ng-select>
        </div>
        <div *ngIf="blockFilter" class="col-sm">
            <label class="form-control-label" for="scheme">Block</label>
            <ng-select id="scheme" [(ngModel)]="blockVillageDto" [searchable]="false" [items]="blockLevelList" bindLabel="name" appearance="outline" (search)="onDistrictLevelFilterChange($event)" (change)="onBlockLevelChange()" [closeOnSelect]="true" [clearable]="false"
                class="form-control">
            </ng-select>
        </div>
        <div *ngIf="VillageFilter" class="col-sm">
            <label class="form-control-label" for="scheme">Village</label>
            <ng-select id="scheme" [(ngModel)]="VillageDto" [items]="villageLevelList" [searchable]="false" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div *ngIf="iAFilter" class="col-sm">
            <label class="form-control-label" for="scheme">Implementing Agency</label>
            <ng-select id="scheme" [(ngModel)]="implementingAgencyDto" [items]="iaCompList" [searchable]="false" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div *ngIf="dateFilter" class="col-sm">
            <label class="form-control-label" for="dateOfBirth">From Date</label>
            <br />
          <p-calendar
            dateFormat="dd.mm.yy"
            [(ngModel)]="fromDate"
            styleClass="datepicker"
            [maxDate]="maxDate" [minDate]="minDate">
        </p-calendar>
        </div>
    
        <div  *ngIf="dateFilter" class="col-sm">
          <label class="form-control-label" for="dateOfBirth">To Date</label><br />
            <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="toDate" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>
        </div>
        <div *ngIf="searchButton" class="col-sm">
            <p></p>
            <button pButton pRipple class="p-button-success custom-button" (click)="filterData()">
                <span class="button-label">Search</span>
            </button>
        </div>
        <div *ngIf="searchButton" class="col-sm">
            <p></p>
            <button pButton pRipple (click)="clearData()" class="p-button-danger custom-button">
                <span class="button-label" >Clear</span>
            </button>
        </div>
    </div> -->
    <div #customerTable id="entities" class="col-12 px-4 mt-4">
        <!-- <div class="multiselect">
            <p-multiSelect [options]="columnOptions" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns">
            </p-multiSelect>
        </div> -->
        <p-table #reportTable [value]="listItems | async" scrollDirection="both"
         styleClass="dyanamic-table" columnResizeMode='fit'
            [paginator]="paginator" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage"
            [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="lazyLoadOnInit"
            responsiveLayout="scroll" [columns]="selectedColumns" [tableStyle]="{ width: 'max-content'}"
            [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30,2000]"
            [sortOrder]="sortOrder" [sortField]="sortField"
            [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
            [first]="first" id="reportTable">
            <ng-template pTemplate="caption">
                
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <ng-container *ngFor="let column of columns">
                        <th pReorderableColumn [pSortableColumn]="column.field" scope="col">

                            <div class="head-wrapper" *ngIf="column.jhiTranslate">
                                <span class="left" jhiTranslate={{column.jhiTranslate}}>{{column.header}}</span>
                            </div>
                            <div class="head-wrapper" *ngIf="!column.jhiTranslate">
                                <span class="left">{{column.header}}</span>
                            </div>
                        </th>
                    </ng-container>
                    <th alignFrozen="right" pFrozenColumn>
                        <span></span>
                    </th>
                </tr>
                <!-- <tr>
                    <ng-container *ngFor="let column of columns">
                        <th>
                            <div class="head-wrapper">
                                <p-columnFilter *ngIf="column.isFilterable && column.type !== 'dropDown' && column.type !== 'dropDown-B' && column.type !== 'dropDown-C'"
                                    type="text" [field]="column.field"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    [matchModeOptions]="matchModeOptions" [showAddButton]="false"
                                    [hideOnClear]="true"></p-columnFilter>
                                <p-columnFilter *ngIf="column.isFilterable && column.type === 'dropDown'"
                                    field="{{column.field}}" [showMenu]="false" matchMode="equals">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" appendTo="body"
                                            [options]="column.filterDropDownDate" optionLabel="name"
                                            (onChange)="filter($event.value)" placeholder="Select">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.name}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                                <p-columnFilter *ngIf="column.isFilterable && column.type === 'dropDown-B'"
                                    field="{{column.field}}" [showMenu]="false" matchMode="equals">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" appendTo="body"
                                            [options]="column.filterDropDownDate" optionLabel="name"
                                            (onChange)="filter($event.value)" placeholder="Select">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.description}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                                <p-columnFilter *ngIf="column.isFilterable && column.type === 'dropDown-C'"
                                    field="{{column.field}}" [showMenu]="false" matchMode="equals">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" appendTo="body"
                                            [options]="column.filterDropDownDate" optionLabel="name"
                                            (onChange)="filter($event.value)" placeholder="Select">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.userName}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                    </ng-container>
                    <th alignFrozen="right" pFrozenColumn>
                        <span></span>
                    </th>
                </tr> -->
            </ng-template>
            <ng-template pTemplate="body" let-record let-columns="columns" let-rowData let-index="rowIndex">
                <!-- <tr (click)="onSelectRow(record, 'select')" > -->
                <tr [ngClass]="{'odd-row': index % 2 === 0, 'even-row': index % 2 !== 0}">
                    <ng-container *ngFor="let col of columns" [ngSwitch]="col.type">
                        <td *ngSwitchCase="'date'">
                            {{rowData[col.field] | tabledate }}
                        </td>
                        <td *ngSwitchCase="'dateTime'">
                            {{ rowData[col.field] | date : 'dd-MMM-yy HH:mm:ss' }}
                        </td>
                        <td *ngSwitchCase="'dropDown'">
                            {{(rowData[col.field])?.name}}
                        </td>
                        <td *ngSwitchCase="'dropDown-B'">
                            {{(rowData[col.field])?.description}}
                        </td>
                        <td *ngSwitchCase="'dropDown-C'">
                            {{(rowData[col.field])?.userName}}
                        </td>
                        <td *ngSwitchCase="'dropDown_with_other_field'">
                            {{ rowData[col.field][col.Dfield] }}
                        </td>
                        <td *ngSwitchCase="'dropDown_with_other_some_field'">
                            {{ rowData[col.field][col.Dfield][col.Efield] }}
                        </td>
                        <td *ngSwitchCase="'amount'">
                            {{ rowData[col.field] |  currency:'INR':'symbol':'1.1-2'}}
                        </td>
                        <td *ngSwitchCase="'type'">
                            {{(rowData[col.field])?.name}}
                        </td>
                        <td class="pointer " *ngIf="!col.toolTip && col.type == 'clickable'" (click)="onSelect(record, col.field)">
                         <a class="clickable" rel="noopener noreferrer">{{rowData[col.field]}}</a>   
                        </td>
                        <td *ngIf="!col.toolTip && !col.type">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="col.toolTip && !col.type" [pTooltip]="rowData[col.toolTipText]">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngSwitchCase="'resetPassword'">
                            <button *ngIf="!disableLockIcon" pButton type="button"
                                class="p-button-rounded p-button-text" (click)="onSelectRow(record,'Reset')">
                                Reset
                            </button>
                        </td>
                        <td *ngSwitchCase="'deActivate'">
                            <button *ngIf="!disableDeactivateIcon" pButton pRipple type="button"
                                class="p-button-rounded p-button-text" (click)="onSelectRow(record, 'Deactivate')">
                                Deactivate
                            </button>
                    </ng-container>
                    <td style=" width: 8" alignFrozen="right" pFrozenColumn class="text-center">
                        <button *ngIf="!disableEditIcon" pButton pRipple type="button" icon="pi pi-pencil"
                            class="p-button-rounded p-button-text p-button-info"
                            (click)="onSelectRow(record, 'select')">
                        </button>
                        <button *ngIf="record?.isEdit" pButton pRipple type="button" icon="pi pi-pencil"
                            class="p-button-rounded p-button-text p-button-info"
                            (click)="onSelectRow(record, 'select')">
                        </button>
                        <button *ngIf="enableView" pButton pRipple type="button" icon="pi pi-eye"
                            class="p-button-rounded p-button-text p-button-info" (click)="onSelectRow(record, 'View')">
                        </button>
                        <!-- <button *ngIf="enableView" pButton pRipple class="p-button-success mb-1 p-button-alignment"
                            (click)="onSelectRow(record, 'View')" styleClass="button">
                            view
                        </button> -->
                        <button *ngIf="!disableDeleteIcon" pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-text p-button-danger"
                            (click)="onSelectRow(record, 'delete')">
                        </button>
                        <button *ngIf="showProfileButton" pButton pRipple type="button" icon="pi pi-user"
                            class="p-button-rounded p-button-text p-button-info" (click)="navigateProfile()">
                        </button>
                        <button *ngIf="showRedirectButton" pButton pRipple type="button" icon="pi pi-arrow-circle-right"
                            class="p-button-rounded p-button-text" (click)="navigatetoVoucherPage(record.id)">
                        </button>
                        <button *ngIf="showTableButton" pButton pRipple type="button" icon="pi pi-th-large"
                            class="p-button-rounded p-button-text" (click)="onSelectshowTable(record?.transactionId)">
                        </button>

                        <button *ngIf="enableApprove && record?.status === 'PENDING'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'approve')">
                            <span class="button-label">Approve</span>
                        </button>
                        <button *ngIf="enableApprove && record?.status === 'PENDING'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'Reject')">
                            <span class="button-label">Reject</span>
                        </button>
                        <button *ngIf="enableApprove && record?.activeFlag === 'No'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'approve')">
                            <span class="button-label">Approve</span>
                        </button>
                        <button *ngIf="enableApprove && record?.activeFlag === 'No'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'Reject')">
                            <span class="button-label"> Reject</span>
                        </button>
                        <button *ngIf="enableApprove && record?.activeFlag === 'New'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'approve')">
                            <span class="button-label">Approve</span>
                        </button>
                        <button *ngIf="enableApprove && record?.activeFlag === 'New'" pButton pRipple
                            class="p-button-text custom-button" (click)="approveRecord(record.id,'Reject')">
                            <span class="button-label"> Reject</span>
                        </button>
                        <button *ngIf="enableApproveStatus === record?.status" pButton pRipple
                            class="p-button-text custom-button" (click)="approveStatus(record,'approve')">
                            <span class="button-label">Approve</span>
                        </button>

                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div *ngIf="listItems" class="p-toolbar-group-end mb-4">
    <p-button (click)="exportExcel()"
        styleClass="p-button-sm p-button-raised p-button-text p-button-info me-4 custom-button">
        <span class="button-label" jhiTranslate="common.exportexcel"></span>
    </p-button>
    <!-- <p-button (click)="downloadExcel(keyName,fileName)" *ngIf="!back"
        styleClass="p-button-sm p-button-raised p-button-text p-button-info me-4 custom-button">
        <span class="button-label">Export all to Excel</span>
    </p-button> -->
    <p-button (click)="exportExcel()" *ngIf="!back"
    styleClass="p-button-sm p-button-raised p-button-text p-button-info me-4 custom-button">
    <span class="button-label">Export all to Excel</span>
</p-button>
</div>