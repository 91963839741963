<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Land</h2>
</div>

<div class="container-fluid bg">
    <div class="container row mx-auto bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project Name
            </label>
            <ng-select id="department" bindLabel="projectName" appearance="outline"
                [(ngModel)]="stage2SearchFilter.project" [items]="projectList" class="form-control"
                (change)="getExtent('projectName')" (change)="selectProject($event)">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Districts</label>
            <ng-select id="department" bindLabel="name" appearance="outline" (change)="SelectDistrict($event)"
                (change)="getExtent('district')" [(ngModel)]="stage2SearchFilter.district" [items]="districkList"
                class="form-control">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk</label>
            <ng-select id="type" [items]="taluk" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                [clearable]="true" class="form-control" (change)="OnSelectTaluk($event)" (change)="getExtent('taluk')"
                [(ngModel)]="stage2SearchFilter.taluk" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="type" [items]="villages" bindLabel="name" (change)="OnSelectVillage($event)"
                (change)="getExtent('village')" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [(ngModel)]="stage2SearchFilter.village"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }">
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Total extent Acquired(In
                hectares)</label>
            <input type="number" class="form-control" name="name" readonly autocomplete="off"
                [(ngModel)]="stage2SearchFilter.totalExtent" maxlength="60"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
        </div>
    </div>
    <div class="row" *ngIf="isShowTable">
        <div class="col-md-12 mt-3">
            <div class="mt-2">
                <span>Land Acquired(In hectares): {{RemainLand}}</span> &nbsp;
                <span>Amount Spent for Acquisition(In Rupees): {{RemainAmount}}</span>
            </div>
            <form [formGroup]="projectForm" class="mt-4">
                <div formArrayName="LandDetailsForm">
                    <table style="width:100%">
                        <th colspan="12" class="text-center" style="position: sticky;top:0">
                            {{projectName}}
                        </th>
                        <tr style="background: #017DC3;position: sticky;top:0">
                            <th rowspan="2" style="width:200px;background: #017DC3;">Survey
                                Number</th>
                            <th rowspan="2" style="width:200px;background: #017DC3;">Subdivision
                                Number</th>
                            <th rowspan="2" style="width:200px;background: #017DC3;">Part Subdivision
                                Number</th>
                            <th rowspan="2" class="text-center" style="width:200px;background: #017DC3;">Total extent of
                                the Survey
                                Number as per
                                Tamilnilam</th>
                            <th rowspan="2" style="width:200px;background: #017DC3;">Acquired
                                extent as per
                                Award
                                (In hectares)</th>
                            <th rowspan="2" style="width:200px;background: #017DC3;">Acquired
                                extent as per
                                Award
                                (In Square metre)</th>
                            <th style="width:200px;background: #017DC3;">As per Private
                                Negotiation(Land)</th>
                            <th style="width:200px;background: #017DC3;">As
                                per Act(Land)</th>
                            <th style="width:200px;background: #017DC3;">
                                As Per Private Negotiation Cost
                            </th>

                            <th style="width:200px;background: #017DC3;">
                                As Per Act Cost
                            </th>

                            <th rowspan="2" style="width:200px;background: #017DC3;">
                                Total
                                Compensation
                                determined for
                                this survey
                                number
                            </th>
                            <th style="width:200px;background: #017DC3;">
                                Action
                            </th>
                        </tr>
                        <tr>
                        </tr>
                        <tr *ngFor="let landDetails of detailsOfLand.controls; let i=index" [formGroupName]="i">
                            <td>
                                <input type="text" class="form-control" name="name" formControlName="surveyNo"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    (keyup)="surveyNo($event,i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="text" class="form-control" name="name" formControlName="subdivisionNo"
                                    (change)="subDivision($event,i)"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="text" class="form-control" name="name" formControlName="partSubdivisionNo"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>

                            <td>
                                <input type="number" class="form-control" name="name" formControlName="tamilnilam"
                                    (keyup)="tamilnilam($event,'',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="number" class="form-control" name="name"
                                    (keypress)="keyPressNumbersDecimal($event,'land')" formControlName="acquireAsAward"
                                    (keyup)="tamilnilam($event,'acquire',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="text" class="form-control" name="name" formControlName="acquireAsASquare"
                                    readonly [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}"
                                    id="name" />
                            </td>
                            <td>
                                <input type="number" class="form-control" name="name"
                                    (keypress)="keyPressNumbersDecimal($event,'land')"
                                    formControlName="asPerPrivateNego" (keyup)="tamilnilam($event,'',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="number" class="form-control" name="name"
                                    (keypress)="keyPressNumbersDecimal($event,'land')" formControlName="asPerAct"
                                    (keyup)="tamilnilam($event,'',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>

                            <td>
                                <input type="number" class="form-control" name="name"
                                    (keypress)="keyPressNumbersDecimal($event,'cost')"
                                    formControlName="asPerPrivateNegoCost" (keyup)="getTotal(i)"
                                    (keyup)="tamilnilam($event,'',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td>
                                <input type="number" class="form-control" name="name"
                                    (keypress)="keyPressNumbersDecimal($event,'cost')" formControlName="asPerActCost"
                                    (keyup)="getTotal(i)" (keyup)="tamilnilam($event,'',i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>

                            <td>
                                <input type="text" class="form-control" name="name" formControlName="totalCompensation"
                                    readonly
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </td>
                            <td class="action">
                                <i class="pi pi-plus-circle mt-1" (click)="addlandDetails()"></i> &nbsp;
                                <i class="pi pi-trash" *ngIf="detailsOfLand.length > 1" (click)="removeSkill(i)"></i>
                            </td>
                        </tr>

                        <tr style=" background: rgba(1, 125, 195, 0.05);">
                            <td colspan="3">
                                <h4 class="p-2">Total</h4>
                            </td>
                            <td class="result">
                                {{tamilnilams | number}}
                            </td>
                            <td class="result">
                                {{extend | number}}
                            </td>
                            <td class="result">
                            </td>
                            <td class="result">
                                {{asPerPrivate | number}}
                            </td>
                            <td class="result">
                                {{asPerAct | number}}
                            </td>
                            <td class="result">
                                {{asPerPrivateNegoe | number}}
                            </td>
                            <td class="result">
                                {{asPerActCost | number}}
                            </td>
                            <td class="result">
                                {{totalCompensation | number}}
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </form>
            <div class="flex align-items-center">
                <p-button label="Submit" class="submit" [disabled]="projectForm.invalid" (click)="submit()"></p-button>
            </div>
        </div>
    </div>
</div>